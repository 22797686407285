import {TaskActionType} from '@ee/common/enums';
import {Auditing} from './auditing.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

export interface Task extends Auditing {
  id?: string;
  text?: string;
  notes?: string;
  completed?: boolean;
  current_user_is_owner?: boolean;
  visible_to_organization?: boolean;
  due_date?: Date;
  link?: string;
  type?: TaskActionType;
  admin?: boolean;
  pro_feature?: boolean;
}

export function GenerateTaskForm(fb: UntypedFormBuilder): UntypedFormGroup {
  return fb.group({
    text: ['', Validators.required],
    notes: '',
    completed: false,
    due_date: null,
    visible_to_organization: false
  });
}

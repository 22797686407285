import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaymentMethodFormComponent} from './payment-method-form.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';


@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule
  ],
  declarations: [
    PaymentMethodFormComponent
  ],
  exports: [
    PaymentMethodFormComponent
  ]
})
export class PaymentMethodFormModule {
}

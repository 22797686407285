import { Component, OnDestroy, OnInit } from '@angular/core';
import { Organization, PlanModel, SubscriptionModel } from '@ee/common/models';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { UpdateSubscription } from '../core/actions/billing.actions';
import { Subscription } from 'rxjs';
import { AccountType } from '@ee/common/enums';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BillingService } from '@ee/common/services';
import * as fromApp from '../core/reducers/app.reducer';
import { GenerateSubscriptionForm } from '@ee/common/forms';

@Component({
  selector: 'ee-billing',
  template: `
    <div class="auth-container flex flex-row justify-center items-center" *ngIf="plans?.length">
      <div class="back-button">
        <button mat-button type="button" (click)="goBack()">
          <mat-icon fontSet="fa" fontIcon="fa-reply"></mat-icon>
          Billing
        </button>
      </div>

      <ee-attorney-enterprise-subscribe class="content" [plans]="plans" [loggedInOrg]="loggedInOrg" [form]="subscriptionForm"
                                *ngIf="loggedInOrg?.type === accountType.ATTORNEY; else pmSubscription">
      </ee-attorney-enterprise-subscribe>
      <ng-template #pmSubscription>
        <ee-property-manager-subscription class="content" [plans]="plans" [loggedInOrg]="loggedInOrg" [form]="subscriptionForm">
        </ee-property-manager-subscription>
      </ng-template>

      <!--  <div class="flex flex-col justify-center items-center" *ngIf="loggedInOrg?.type !== accountType.ATTORNEY">-->
      <!--    <h1>Your subscription has expired.</h1>-->
      <!--    <p>Please speak with your firm's account administrator to update subscription details</p>-->
      <!--    <p>If you feel you are seeing this message in error, please contact us below.</p>-->
      <!--  </div>-->
    </div>
  `,
  styles: [`
    @import 'components/color-palette';

    .auth-container {
      background: #fff;
    }

    .content {
      margin: 80px 0;
      width: 80%;
    }

    .plan-feature {
      line-height: 17px;
      margin: 5px 0 !important;
    }

    .red {
      color: $primary-red;
    }

    .back-button {
      position: fixed;
      top: 1.5rem;
      left: 1.5rem;
      text-align: right;
      color: $c-gray-50;
      width: 120px;

      .mdc-icon-button {
        font-size: 1rem;
        width: 120px;
      }
    }
  `]
})
export class BillingComponent implements OnInit, OnDestroy {
  plans: PlanModel[] = [];

  subscriptionForm: UntypedFormGroup;

  loggedInOrg: Organization;

  accountType = AccountType;

  isLoading = false;

  private subscription: Subscription = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private appStore: Store,
    private billingService: BillingService,
    private httpClient: HttpClient,
    private router: Router
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.appStore.select(fromApp.getLoggedInOrg).subscribe((org: Organization) => {
        this.loggedInOrg = org;
        if (org) {
          this.subscriptionForm = GenerateSubscriptionForm(this.fb, this.billingService, org);
          this.subscription.add(
            this.httpClient.get(`assets/${org.type.toLowerCase()}-plans.json`).subscribe((plans: PlanModel[]) => {
              this.plans = plans;
            })
          );
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  purchase() {
    const subscription = this.subscriptionForm.value as SubscriptionModel;
    this.isLoading = true;
    this.appStore.dispatch(new UpdateSubscription(subscription));
  }

  goBack() {
    this.router.navigate(['admin/billing']);
  }
}

<div mat-dialog-content class="ee-container">
  <mat-icon class="cancel" fontSet="fa" fontIcon="fa-times" [mat-dialog-close]="false"></mat-icon>
  <div class="flex flex-row">
    <div class="mr-1 flex flex-col justify-center items-center flex-[1_1_40%]" *ngIf="data.image_path">
      <img class="upgrade-image" [src]="data.image_path" alt="">
    </div>
    <div class="flex flex-col justify-between items-stretch" [ngClass]="{'flex-[1_1_60%]': data.image_path, 'flex-[1_1_100%]': !data.image_path, }">
      <h2>{{data.title}}</h2>
      <div class="content flex-1">
        <div *ngIf="data.inner_html" [innerHTML]="data.inner_html"></div>
        <ul class="features" *ngIf="data.features?.length">
          <li class="flex flex-row justify-start items-center" *ngFor="let feature of data.features">
            <mat-icon fontSet="fa" fontIcon="fa-check"></mat-icon>
            <div class="flex-1">{{feature}}</div>
          </li>
        </ul>
      </div>
      <div class="flex flex-col justify-start items-stretch">
        <button *ngIf="data.user?.is_admin || data.user?.is_account_admin; else close"
                mat-flat-button color="accent" type="button" [mat-dialog-close]="true">
          Start your 14 day free trial today
        </button>
        <ng-template #close>
          <button mat-flat-button color="accent" type="button" [mat-dialog-close]="false">
            Talk to your account admin. Click to close.
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</div>


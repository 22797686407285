import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from './app.reducer';
import {
  LOAD_TOTAL_UNREAD_NOTIFICATION_COUNT,
  LOAD_UNREAD_NOTIFICATIONS,
  MARK_NOTIFICATIONS_READ,
  NotificationActions
} from '../actions/notification.actions';
import { Notification } from '@ee/common/models';

export interface NotificationState {
  notifications: Notification[];
  totalUnreadNotificationCount: number;
}

export interface State extends fromRoot.State {
  notification: NotificationState;
}

const initialState: NotificationState = {
  notifications: [],
  totalUnreadNotificationCount: 0
};

export function notificationReducer(state = initialState, action: NotificationActions) {
  if (action.type === LOAD_UNREAD_NOTIFICATIONS) {
    return {...state, notifications: [...action.payload]};
  } else if (action.type === LOAD_TOTAL_UNREAD_NOTIFICATION_COUNT) {
    return {...state, totalUnreadNotificationCount: action.payload};
  } else if (action.type === MARK_NOTIFICATIONS_READ) {
    let count = state.totalUnreadNotificationCount - action.payload.length;
    count = count < 0 ? 0 : count; // should never have less than zero notifications
    return {...state, totalUnreadNotificationCount: count};
  } else {
    return state;
  }
}

export const getNotificationState = createFeatureSelector<NotificationState>('notification');

export const getNotifications = createSelector(
  getNotificationState, (state: NotificationState) => state.notifications
);

export const getTotalUnreadNotificationCount = createSelector(
  getNotificationState, (state: NotificationState) => state.totalUnreadNotificationCount
);

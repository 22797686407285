import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  MESSAGE_SENT,
  MessageActions,
  RESET_CASE_UNREAD_COUNT,
  SET_EVICTION_MESSAGES
} from '../actions/message.actions';
import * as fromRoot from './app.reducer';
import { EvictionMessageSummaryModel, Message } from '@ee/common/models';
import orderBy from 'lodash-es/orderBy';
import cloneDeep from 'lodash-es/cloneDeep';
import findIndex from 'lodash-es/findIndex';

export interface MessageState {
  evictionMessageSummaryList: EvictionMessageSummaryModel[];
  selectedEvictionMessages: Message[];
}

export interface State extends fromRoot.State {
  message: MessageState;
}

const initialState: MessageState = {
  evictionMessageSummaryList: null,
  selectedEvictionMessages: []
};

export function messageReducer(state = initialState, action: MessageActions) {
  switch (action.type) {
    case SET_EVICTION_MESSAGES:
      return {...state, selectedEvictionMessages: orderBy(action.payload, ['send_date'], ['desc'])};
    case MESSAGE_SENT:
      const stateCopy = cloneDeep(state);
      stateCopy.selectedEvictionMessages.unshift(action.payload);
      return stateCopy;
    case RESET_CASE_UNREAD_COUNT:
      const evictionMessageSummaryList = cloneDeep(state.evictionMessageSummaryList);
      const index = findIndex(evictionMessageSummaryList, {case_id: action.caseId});
      if (index > -1) {
        evictionMessageSummaryList[index].unread_message_count = 0;
      }
      return {...state, evictionMessageSummaryList };
    default: {
      return state;
    }
  }
}

export const getMessageState = createFeatureSelector<MessageState>('message');

export const getEvictionMessageSummaryList = createSelector(
  getMessageState,
  (state: MessageState) => state.evictionMessageSummaryList
);

export const getSelectedEvictionMessages = createSelector(
  getMessageState,
  (state: MessageState) => state.selectedEvictionMessages
);

import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {AUTHENTICATION_TOKEN} from '@ee/common/constants';
import {User} from '@ee/common/models';
import {AppState, AuthService as Auth0Service} from '@auth0/auth0-angular';
import {RedirectLoginOptions} from '@auth0/auth0-spa-js';
import {firstValueFrom, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AuthService {
  constructor(private http: HttpClient,
              private $localStorage: LocalStorageService,
              private $sessionStorage: SessionStorageService,
              @Inject('environment') private environment,
              private auth0Service: Auth0Service) {
  }

  getToken() {
    return this.$localStorage.retrieve(AUTHENTICATION_TOKEN) || this.$sessionStorage.retrieve(AUTHENTICATION_TOKEN);
  }

  async login(redirectToPath: string = null): Promise<void> {
    const options = {} as RedirectLoginOptions<AppState>;
    if (redirectToPath) {
      options.appState = {
        target: this.environment.base_url + redirectToPath
      };
    }
    await firstValueFrom(this.auth0Service.loginWithRedirect(options));
  }

  isLoggedIn(): Observable<boolean> {
    return this.auth0Service.isAuthenticated$;
  }

  logout(): void {
    this.auth0Service.logout({
      clientId: this.environment.auth0_clientid,
      logoutParams: {
        returnTo: this.environment.base_url
      }
    });
  }

  arrayHasAnyAuthority(userAuthorities: string[], authorities: string[]): boolean {
    if (!userAuthorities || !Array.isArray(userAuthorities)) {
      return false;
    }

    if (!authorities?.length) {
      return true;
    }

    const intersection = userAuthorities.filter(x => authorities.includes(x));

    return intersection.length > 0;
  }

  hasAnyAuthority(user: User, authorities: string[]): boolean {
    if (!user || !user.authorities || !Array.isArray(user.authorities)) {
      return false;
    }

    let contains = false;

    for (const authority of authorities) {
      contains = contains || user.authorities.indexOf(authority) >= 0;
    }

    return contains;
  }
}

import { Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  template: `
    <div class="ag-tag-wrapper flex flex-row"
         *ngIf="params.data?.tags?.length">
      <ee-tag *ngFor="let t of params.data?.tags" [tag]="t" [noWrap]="true"></ee-tag>
    </div>
  `,
  styles: [`
    .ag-tag-wrapper {
      overflow-x: auto;
      font-weight: normal;
    }
  `]
})
export class AgTagRendererComponent implements ICellRendererAngularComp {
  public params!: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}

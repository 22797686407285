import {createAction} from '@ngrx/store';
import {
  AttorneyClientSettings,
  CaseSettings,
  CustomField,
  DashboardWidgets,
  NotificationSettings,
  Organization,
  PropertyManagerDashboardWidgets, PermissionGroup, Tag, TagType,
  User,
  UserDashboardSettings
} from '@ee/common/models';
import {ColDef} from '@ag-grid-community/core';

export const AUTHENTICATED = '[Auth] Authenticated';
export const ACTIVATION_REQUEST_SENT = '[Auth] Activation request sent';
export const NOT_AUTHENTICATED = '[Auth] Not Authenticated';
export const LOGOUT = '[Auth] Logout';
export const AUTH_ERROR = '[Auth] Error';
export const GET_LOGGED_IN_USER = '[Auth] Get logged in user';
export const NO_ACTION = '[Auth] No Action';
export const NOTIFICATION_SETTINGS_UPDATED = '[NotificationSettingsUpdated] Notification Settings Updated';
export const USER_DASHBOARD_SETTINGS_UPDATED = '[UserDashboardSettingsUpdated] Dashboard Settings Updated';
export const USER_SETTING_UPDATED = '[UserSettingUpdated] User Settings Updated';
export const CASE_GRID_SETTINGS_UPDATED = '[CaseGridSettingUpdated] Case Grid Settings Updated';
export const USER_EMAIL_UPDATED = '[Auth] User email updated';
export const USER_DETAILS_UPDATED = '[Auth] User details Updated';
export const USER_BAR_LICENSES_UPDATED = '[Auth] User bar licenses Updated';
export const SET_LOGGED_IN_ORG = '[Organization] Set Logged In Organization';
export const REFRESH_LOGGED_IN_ORG = '[Organization] Refresh Logged In Organization';
export const REFRESH_METRICS = '[Organization] Refresh Metrics';
export const DASHBOARD_WIDGETS_UPDATED = '[Organization] Dashboard widgets found';
export const REFRESH_TASK_COUNT = '[Organization] Refresh Task Count';
export const DASHBOARD_LAUNCH_ATTORNEY_GETTING_STARTED = '[Organization] Dashboard launch attorney getting started';
export const GUIDES_VIEWED_UPDATED = '[Auth] Guides Viewed Updated';
export const LOGGED_IN_ORG_UPDATED = '[Auth] Logged In Organization Updated';
export const LOGGED_IN_USER_UPDATED = '[Auth] Logged In User Updated';
export const DEFAULT_WORKFLOW_UPDATED = '[Auth] Default Workflow Updated';
export const SET_CASE_SETTINGS = '[Auth] Set Case Settings';
export const SET_ATTORNEY_CLIENT_SETTINGS = '[Auth] Attorney Client Settings';
export const SET_SCRA_MONTHLY_LIMIT = '[Organization] Scra Monthly Limit';
export const LOAD_APP_SETTING = '[Auth] Load Application Settings';
export const APP_SETTING_LOADED = '[Auth] Application Settings Loaded';
export const UPDATE_UNREAD_MESSAGE_COUNT = '[Organization] Update Unread Message Count';
export const UPDATE_ALL_TAGS = '[AUTH] Update All Tags';
export const UPDATE_TAGS = '[AUTH] Update Tags';
export const CUSTOM_CASE_FIELDS_UPDATES = '[AUTH] Custom Case Fields Updated';
export const UPDATE_PERMISSION_GROUPS = '[Auth] Update Permission Groups';
export const UPDATE_FEATURE_FLAG = '[Auth] Update Feature Flag';
export const FEATURE_FLAG_UPDATED = '[Auth] Feature Flag Updated';

export const GetLoggedInUser = createAction(
  GET_LOGGED_IN_USER,
  (forceRefresh = false, redirectTo: string | undefined = undefined) => ({forceRefresh, redirectTo})
);

export const RefreshLoggedInOrg = createAction(REFRESH_LOGGED_IN_ORG);

export const SetLoggedInOrg = createAction(
  SET_LOGGED_IN_ORG,
  (payload: Organization) => ({payload})
);

export const SetCaseSettings = createAction(
  SET_CASE_SETTINGS,
  (payload: CaseSettings) => ({payload})
);

export const SetAttorneyClientSettings = createAction(
  SET_ATTORNEY_CLIENT_SETTINGS,
  (payload: AttorneyClientSettings) => ({payload})
);

export const SetScraMonthlyLimit = createAction(
  SET_SCRA_MONTHLY_LIMIT,
  (payload: number) => ({payload})
);

export const Authenticated = createAction(
  AUTHENTICATED,
  (user: User, redirectToDashboard = false) => ({user, redirectToDashboard})
);

export const NotAuthenticated = createAction(
  NOT_AUTHENTICATED,
  (payload?: any) => ({payload})
);

export const AuthError = createAction(
  AUTH_ERROR,
  (message: string | undefined = undefined) => ({message})
);

/// Logout Actions

export const Logout = createAction(
  LOGOUT,
  (payload?: any) => ({payload})
);

export const NoAction = createAction(
  NO_ACTION
);

export const UserDetailsUpdated = createAction(
  USER_DETAILS_UPDATED,
  (payload: User) => ({payload})
);

export const UserBarLicensesUpdated = createAction(
  USER_BAR_LICENSES_UPDATED,
  (payload: { string: string }) => ({payload})
);

export const UserEmailUpdated = createAction(
  USER_EMAIL_UPDATED,
  (payload: string) => ({payload})
);

export const NotificationSettingsUpdated = createAction(
  NOTIFICATION_SETTINGS_UPDATED,
  (payload: NotificationSettings) => ({payload})
);

export const UserDashboardSettingsUpdated = createAction(
  USER_DASHBOARD_SETTINGS_UPDATED,
  (payload: UserDashboardSettings) => ({payload})
);

export const UserSettingUpdated = createAction(
  USER_SETTING_UPDATED,
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  (payload: { key: string; value: any | null }) => (payload)
);

export const CaseGridSettingsUpdated = createAction(
  CASE_GRID_SETTINGS_UPDATED,
  (payload: ColDef[]) => ({payload})
);

export const RefreshMetrics = createAction(
  REFRESH_METRICS
);

export const DashboardWidgetsUpdated = createAction(
  DASHBOARD_WIDGETS_UPDATED,
  (payload: DashboardWidgets | PropertyManagerDashboardWidgets) => ({payload})
);

export const RefreshTaskCount = createAction(
  REFRESH_TASK_COUNT
);

export const LaunchAttorneyGettingStarted = createAction(
  DASHBOARD_LAUNCH_ATTORNEY_GETTING_STARTED
);

export const GuidesViewedUpdated = createAction(
  GUIDES_VIEWED_UPDATED,
  (payload: number) => ({payload})
);

export const LoggedInOrgUpdated = createAction(
  LOGGED_IN_ORG_UPDATED,
  (payload: Organization) => ({payload})
);

export const LoggedInUserUpdated = createAction(
  LOGGED_IN_USER_UPDATED,
  (payload: User) => ({payload})
);

export const DefaultWorkflowUpdated = createAction(
  DEFAULT_WORKFLOW_UPDATED,
  (payload: string) => ({payload})
);

export const ActivationRequestSent = createAction(
  ACTIVATION_REQUEST_SENT,
  (payload: boolean) => ({payload})
);

export const LoadAppSettings = createAction(LOAD_APP_SETTING);

export const AppSettingsLoaded = createAction(
  APP_SETTING_LOADED,
  (payload: any) => ({payload})
);

export const UpdateUnreadMessageCount = createAction(
  UPDATE_UNREAD_MESSAGE_COUNT,
  (count: number) => ({count})
);

export const UpdateAllTags = createAction(
  UPDATE_ALL_TAGS,
  (tags: Tag[] = []) => ({tags})
);

export const UpdateTags = createAction(
  UPDATE_TAGS,
  (tagType: TagType, tags: Tag[] = []) => ({tagType, tags})
);

export const CustomCaseFieldsUpdated = createAction(
  CUSTOM_CASE_FIELDS_UPDATES,
  (fields: CustomField[]) => ({fields})
);

export const UpdateFeatureFlag = createAction(
  UPDATE_FEATURE_FLAG,
  (feature: string, enabled: boolean) => ({feature, enabled})
);

export const FeatureFlagUpdated = createAction(
  FEATURE_FLAG_UPDATED,
  (feature: string, enabled: boolean) => ({feature, enabled})
);

export const UpdatePermissionGroups = createAction(UPDATE_PERMISSION_GROUPS, (roles: PermissionGroup[]) => ({ roles }));

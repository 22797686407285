import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { accountState } from './auth.route';
import { RegisterComponent } from './register/register.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { StoreModule } from '@ngrx/store';
import { userReducer } from '../core/reducers/user.reducer';
import { ActivateAttorneyComponent } from './activate/attorney/activate-attorney.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { CoreModule } from '../core/core.module';
import { BillingModule } from '../billing/billing.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { ActivatePropertyManagerComponent } from './activate/property-manager/activate-property-manager.component';
import { ActivateNewUserComponent } from './activate/new-user/activate-new-user.component';
import { MatRadioModule } from '@angular/material/radio';
import { LoadingModule } from '@ee/common/loading';
import { AddressAutocompleteModule } from '@ee/common/address-autocomplete';
import { UploadLogoModule } from '@ee/common/upload-logo';
import { EePipesModule } from '@ee/common/pipes';
import { PasswordStrengthBarModule } from '@ee/common/password-strength-bar';
import { AuthorizeComponent } from './authorize.component';
import { NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';
import { LoginComponent } from './login.component';

@NgModule({
  imports: [
    RouterModule.forChild(accountState),
    StoreModule.forFeature('user', userReducer),
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    MatIconModule,
    MatStepperModule,
    MatSelectModule,
    CoreModule,
    BillingModule,
    SharedComponentsModule,
    MatRadioModule,
    LoadingModule,
    AddressAutocompleteModule,
    UploadLogoModule,
    EePipesModule,
    PasswordStrengthBarModule,
    NgxMaskDirective
  ],
  declarations: [
    RegisterComponent,
    ActivateAttorneyComponent,
    ActivatePropertyManagerComponent,
    ActivateNewUserComponent,
    AuthorizeComponent,
    LoginComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [provideEnvironmentNgxMask()]
})
export class AuthModule {}

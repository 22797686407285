import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'ee-banner',
  template: `
    <div class="banner {{type}}" [ngClass]="{'main': main}">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      @import 'components/color-palette';

      .banner {
        background-color: #fff;
        border-bottom: 1px solid $c-gray-80;
        padding: 18px;
        font-size: 16px;
        font-weight: 500;

        &.primary {
          color: $banner-default-color;
          background-color: $banner-default-background-color;
        }

        &.secondary {
          color: $banner-secondary-color;
          background-color: $banner-secondary-background-color;
        }

        &.warning {
          color: $banner-warning-color;
          background-color: $banner-warning-background-color;
        }

        &.error {
          background-color: $banner-error-color;
          color: $banner-v-background-color;
        }
      }
    `
  ]
})
export class BannerComponent implements OnInit, OnChanges {
  @Input() main = false;

  @Input() type: 'primary' | 'secondary' | 'warning' | 'error' | 'info' = 'info';

  ngOnInit() {
    this.checkRequired();
  }

  ngOnChanges(): void {
    this.checkRequired();
  }

  checkRequired() {
    if (this.type === null) {
      throw new Error('Attribute \'type\' is required');
    }
  }
}

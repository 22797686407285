import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialogActions, MatDialogContent, MatDialogTitle} from '@angular/material/dialog';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormField, MatHint, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatButton} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {ClientSelectionDialogComponent} from './client-selection-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EeDirectivesModule} from '@ee/common/directives';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogTitle,
    MatDialogContent,
    MatFormField,
    MatHint,
    MatInput,
    MatLabel,
    MatButton,
    MatDialogActions,
    MatSelectModule,
    MatAutocompleteModule,
    EeDirectivesModule
  ],
  declarations: [ClientSelectionDialogComponent],
  exports: [ClientSelectionDialogComponent]
})
export class ClientSelectionDialogModule {
  public static forRoot(environment: any): ModuleWithProviders<ClientSelectionDialogModule> {
    return {
      ngModule: ClientSelectionDialogModule,
      providers: [{ provide: 'environment', useValue: environment }]
    };
  }
}

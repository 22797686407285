import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {mergeMap, Observable, of, timer, withLatestFrom} from 'rxjs';
import {catchError, delay, map, skipWhile, switchMap, takeUntil, takeWhile} from 'rxjs/operators';
import * as attachmentActions from '../actions/attachment.actions';
import {REFRESH_EVICTION_ATTACHMENTS} from '../actions/attachment.actions';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Attachment, KeyValue} from '@ee/common/models';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ShowAutoClosableAlert} from '../actions/alert.actions';
import * as fromEviction from '../reducers/eviction.reducer';
import {Store} from '@ngrx/store';

@Injectable()
export class AttachmentEffects {
  addAttachment$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(attachmentActions.ADD_ATTACHMENT),
      map((action: attachmentActions.AddAttachment) => action.payload),
      switchMap((attachmentDetails: KeyValue<string, FormData>) =>
        this.http
          .post<Attachment>(
            `${environment.api_prefix}api/evictions/${attachmentDetails.key}/attachments`,
            attachmentDetails.value
          )
          .pipe(
            switchMap((addedAttachment: Attachment) => {
              this.snackBar.open('Attachment saved', 'Dismiss', { duration: 3000 });
              return [new attachmentActions.AttachmentAdded(addedAttachment)];
            }),
            catchError(() => [new ShowAutoClosableAlert('Error uploading attachment.')])
          )
      )
    )
  );

  deleteAttachment$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(attachmentActions.DELETE_ATTACHMENT),
      map((action: attachmentActions.DeleteAttachment) => action.payload),
      switchMap((kvp: KeyValue<string, Attachment>) =>
        this.http.delete(`${environment.api_prefix}api/evictions/${kvp.key}/attachments/${kvp.value.id}`).pipe(
          switchMap(() => {
            this.snackBar.open('Attachment deleted', 'Dismiss', { duration: 3000 });
            return [new attachmentActions.AttachmentDeleted(kvp.value.id)];
          }),
          catchError(() => [new ShowAutoClosableAlert('Error deleting attachment.')])
        )
      )
    )
  );

  updateAttachmentVisibility$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(attachmentActions.UPDATE_ATTACHMENT_VISIBILITY),
      map((action: attachmentActions.UpdateAttachmentVisibility) => action),
      switchMap((update) =>
        this.http.put(`${environment.api_prefix}api/evictions/${update.caseId}/attachments/` +
            `${update.attachmentId}/hide-from-client/${update.hideFromClient ? 'true' : 'false'}`, {}).pipe(
          switchMap(() => {
            return [new attachmentActions.AttachmentVisibilityUpdated(update.attachmentId, update.hideFromClient)];
          }),
          catchError(() => [new ShowAutoClosableAlert('Error updating attachment visibility.')])
        )
      )
    )
  );

  pollGeneratedDocument$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(attachmentActions.POLL_FOR_ATTACHMENT),
      map((action: attachmentActions.PollForAttachment) => action.formId),
      mergeMap((attachmentId: string) =>
        timer(0, 3000).pipe(
          // Stop the polling (used only in testing)
          takeUntil(of(true).pipe(delay(30000))),
          switchMap(() =>
            this.http.get<Attachment>(`${environment.api_prefix}api/evictions/attachment-generated/${attachmentId}`)
          ),
          takeWhile((attachment: Attachment) => !attachment || !attachment.ready, true),
          switchMap((attachment: Attachment) =>
            of(attachment).pipe(
              skipWhile((a: Attachment) => !a || !a.ready),
              map((a: Attachment) => new attachmentActions.AttachmentReady(a.id))
            )
          )
        )
      )
    )
  );

  refreshEvictionAttachments$: Observable<any> = createEffect(() => this.actions$?.pipe(
    ofType(REFRESH_EVICTION_ATTACHMENTS),
    map((action: attachmentActions.RefreshEvictionAttachments) => action),
    withLatestFrom(this.store.select(fromEviction.getCurrentEviction)),
    switchMap(([payload, eviction]) => {
      const loadEviction: any[] = [];
      if (eviction.case_id === payload.caseId) {
        loadEviction.push(new attachmentActions.SetEvictionAttachments(payload.attachments));
      }
      return loadEviction;
    })
  ));

  constructor(private actions$: Actions, private snackBar: MatSnackBar, private http: HttpClient, private store: Store) {
  }
}

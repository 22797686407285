import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {CaseSettings} from '@ee/common/models';

@Injectable({providedIn: 'root'})
export class CaseSettingService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  update(settings: CaseSettings): Observable<CaseSettings> {
    return this.http.post<CaseSettings>(this.environment.api_prefix + 'api/case-settings', settings);
  }

}

import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AvailableFieldValidator} from '@ee/common/validators';
import {Tag, TagColor} from '@ee/common/models';

export type TagFormType = FormGroup<{
  value: FormControl<string | null>;
  name: FormControl<string>;
  color: FormControl<TagColor>;
}>

export function GenerateTagForm(fb: FormBuilder, existingLabel: Tag, existingTags: Tag[]): TagFormType {
  const control = fb.group({
    value: new FormControl(existingLabel?.value ?? ''),
    name: new FormControl(existingLabel?.name ?? '',
      [Validators.required, AvailableFieldValidator(existingTags.map(t => t.name))]),
    color: new FormControl(existingLabel?.color ?? 'gray', Validators.required),
  });

  // add uniqueness validator

  return control;
}

import {createFeatureSelector, createSelector} from '@ngrx/store';
import {BillableItem, Eviction} from '@ee/common/models';
import * as fromRoot from './app.reducer';
import orderBy from 'lodash-es/orderBy';
import findIndex from 'lodash-es/findIndex';
import sortBy from 'lodash-es/sortBy';
import cloneDeep from 'lodash-es/cloneDeep';
import {
  BILL_DELETED,
  BILL_SAVED,
  BILL_UPDATED,
  CASE_TENANT_UPDATED,
  COURT_RESULT_UPDATED,
  EVICTION_REOPENED,
  EVICTION_STATUS_UPDATED,
  EvictionActions,
  FIELD_UPDATED,
  SCHEDULED_EMAIL_CANCELED,
  SET_CURRENT_EVICTION,
  SET_EVICTION_BILLS,
  SET_STAGED_EVICTIONS,
  STEP_COMPLETED,
  TAGS_UPDATED,
  UPDATE_SCRA_CHECK_STATUS,
  WATCH_SETTINGS_UPDATED
} from '../actions/eviction.actions';
import {CustomFieldType} from '@ee/common/enums';

export interface EvictionState {
  stagedEvictions: Eviction[];
  currentEviction: Eviction;
  currentEvictionBills: BillableItem[];
}

export interface State extends fromRoot.State {
  eviction: EvictionState;
}

export const initialState: EvictionState = {
  stagedEvictions: [],
  currentEviction: null,
  currentEvictionBills: []
};

export function evictionReducer(state = initialState, action: EvictionActions) {
  let currentEviction;
  let currentEvictionBills: BillableItem[];
  let index;
  switch (action.type) {
    case SET_CURRENT_EVICTION:
      action.payload.status_history = sortBy(action.payload.status_history, 'step');
      return {...state, currentEviction: action.payload};
    case SET_STAGED_EVICTIONS:
      return {...state, stagedEvictions: action.payload};
    case STEP_COMPLETED:
      currentEviction = action.payload;
      // update active evictions list
      return updateCurrentEvictionState();
    case EVICTION_STATUS_UPDATED:
      currentEviction = cloneDeep(state.currentEviction);
      currentEviction.closed_date = action.payload.closed_date;
      currentEviction.eviction_updates = action.payload.eviction_updates;
      currentEviction.tenant_total_owed = action.payload.tenant_total_owed;
      currentEviction.assigned_attorney_name = action.payload.assigned_attorney_name;
      currentEviction.tenant_outstanding_balance = action.payload.tenant_outstanding_balance;
      currentEviction.partial_payments = action.payload.partial_payments;
      return updateCurrentEvictionState();
    case SET_EVICTION_BILLS:
      return {...state, currentEvictionBills: orderBy(action.payload, ['created_date'], ['desc'])};
    case BILL_SAVED:
      if (state.currentEviction) {
        currentEvictionBills = cloneDeep(state.currentEvictionBills);
        currentEviction = cloneDeep(state.currentEviction);
        currentEvictionBills.push(action.payload);
        currentEviction.outstanding_balance = calculateBalance(currentEvictionBills);
        currentEviction.status_history = sortBy(currentEviction.status_history, 'step');
        return {...state, currentEvictionBills, currentEviction};
      }
      return state;
    case BILL_UPDATED:
      if (state.currentEviction) {
        currentEvictionBills = cloneDeep(state.currentEvictionBills);
        currentEviction = cloneDeep(state.currentEviction);
        index = findIndex(currentEvictionBills, {id: action.payload.id});
        currentEvictionBills[index] = action.payload;
        currentEviction.outstanding_balance = calculateBalance(currentEvictionBills);
        currentEviction.status_history = sortBy(currentEviction.status_history, 'step');
        return {...state, currentEvictionBills, currentEviction};
      }
      return state;
    case BILL_DELETED:
      if (state.currentEviction) {
        currentEvictionBills = cloneDeep(state.currentEvictionBills);
        currentEviction = cloneDeep(state.currentEviction);
        currentEviction.status_history = sortBy(currentEviction.status_history, 'step');
        index = findIndex(currentEvictionBills, {id: action.payload});
        currentEvictionBills[index].deleted = true;
        currentEvictionBills[index].deleted_by = action.userName;
        currentEvictionBills[index].deleted_date = new Date().toISOString();
        currentEviction.outstanding_balance = calculateBalance(currentEvictionBills);
        return {...state, currentEvictionBills, currentEviction};
      }
      return state;
    case EVICTION_REOPENED:
      currentEviction = action.payload;
      return updateCurrentEvictionState();
    case TAGS_UPDATED:
      currentEviction = cloneDeep(state.currentEviction) ;
      currentEviction.tags = action.payload;
      return {...state, currentEviction};
    case SCHEDULED_EMAIL_CANCELED:
      currentEviction = cloneDeep(state.currentEviction) ;
      currentEviction.scheduled_email_history = action.payload;
      return {...state, currentEviction};
    case WATCH_SETTINGS_UPDATED:
      currentEviction = cloneDeep(state.currentEviction);
      currentEviction.watcher_settings = action.watcherSettings;
      currentEviction.case_watcher_user_ids = currentEviction.case_watcher_user_ids.filter(id => id !== action.user.id);
      currentEviction.watchers = currentEviction.watchers.filter(watcher => watcher.id !== action.user.id);

      if (action.watcherSettings) {
        currentEviction.case_watcher_user_ids = [...currentEviction.case_watcher_user_ids, action.user.id];
        currentEviction.watchers = [...currentEviction.watchers, { ...action.user, type: action.organization.type }];
      }

      return {...state, currentEviction};
    case COURT_RESULT_UPDATED:
      currentEviction = cloneDeep(state.currentEviction) ;
      currentEviction.court_result = action.courtResult;
      return {...state, currentEviction};
    case CASE_TENANT_UPDATED:
      currentEviction = cloneDeep(state.currentEviction);
      currentEviction.tenants = action.tenants;
      return {...state, currentEviction};
    case UPDATE_SCRA_CHECK_STATUS:
      currentEviction = cloneDeep(state.currentEviction);
      index = findIndex(currentEviction.tenants, {id: action.tenantId});
      currentEviction.tenants[index].scra_scan_status = action.status;
      return {...state, currentEviction};
    case FIELD_UPDATED:
      currentEviction = cloneDeep(state.currentEviction) ;
      if (action.event.type === CustomFieldType.DATE) {
        currentEviction[action.event.field] = action.event.value?.toISOString();
        if (action.event.field === 'current_step_due_date') {
          currentEviction['current_step_days_left'] = null;
        }
      } else {
        currentEviction[action.event.field] = action.event.value;
      }
      return {...state, currentEviction};
    default:
      return state;
  }

  function updateCurrentEvictionState() {
    const updateState = {...state, currentEviction};
    currentEviction.status_history = sortBy(currentEviction.status_history, 'step');
    return updateState;
  }

  function calculateBalance(bills: BillableItem[]): number {
    return bills.reduce((total, item) => total + item.amount - (item.paid ? item.paid : 0), 0);
  }
}

export const getEvictionState = createFeatureSelector<EvictionState>('eviction');

export const getCurrentEviction = createSelector(
  getEvictionState,
  (state: EvictionState) => state.currentEviction
);
export const getCurrentEvictionBills = createSelector(
  getEvictionState,
  (state: EvictionState) => state.currentEvictionBills
);

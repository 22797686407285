import {AbstractControl, ValidationErrors} from '@angular/forms';
import {debounceTime, map, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {UserService} from '@ee/common/services';

export class ValidateEmailNotTaken {
  static createValidator(userService: UserService, limitToCurrentOrganization = false, clientId: string = null) {
    const result = (control: AbstractControl): Observable<ValidationErrors | null> =>
      userService.checkEmailAvailability(control.value, limitToCurrentOrganization, clientId).pipe(
        debounceTime(500),
        take(1),
        map(res => res ? null : {emailTaken: true}));
    return result;
  }
}

// code adapted from https://coryrylan.com/blog/creating-a-dynamic-checkbox-list-in-angular

import {FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function NotEqualValidator(value: any): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    if (control?.value) {
      return value === control.value ? { notEqual: true } : null;
    }
    return null;
  };
}

import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomField, IntakeFormSection} from '@ee/common/models';
import {GenerateCustomFieldBuilderForm} from './custom-field.form';
import {IntakeFormSectionType} from '@ee/common/enums';

export function GenerateIntakeFieldForm(fb: UntypedFormBuilder, section: IntakeFormSection = {} as IntakeFormSection): UntypedFormGroup {
  const result = fb.group({
    title: [section.title, [Validators.required]],
    type: [section.type, [Validators.required]],
    order: [section.order],
    description: [section.description, [Validators.required]],
    editable: [section.editable],
    multiple: section.multiple,
    add_multiple_button_label: section.add_multiple_button_label,
    multiple_field: section.multiple_field,
    fields: fb.array([]),
    rules: [section.rules ?? []]
  });

  // loop through controls and set them up as well
  if (section) {
    if (section.fields) {
      const fieldsControl = result.controls['fields'] as UntypedFormArray;
      section.fields.sort((a, b) => a.order - b.order).forEach((field: CustomField) => {
        fieldsControl.push(GenerateCustomFieldBuilderForm(fb, field));
      });
    }

    if (section.type === IntakeFormSectionType.CALCULATED) {
      const calculatedField = GenerateCustomFieldBuilderForm(fb, section.calculated_field);
      calculatedField.get('value').removeValidators(Validators.required);
      result.registerControl('calculated_field', calculatedField);
    }
  }

  return result;
}

import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Coupon, PaymentMethod} from '@ee/common/models';
import {SubscriptionPlan} from '@ee/common/enums';

@Injectable({ providedIn: 'root' })
export class BillingService {
  public resourceUrl: string;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
    this.resourceUrl = this.environment.api_prefix + 'api/billing';
  }

  getPaymentMethods(): Observable<PaymentMethod[]> {
    return this.http.get<PaymentMethod[]>(`${this.resourceUrl}/payment-method`);
  }

  findCoupon(couponCode: string): Observable<Coupon> {
    return this.http.get<Coupon>(`${this.resourceUrl}/coupon/${couponCode}`);
  }

  calculateProratedAmount(plan: SubscriptionPlan, users: number, coupon: string = null): Observable<number> {
    let couponParam = '';
    if (coupon) {
      couponParam = `?coupon=${coupon}`;
    }
    return this.http.get<number>(`${this.resourceUrl}/proration-amount/${plan}/${users}${couponParam}`);
  }
}

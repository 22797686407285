import {createAction} from '@ngrx/store';
import {BillableItem, CreditNote, Invoice, InvoiceCredit, InvoicePayment} from '@ee/common/models';

export const LOAD_INVOICES = '[Invoice] Load Invoices';
export const SET_INVOICES = '[Invoice] Set Invoices';
export const LOAD_INVOICE = '[Invoice] Load Invoice';
export const SET_CURRENT_INVOICE = '[Eviction] Set Current Invoice';
export const RECORD_INVOICE_PAYMENT = '[Invoice] Record Invoice Payment';
export const RECORD_INVOICE_PAYMENTS = '[Invoice] Record Invoice Payments';
export const DELETE_INVOICE_PAYMENT = '[Invoice] Delete Invoice Payment';
export const APPLY_INVOICE_CREDIT = '[Invoice] Apply Invoice Credit';
export const DELETE_INVOICE_CREDIT = '[Invoice] Delete Invoice Credit';
export const INVOICE_SAVED = '[Invoice] Invoice Saved';
export const INVOICES_PAYMENT_RECORDED = '[Invoice] Invoices Payment Recorded';
export const DELETE_INVOICE = '[Invoice] Delete Invoice';
export const REMOVE_ONLINE_PAYMENT_OPTION = '[Invoice] Remove Online Payment Option';
export const INVOICE_DELETED = '[Invoice] Invoice Deleted';
export const LOAD_INVOICE_DASHBOARD_WIDGETS = '[Invoice] Load Dashboard Widgets';
export const SET_INVOICE_DASHBOARD_WIDGETS = '[Invoice] Set Dashboard Widgets';
export const LOAD_INVOICE_BILLABLE_ITEMS = '[Invoice] Load Invoice Billable Items';
export const SET_INVOICE_BILLABLE_ITEMS = '[Invoice] Set Invoice Billable Items';
export const INVOICE_BILLABLE_ITEM_UPDATED = '[Invoice] Invoice Billable Item Updated';
export const INVOICE_BILLABLE_ITEM_DELETED = '[Invoice] Invoice Billable Item Deleted';
export const LOAD_CREDIT_NOTES = '[Invoice] Load Credit Notes';
export const CREDIT_NOTES_LOADED = '[Invoice] Credit Notes Loaded';
export const CREDIT_NOTE_CREATED = '[Invoice] Credit Note Created';
export const CREDIT_NOTE_UPDATED = '[Invoice] Credit Note Updated';
export const CREDIT_NOTE_DELETED = '[Invoice] Credit Note Deleted';
export const DOWNLOAD_INVOICES = '[Invoice] Download Invoices';

export const LoadInvoices = createAction(LOAD_INVOICES);

export const SetInvoices = createAction(
  SET_INVOICES,
  (payload: Invoice[]) => ({ payload })
);

export const LoadInvoice = createAction(
  LOAD_INVOICE,
  (payload: string) => ({ payload })
);

export const SetCurrentInvoice = createAction(
  SET_CURRENT_INVOICE,
  (payload: Invoice) => ({ payload })
);

export const InvoiceSaved = createAction(
  INVOICE_SAVED,
  (payload: Invoice) => ({ payload })
);

export const InvoicesPaymentRecorded = createAction(
  INVOICES_PAYMENT_RECORDED,
  (payload: Invoice[]) => ({ payload })
);

export const DeleteInvoice = createAction(
  DELETE_INVOICE,
  (payload: string) => ({ payload })
);

export const RemoveOnlinePaymentOption = createAction(
  REMOVE_ONLINE_PAYMENT_OPTION,
  (payload: string) => ({ payload })
);

export const InvoiceDeleted = createAction(
  INVOICE_DELETED,
  (payload: string) => ({ payload })
);

export const RecordInvoicePayment = createAction(
  RECORD_INVOICE_PAYMENT,
  (id: string, invoicePayment: InvoicePayment) => ({ id, invoicePayment })
);

export const RecordInvoicePayments = createAction(
  RECORD_INVOICE_PAYMENTS,
  (invoicePayments: InvoicePayment[]) => ({ invoicePayments })
);

export const DeleteInvoicePayment = createAction(
  DELETE_INVOICE_PAYMENT,
  (id: string, paymentId: string, reason: string) => ({ id, paymentId, reason })
);

export const ApplyInvoiceCredit = createAction(
  APPLY_INVOICE_CREDIT,
  (id: string, invoiceCredit: InvoiceCredit) => ({ id, invoiceCredit })
);

export const DeleteInvoiceCredit = createAction(
  DELETE_INVOICE_CREDIT,
  (id: string, creditId: string, reason: string) => ({ id, creditId, reason })
);

export const LoadInvoiceDashboardWidgets = createAction(
  LOAD_INVOICE_DASHBOARD_WIDGETS
);

export const SetInvoiceDashboardWidgets = createAction(
  SET_INVOICE_DASHBOARD_WIDGETS,
  (payload: {[key: string]: any}) => ({ payload })
);

export const LoadInvoiceBillableItems = createAction(
  LOAD_INVOICE_BILLABLE_ITEMS,
  (clientId: string | undefined, includeClosedCases: boolean) => ({ clientId, includeClosedCases })
);

export const SetInvoiceBillableItems = createAction(
  SET_INVOICE_BILLABLE_ITEMS,
  (payload: BillableItem[]) => ({ payload })
);

export const InvoiceBillableItemUpdated = createAction(
  INVOICE_BILLABLE_ITEM_UPDATED,
  (payload: BillableItem) => ({ payload })
);

export const InvoiceBillableItemDeleted = createAction(
  INVOICE_BILLABLE_ITEM_DELETED,
  (payload: string | undefined) => ({ payload })
);

export const DownloadInvoices = createAction(
  DOWNLOAD_INVOICES,
  (invoicesIds: string[]) => ({ invoicesIds })
);

export const LoadCreditNotes = createAction(
  LOAD_CREDIT_NOTES,
  (invoiceId: string) => ({ invoiceId })
);

export const CreditNotesLoaded = createAction(
  CREDIT_NOTES_LOADED,
  (creditNotes: CreditNote[]) => ({ creditNotes })
);

export const CreditNoteCreated = createAction(
  CREDIT_NOTE_CREATED,
  (creditNote: CreditNote) => ({ creditNote })
);

export const CreditNoteUpdated = createAction(
  CREDIT_NOTE_UPDATED,
  (creditNote: CreditNote) => ({ creditNote })
);

export const CreditNoteDeleted = createAction(
  CREDIT_NOTE_DELETED,
  (id: string) => ({ id })
);

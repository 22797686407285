import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as guidedTourActions from '../actions/guided-tour.actions';
import * as fromRoot from '../reducers/app.reducer';
import {Store} from '@ngrx/store';
import {GuideViewed} from '../actions/user.actions';
import {User} from '@ee/common/models';
import forEach from 'lodash-es/forEach';
import {productFruits} from 'product-fruits';

@Injectable()
export class GuidedTourEffects {

  showTour: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(guidedTourActions.SHOW_TOUR),
    map((action: guidedTourActions.ShowTour) => action),
    concatLatestFrom(() => this.store.select(fromRoot.getLoggedInUser)),
    concatLatestFrom(() => this.store.select(fromRoot.getLoggedInOrg)),
    map(([[params, user]]) => {
      if ((user?.tos_confirm && user?.policy_confirm &&
        user?.recently_viewed_guides?.indexOf(params.tour) === -1) || params.forceShow) {
        productFruits.safeExec(() => {
          (window as any).productFruits.api.tours.tryStartTour(params.tour);
          this.recordTourViewed(user, params.tour);
          params.additionalToursToMarkAsViewed.forEach((tour: number) => {
            this.recordTourViewed(user, tour);
          });
        });
      }
    })
  ), {dispatch: false});

  private conditionsMatch(conditions: any, stepConditions: any): boolean {
    let match = true;

    if (stepConditions) {
      // if a single condition is not met then we're returning false
      forEach(stepConditions, (value, key) => {
        if (!conditions || conditions[key] !== value) {
          match = false;
        }
      });
    }

    return match;
  }

  private recordTourViewed(user: User, tour: number) {
    if (user.recently_viewed_guides?.indexOf(tour) === -1) {
      this.store.dispatch(GuideViewed(tour));
    }
  }

  constructor(private actions$: Actions, private store: Store) {
  }
}

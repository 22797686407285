import { Action } from '@ngrx/store';
import {
  AuditableEvent,
  BillableItem,
  CaseWatcherSettings,
  ClientAnswers,
  Eviction,
  EvictionUpdate,
  KeyValue, Organization,
  ScheduledEmailSettings,
  StepCompletionModel,
  Tag, Tenant, User
} from '@ee/common/models';
import { Params } from '@angular/router';
import {ScraScanStatus} from '@ee/common/enums';

export const SET_ACTIVE_EVICTIONS = '[Eviction] Set Active Evictions';
export const SET_STAGED_EVICTIONS = '[Eviction] Set Staged Evictions';
export const ADD_NEW_EVICTION = '[Eviction] Add New Eviction';
export const EDIT_EVICTION = '[Eviction] Edit Eviction';
export const LOAD_EVICTION = '[Eviction] Load Eviction';
export const SET_CURRENT_EVICTION = '[Eviction] Set Current Eviction';
export const SAVE_NEW_EVICTION = '[Eviction] Save New Eviction';
export const EVICTION_STATUS_UPDATE = '[Eviction] Eviction status update';
export const EVICTION_STATUS_UPDATED = '[Eviction] Eviction status updated';
export const COMPLETE_STEP = '[Eviction] Complete Step';
export const STEP_COMPLETED = '[Eviction] Eviction Step Complete';
export const SET_EVICTION_BILLS = '[Eviction] Set Eviction Bills';
export const SAVE_BILL = '[Eviction] Save Bill';
export const BILL_SAVED = '[Eviction] Bill Saved';
export const UPDATE_BILL = '[Eviction] Update Bill';
export const BILL_UPDATED = '[Eviction] Bill Updated';
export const DELETE_BILL = '[Eviction] Delete Bill';
export const BILL_DELETED = '[Eviction] Bill Deleted';
export const EVICTION_REOPEN = '[Eviction] Reopen Eviction';
export const EVICTION_REOPENED = '[Eviction] Reopened';
export const ADD_TAG = '[Eviction] Add Tag';
export const REMOVE_TAG = '[Eviction] Remove Tag';
export const SAVE_CASE_TAGS = '[Eviction] Save Case Tags';
export const TAGS_UPDATED = '[Eviction] Tags Updated';
export const CANCEL_SCHEDULED_EMAIL = '[Eviction] Cancel Scheduled Email';
export const SCHEDULED_EMAIL_CANCELED = '[Eviction] Scheduled Email Canceled';
export const GENERATE_FILLABLE_FORM_DETAILS = '[Eviction] Generate Fillable Form Details';
export const WATCH_CASE = '[Eviction] Watch Case';
export const WATCH_SETTINGS_UPDATED = '[Eviction] Watch Updated';
export const UPDATE_EVICTION_DETAILS = '[Eviction] Update Details';
export const SEND_CLIENT_ANSWER = '[Eviction] Send Client Answer';
export const UPDATE_COURT_RESULT = '[Eviction] Update Court Result';
export const COURT_RESULT_UPDATED = '[Eviction] Court Result Updated';
export const FIELD_UPDATED = '[Eviction] Field Updated';
export const DOWNLOAD_CASE_FILES = '[Eviction] Download Case Files';
export const CASE_TENANT_UPDATED = '[Eviction] Case tenant updated';
export const REFRESH_CASE_TENANTS = '[Eviction] Refresh tenant case';
export const DO_SCRA_CHECK = '[Eviction] Do SCRA Check';
export const UPDATE_SCRA_CHECK_STATUS = '[Eviction] Update SCRA check status';



export class SetStagedEvictions implements Action {
  readonly type = SET_STAGED_EVICTIONS;

  constructor(public payload: Eviction[]) {
  }
}

export class LoadEviction implements Action {
  readonly type = LOAD_EVICTION;

  constructor(public payload: string, public queryParams: Params | null = null) {
  } // eviction id
}

export class SetCurrentEviction implements Action {
  readonly type = SET_CURRENT_EVICTION;

  constructor(public payload: Eviction) {
  }
}

export class SaveNewEviction implements Action {
  readonly type = SAVE_NEW_EVICTION;

  constructor(public payload: Eviction) {
  }
}

export class UpdateEvictionDetails implements Action {
  readonly type = UPDATE_EVICTION_DETAILS;

  constructor(public payload: Eviction) {
  }
}

export class UpdateEvictionStatus implements Action {
  readonly type = EVICTION_STATUS_UPDATE;

  constructor(public caseId: string, public payload: EvictionUpdate) {
  }
}

export class EvictionStatusUpdated implements Action {
  readonly type = EVICTION_STATUS_UPDATED;

  constructor(public payload: Eviction) {
  }
}

export class UpdateCourtResult implements Action {
  readonly type = UPDATE_COURT_RESULT;

  constructor(public caseId: string, public courtResult: string) {
  }
}

export class CourtResultUpdated implements Action {
  readonly type = COURT_RESULT_UPDATED;

  constructor(public caseId: string, public courtResult: string) {
  }
}

export class CaseFieldUpdated implements Action {
  readonly type = FIELD_UPDATED;

  constructor(public event: AuditableEvent, public evictionUpdate: EvictionUpdate) {
  }
}

export class EvictionCompleteStep implements Action {
  readonly type = COMPLETE_STEP;

  constructor(public payload: StepCompletionModel) {
  }
}

export class EvictionStepCompleted implements Action {
  readonly type = STEP_COMPLETED;

  constructor(public payload: Eviction) {
  }
}

/* Billing-related actions */
export class SetEvictionBills implements Action {
  readonly type = SET_EVICTION_BILLS;

  constructor(public payload: BillableItem[] | undefined) {
  }
}

export class SaveBill implements Action {
  readonly type = SAVE_BILL;

  constructor(public payload: BillableItem) {
  }
}

export class BillSaved implements Action {
  readonly type = BILL_SAVED;

  constructor(public payload: BillableItem) {
  }
}

export class UpdateBill implements Action {
  readonly type = UPDATE_BILL;

  constructor(public payload: BillableItem) {
  }
}

export class BillUpdated implements Action {
  readonly type = BILL_UPDATED;

  constructor(public payload: BillableItem) {
  }
}

export class DeleteBill implements Action {
  readonly type = DELETE_BILL;

  constructor(public payload: BillableItem, public userName: string) {
  }
}

export class BillDeleted implements Action {
  readonly type = BILL_DELETED;

  constructor(public payload: string | undefined, public userName: string) { // id
  }
}

export class EvictionReopen implements Action {
  readonly type = EVICTION_REOPEN;

  constructor(public payload: KeyValue<string, number>) { // id
  }
}

export class EvictionReopened implements Action {
  readonly type = EVICTION_REOPENED;

  constructor(public payload: Eviction) { // id
  }
}

export class SaveCaseTags implements Action {
  readonly type = SAVE_CASE_TAGS;

  constructor(public caseId: string, public tags: Tag[]) { // id
  }
}

export class TagsUpdated implements Action {
  readonly type = TAGS_UPDATED;
  constructor(public payload: Tag[]) { }// id
}

export class CancelScheduledEmail implements Action {
  readonly type = CANCEL_SCHEDULED_EMAIL;

  constructor(public payload: string) { // id
  }
}

export class ScheduledEmailCanceled implements Action {
  readonly type = SCHEDULED_EMAIL_CANCELED;

  constructor(public payload: ScheduledEmailSettings[]) { // id
  }
}

export class GenerateFillableFormDetails implements Action {
  readonly type = GENERATE_FILLABLE_FORM_DETAILS;

  constructor(public caseId: string | undefined, public formPath: string | undefined) {
  } // Intake Eviction Details
}

export class WatchCase implements Action {
  readonly type = WATCH_CASE;

  constructor(public caseId: string, public user: User, public organization: Organization, public watch?: CaseWatcherSettings) {}
}

export class WatchSettingsUpdated implements Action {
  readonly type = WATCH_SETTINGS_UPDATED;

  constructor(public user: User, public organization: Organization, public watcherSettings?: CaseWatcherSettings) {}
}

export class SendClientAnswers implements Action {
  readonly type = SEND_CLIENT_ANSWER;
  constructor(public answers: ClientAnswers) {}
}

export class DownloadCaseFiles implements Action {
  readonly type = DOWNLOAD_CASE_FILES;

  constructor(public caseIds: string[]) {
  }
}

export class DoScraCheck implements Action {
  readonly type = DO_SCRA_CHECK;

  constructor(public caseId: string, public tenantId: string) {
  }
}

export class RefreshCaseTenants implements Action {
  readonly type = REFRESH_CASE_TENANTS;

  constructor(public caseId: string, public tenants: Tenant[]) {
  }
}

export class UpdateCaseTenant implements Action {
  readonly type = CASE_TENANT_UPDATED;

  constructor(public tenants: Tenant[]) {
  }
}

export class UpdateCaseScraCheckStatus implements Action {
  readonly type = UPDATE_SCRA_CHECK_STATUS;

  constructor(public tenantId: string, public status: ScraScanStatus) {
  }
}

export type EvictionActions =
  | SetStagedEvictions
  | LoadEviction
  | SetCurrentEviction
  | SaveNewEviction
  | EvictionStatusUpdated
  | EvictionStepCompleted
  | SetEvictionBills
  | SaveBill
  | BillSaved
  | UpdateBill
  | BillUpdated
  | DeleteBill
  | BillDeleted
  | EvictionReopen
  | EvictionReopened
  | SaveCaseTags
  | TagsUpdated
  | CancelScheduledEmail
  | ScheduledEmailCanceled
  | GenerateFillableFormDetails
  | WatchCase
  | WatchSettingsUpdated
  | SendClientAnswers
  | UpdateCourtResult
  | CourtResultUpdated
  | CaseFieldUpdated
  | DownloadCaseFiles
  | DoScraCheck
  | UpdateCaseTenant
  | UpdateCaseScraCheckStatus;

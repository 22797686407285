// code adapted from https://coryrylan.com/blog/creating-a-dynamic-checkbox-list-in-angular

import {FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function AvailableFieldValidator(arr: string[] = []): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    if (control?.value) {
      return arr.includes(control.value) ? { availableField: true } : null;
    }
    return null;
  };
}

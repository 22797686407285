import {AbstractControl} from '@angular/forms';
import {debounceTime, map, take} from 'rxjs/operators';
import {ActivationService} from '@ee/common/services';
import {of} from 'rxjs';

export class AttorneyKeyNotTakenValidator {

  static ATTORNEY_KEY_PATTERN = '^[a-zA-Z0-9-]*$';

  static createValidator(activateAttorneyService: ActivationService, errorWhenMissing = false) {
    return (control: AbstractControl) => {
      control.markAllAsTouched();
      if (!control.value) {
        return of(null);
      }
      return activateAttorneyService.validateAttorneyKey(control.value).pipe(
        debounceTime(1000),
        take(1),
        map(res => {
          let result = {attorneyKey: true};
          if ((errorWhenMissing && !res) || (!errorWhenMissing && res)) {
            result = null; // don't show error
          }
          return result;
        }));
    };
  }
}

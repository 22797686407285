import { Route } from '@angular/router';
import { ActivatePropertyManagerComponent } from './activate-property-manager.component';

export const activatePropertyManagerRoute: Route = {
  path: 'activate/pm',
  component: ActivatePropertyManagerComponent,
  data: {
    authorities: [],
    pageTitle: 'Activate Account'
  }
};

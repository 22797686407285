import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountType } from '@ee/common/enums';
import { Crisp } from 'crisp-sdk-web';
import { ReferralService } from '@ee/common/services';
import { User } from '@ee/common/models';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {ShowAutoClosableAlert} from '../actions/alert.actions';
import {Router} from '@angular/router';

@Component({
  selector: 'ee-referral-dialog',
  template: `
    <div mat-dialog-content class="referral-content">
      <div class="flex-1 flex flex-col p-4">
        <h2>Referral Program</h2>
        <div class="overflow-auto flex-1 flex flex-col">
          <div>
            <div *ngIf="data.accountType === AccountType.CLIENT">
              <p>
                Enjoying EasyEviction? Do you wish all your attorneys were using it?
                Refer them to EasyEviction and earn a $100 Amazon gift card for each firm that subscribes.
              </p>
            </div>
            <div *ngIf="data.accountType === AccountType.ATTORNEY">
              <p>
                Refer other attorneys to EasyEviction and earn a $100 Amazon gift card for each firm that subscribes.
              </p>
            </div>
            <p>
              <b>
                Every attorney you refer will receive a 14-day free trial and free onboarding (a $399 offer).</b>
            </p>
          </div>
          <div class="flex flex-1 flex-row justify-stretch items-center mt-2">
            <div class="flex-1">
              <p>
                To get started, simply enter one or more emails below and click send.
              </p>
              <div class="flex flex-row justify-stretch items-start">
                <div class="flex-1 flex flex-col justify-stretch items-stretch">
                  <mat-form-field class="compact mr-2" [class.mat-form-field-invalid]="emailError">
                    <mat-label>Comma-Separated Email List</mat-label>
                    <input matInput [(ngModel)]="emails">
                  </mat-form-field>
                  <div class="error"><span class="ml-4 text-xs">{{ emailError ? 'Invalid email addresses submitted' : '&nbsp;' }}</span></div>
                </div>
                <button mat-flat-button (click)="sendEmail()" color="accent" type="button">
                  SEND
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-1">
        <img src="/assets/images/referral.webp" alt="Referral" class="h-full w-full">
      </div>
    </div>
    <div mat-dialog-actions class="ee-modal-actions-split">
      <div class="ee-modal-actions-split-left">
<!--        <button mat-button (click)="copyReferralLink()" color="accent" type="button">-->
<!--          COPY REFERRAL LINK-->
<!--        </button>-->
      </div>
      <div class="ee-modal-actions">
        <button mat-button (click)="dialogRef.close(undefined)" type="button">
          CLOSE
        </button>
        <button mat-button color="accent" type="button" (click)="navigateToReferralLink()">
          LEARN MORE
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      @import 'responsive.mixin.scss';

      .mat-mdc-dialog-content.referral-content {
        display: flex;
        flex-direction: column;
        padding: 0 !important;

        @media screen and (min-width: $break-md) {
          flex-direction: row;
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReferralDialogComponent implements OnDestroy {
  emails: string;
  emailError = false;
  emailRegex = /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/;
  subs: Subscription[] = [];

  constructor(
    public referralService: ReferralService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private store: Store,
    public dialogRef: MatDialogRef<ReferralDialogComponent>,
  @Inject(MAT_DIALOG_DATA) public data: { accountType: AccountType; user: User }
  ) {}

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  referUs() {
    Crisp.message.send('text', 'I have been referred to EasyEviction and I am interested in signing up.');
  }

  copyReferralLink() {
    navigator.clipboard.writeText(`https://easyeviction.com/referral?referral_code=${this.data.user.referral_id}`);
  }

  sendEmail() {
    if (this.emails && this.emailRegex.test(this.emails)) {
      this.emailError = false;
      this.subs.push(this.referralService.sendEmail(this.emails).subscribe({
        next: () => {
          this.store.dispatch(new ShowAutoClosableAlert('Invite sent successfully.'));
          this.navigateToReferralLink();
        },
        error: () => {
          this.store.dispatch(new ShowAutoClosableAlert('An error occurred sending the invitation.'));
        }
      }));
    } else {
      this.emailError = true;
    }
    this.changeDetectorRef.detectChanges();
  }

  navigateToReferralLink() {
    this.router.navigate(['/user-settings/referrals'], {onSameUrlNavigation: 'reload'});
    this.dialogRef.close();
  }

  protected readonly AccountType = AccountType;
}

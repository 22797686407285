import { Route } from '@angular/router';
import { ActivateNewUserComponent } from './activate-new-user.component';

export const activateNewUserRoute: Route = {
  path: 'activate/user',
  component: ActivateNewUserComponent,
  data: {
    authorities: [],
    pageTitle: 'Activate User Account'
  }
};

export enum NotificationType {
  INVOICE = 'Invoice',
  CASE = 'Case',
  CASE_CREATED = 'Case Created',
  INTAKE_CASE = 'Intake Case Submitted',
  INTAKE_CASE_PROCESSED = 'Intake Case Processed',
  MESSAGE = 'Message Received',
  DIRECT_MESSAGE = 'Direct Message Received',
  ATTORNEY_ADD_REQUEST = 'New Client Request',
  ATTORNEY_ADD_REQUEST_CONFIRMED = 'Attorney Request Confirmed',
  CLIENT_ADDED = 'Client Added',
  BULK_CASE_FILES = 'Bulk Case Files',
  BULK_INVOICES = 'Bulk Invoices'
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { STATES } from '@ee/common/constants';
import { AttorneyKeyNotTakenValidator } from '@ee/common/validators';
import { AccountType } from '@ee/common/enums';
import { AddressSuggestion, Organization, populateForm } from '@ee/common/models';
import { combineLatest, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as slugify from 'slugify';
import { ActivationService } from '@ee/common/services';
import { environment } from '../../../../environments/environment';
import {
  AnalyticsService,
  Events,
  RegistrationProps,
  RegistrationSteps,
  RegistrationType
} from '../../../core/services/analytics.service';

@Component({
  selector: 'ee-activate-attorney',
  templateUrl: './activate-attorney.component.html',
  styleUrls: ['./activate-attorney.component.scss']
})
export class ActivateAttorneyComponent implements OnInit, OnDestroy {
  activationKeyForm: UntypedFormGroup;
  lawFirmForm: UntypedFormGroup;
  attorneyKeyForm: UntypedFormGroup;
  states = STATES;
  appProtocol = environment.protocol;
  appDomain = environment.domain;
  nameOfUser = undefined;
  isLoading: boolean;
  isSaving: boolean;
  disableGetStarted: boolean = false;
  private subs = new Subscription();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activationService: ActivationService,
    private router: Router,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private analyticsService: AnalyticsService
  ) {
    this.activationKeyForm = this.formBuilder.group({
      key: [null, Validators.required]
    });
    this.lawFirmForm = this.formBuilder.group({
      street_address_1: ['', Validators.required],
      street_address_2: [null],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip_code: ['', Validators.required],
      lat: [null],
      lng: [null],
      main_phone_number: ['', Validators.required],
      logo: [null]
    });
    this.attorneyKeyForm = this.formBuilder.group({
      attorney_key: [
        '',
        {
          validators: [Validators.required, Validators.pattern(AttorneyKeyNotTakenValidator.ATTORNEY_KEY_PATTERN)],
          asyncValidators: [AttorneyKeyNotTakenValidator.createValidator(activationService)]
        }
      ]
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.subs.add(this.route.queryParams.subscribe(params => {
      if (params.key) {
        this.activationKeyForm.get('key').setValue(params.key);
      }
    }));

    this.subs.add(
      combineLatest([this.route.queryParams]).subscribe(([params]) => {
        if (params && params.key) {
          const activationKey = params.key;
          if (activationKey && !this.nameOfUser) {
            this.activationKeyForm.get('key').setValue(activationKey);
            this.subs.add(
              this.activationService.getByUserActivationKey(activationKey).subscribe({
                next: details => {
                  if (details.account_type !== AccountType.ATTORNEY) {
                    this.router.navigate(['/authorize']);
                    this.snackbar.open('Invalid request. Please try again.', 'Dismiss', { duration: 3000 });
                  }
                  this.nameOfUser = details.first_name;
                  this.attorneyKeyForm.get('attorney_key').setValue(
                    slugify.default(details.company_name, {
                      lower: true,
                      strict: true
                    })
                  );
                  this.isLoading = false;
                },
                error: error => {
                  this.isLoading = false;
                  this.snackbar.open('Invalid request.', 'Dismiss', { duration: 3000 });
                  console.error(error);
                  this.router.navigate(['/authorize']);
                }
              })
            );
          }
        }
      })
    );
  }

  onAddressSelected(suggestion: AddressSuggestion) {
    populateForm(this.lawFirmForm, suggestion);
  }

  submit() {
    this.isSaving = true;
    const attorney = this.lawFirmForm.value as Organization;
    const activationKey = this.activationKeyForm.value.key;
    attorney.attorney_key = this.attorneyKeyForm.value.attorney_key;
    attorney.logo = this.lawFirmForm.value.logo;

    // record event
    this.analyticsService.recordEvent(Events.REGISTRATION, {
      accountType: attorney.type,
      type: RegistrationType.NEW_ORGANIZATION,
      step: RegistrationSteps.CONFIRMATION
    } as RegistrationProps);

    this.subs.add(
      this.activationService.completeRegistration(attorney, activationKey).subscribe({
        next: () => {
          this.disableGetStarted = true;
          this.snackbar.open('Your account is now active! Redirecting to login page...', 'Dismiss', { duration: 3000 });
          // redirect to login page
          window.location.href = environment.base_url + '/dashboard';
        },
        error: () => {
          this.snackbar.open('Activation failed. Please try again shortly.', 'Dismiss', { duration: 3000 });
        },
        complete: () => {
          setTimeout(() => {
            this.isSaving = false;
          }, 5000);
        }
      })
    );
  }

  setLogo(logo: string) {
    this.lawFirmForm.get('logo').setValue(logo);
  }

  login() {
    this.router.navigate(['/authorize']);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

import { createAction } from '@ngrx/store';
import { PluginType } from '@ee/common/enums';

export const UPDATE_PLUGIN_INTEGRATION_STATUS = '[Plugins] Update Plugin Integration Status';

export const UpdatePluginIntegrationStatus = createAction(
  UPDATE_PLUGIN_INTEGRATION_STATUS,
  (pluginType: PluginType, service: string, connected: boolean) => ({pluginType, service, connected})
);


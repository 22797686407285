import { LOADING, START_SAVING, STOP_SAVING, UIActions } from '../actions/ui.actions';

export interface State {
  isSaving: boolean;
  isLoading: boolean;
}

const initialState: State = {
  isSaving: false,
  isLoading: false
};

export function uiReducer(state = initialState, action: UIActions) {
  switch (action.type) {
    case START_SAVING:
      return { ...state,
        isSaving: true
      };
    case STOP_SAVING:
      return {
        ...state,
        isSaving: false
      };
    case LOADING:
      return {
        ...state,
        isLoading: action.loading
      };
    default: {
      return state;
    }
  }
}

export const getIsSaving = (state: State) => state.isSaving;
export const getIsLoading = (state: State) => state.isLoading;

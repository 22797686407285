import {CaseWatcherSettings} from './case-watcher-settings.model';
import forEach from 'lodash-es/forEach';

export interface NotificationSettings {
  client_override: boolean;
  user_actions: boolean;
  direct_messages: boolean;
  intake_eviction_submitted_by_new_client: boolean;
  eviction_message_received: boolean;
  eviction_attachment_saved: boolean;
  eviction_billable_item_saved: boolean;
  eviction_processed: boolean;
  eviction_saved_by_attorney: boolean;
  eviction_submitted_by_client: boolean;
  eviction_status_changed: boolean;
  payment_applied_to_invoice: boolean;
  daily_emails: boolean;
  schedule_email: boolean;
  watch_all_cases: boolean;
  default_case_watcher_settings: CaseWatcherSettings;
}

export function updateSubscribeFromAll(settings: NotificationSettings, subscribeAll = false) {
  forEach(settings, (value, key) => {
    if (key !== 'default_case_watcher_settings') {
      settings[key] = subscribeAll;
    }
  });
  forEach(settings.default_case_watcher_settings, (value, key) => {
    settings.default_case_watcher_settings[key] = subscribeAll;
  });
}

export enum AttachmentType {
  AFFIDAVIT = 'Affidavit',
  BILL_OF_PARTICULARS = 'Bill of Particulars',
  COURT_DOCUMENT = 'Court Document',
  COVER_SHEET = 'Cover Sheet',
  DISPOSITION = 'Disposition',
  EVICTION = 'Eviction',
  GROUNDS_OF_DEFENSE = 'Grounds of Defense',
  LEASE_AGREEMENT = 'Lease Agreement',
  LEASE_APPLICATION = 'Lease Application',
  LEDGER = 'Ledger',
  NOTICE = 'Notice',
  OTHER = 'Other',
  PRAECIPE = 'Praecipe',
  SUMMONS = 'Summons',
  WRIT = 'Writ'
}

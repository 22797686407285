import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';

@Component({
  selector: 'ee-upload-logo',
  template: `
    <div class="upload-logo-container flex flex-col">
      <div class="flex flex-row justify-start items-center mb-4">
        <button class="mr-2" mat-flat-button color="primary" (click)="fileInput.click()" type="button">
          <mat-icon>image</mat-icon>
          <span class="whitespace-nowrap">Select Logo</span>
        </button>
        <div class="mr-2 flex-1">PNG format only</div>
        <div *ngIf="!currentImageUrl && !autoSet">
          No logo has been set
        </div>
        <!-- Hidden input -->
        <input #fileInput type="file" (change)="onFileChange($event)" accept="image/*" hidden>

        <button class="mr-4" mat-flat-button [disabled]="!croppedImage" (click)="clearLogo()" type="button">
          Clear
        </button>
        <button *ngIf="!autoSet" mat-flat-button color="accent" [disabled]="!croppedImage"
                (click)="uploadLogo()" type="button">
          Save
        </button>
      </div>
      <div class="flex flex-1 flex-row justify-center items-center">
        <image-cropper class="h-full w-full"
                       alignImage="center"
                       [imageChangedEvent]="imageChangedEvent"
                       [maintainAspectRatio]="true"
                       [aspectRatio]="4 / 1"
                       format="png"
                       [containWithinAspectRatio]="true"
                       (imageCropped)="imageCropped($event)"
                       (imageLoaded)="imageLoaded()"
                       (cropperReady)="cropperReady()"
                       (loadImageFailed)="loadImageFailed()"
                       [imageBase64]="imageBase64"></image-cropper>
      </div>
    </div>
  `,
  styles: [
    `.upload-logo-container {
          min-height: 350px;
        }`
  ]
})
export class UploadLogoComponent implements OnChanges {
  @Input()
    currentImageUrl: string;

  @Input()
    organizationId: string;

  @Input()
    autoSet: boolean;

  @Output()
    save: EventEmitter<string> = new EventEmitter<string>();

  imageChangedEvent: Event;
  croppedImage = '';
  imageBase64 = '';

  async ngOnChanges(changes: SimpleChanges) {
    if (!changes.currentImageUrl) {
      this.imageBase64 = null;
    } else {
      await this.loadLogoIntoCropper(this.currentImageUrl);
    }
  }

  uploadLogo() {
    this.save.emit(this.croppedImage.split(',')[1]);
    this.imageBase64 = this.croppedImage;
  }

  clearLogo() {
    this.currentImageUrl = undefined;
    this.imageBase64 = undefined;
    this.save.emit('');
  }

  onFileChange(event: Event): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

    if (this.autoSet) {
      this.save.emit(this.croppedImage.split(',')[1]);
    }
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  private async loadLogoIntoCropper(url) {
    const data = await fetch(url, { cache: 'no-cache' });
    const blob = await data.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onloadend = () => {
      this.imageBase64 = reader.result as string;
    };
  }
}

import {AbstractControl, ValidationErrors} from '@angular/forms';
import {debounceTime, map, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ClientService} from '@ee/common/services';

export class ValidateClientIdentifierNotTaken {
  static createValidator(clientService: ClientService) {
    const result = (control: AbstractControl): Observable<ValidationErrors | null> =>
      clientService.checkClientIdentifierAvailability(control.value).pipe(
        debounceTime(1000),
        take(1),
        map(res => res ? {orgIdTaken: true} : null));
    return result;
  }
}

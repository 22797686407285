import { createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import {
  SetTaskResults,
  TaskDeleted,
  TaskSaved,
  TasksRefreshing
} from '../actions/task.actions';
import { Task } from '@ee/common/models';
import * as fromRoot from './app.reducer';
import remove from 'lodash-es/remove';
import findIndex from 'lodash-es/findIndex';

export interface TaskState {
  tasks: Task[];
  refreshing: boolean;
}

export interface State extends fromRoot.State {
  tasks: TaskState;
  refreshing: boolean;
}

const initialState: TaskState = {
  tasks: [],
  refreshing: false
};

export const taskReducer = createReducer(
  initialState,
  on(SetTaskResults, (state, { tasks }) => ({
    ...state,
    tasks
  })),
  on(TaskSaved, (state, { task }) => {
    const tasks = [...state.tasks];
    const i = findIndex(tasks, { id: task.id });
    if (i > -1) {
      tasks[i] = { ...tasks[i], ...task };
    } else {
      tasks.push(task);
    }
    return { ...state, tasks };
  }),
  on(TaskDeleted, (state, { id }) => {
    const tasks = [...state.tasks];
    remove(tasks, { id });
    return { ...state, tasks };
  }),
  on(TasksRefreshing, (state, { payload }) => ({
    ...state,
    refreshing: payload
  }))
);

export const getTaskState = createFeatureSelector<TaskState>('task');

export const getTasks = createSelector(
  getTaskState,
  (state: TaskState) => state.tasks
);

export const getTasksRefreshing = createSelector(
  getTaskState,
  (state: TaskState) => state.refreshing
);

export const getIncompleteTasks = createSelector(
  getTaskState,
  (state: TaskState) => state.tasks.filter(x => !x.completed)
);

export const getCompletedTasks = createSelector(
  getTaskState,
  (state: TaskState) => state.tasks.filter(x => x.completed)
);

import { DateTime } from 'luxon';

// @ts-ignore
const { zones } = require('tzdata-northamerica');

export function getAvailableTimezones(): string[] {
  const currentTz = DateTime.local().zoneName;

  const result = Object.entries(zones)
    .filter(([, v]) => Array.isArray(v))
    .map(([zoneName]) => zoneName)
    .filter(tz => DateTime.local().setZone(tz).isValid);

  DateTime.local().setZone(currentTz);

  return result;
}

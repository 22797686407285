<mat-sidenav-container class="h-full w-full">
  <!-- left side navigation -->
  <mat-sidenav #leftSidenav class="portal-compact-leftsidenav" [mode]="leftSidenavMode" [(opened)]="leftSidenavOpen">
    <portal-vertical-icon-menu class="portal-sidenav-theme" [sidenav]="leftSidenav" (logoClicked)="logoClicked()">
      <ng-content select="[sidenav]"></ng-content>
    </portal-vertical-icon-menu>
  </mat-sidenav>

  <!-- right side navigation -->
  <mat-sidenav #rightSidenav opened="false" position="end" class="portal-compact-rightsidenav">
    <portal-notification-sidenav [sidenav]="rightSidenav"></portal-notification-sidenav>
  </mat-sidenav>

  <!-- main content -->
  <div class="h-full w-full flex !flex-col">
    <!-- top toolbar -->
    <ee-top-horizontal-menu [leftSidenav]="leftSidenav" [rightSidenav]="rightSidenav"></ee-top-horizontal-menu>

    <!-- page content -->
    <div class="no-scroll-x flex flex-row shrink-0 flex-1">
      <ng-content select="[admin-sidenav]" *ngIf="showAdminNestedNav"></ng-content>
      <ng-content select="[user-sidenav]" *ngIf="showUserNestedNav"></ng-content>
      <div class="portal-compact-content-wrapper no-scroll-x no-scroll-y portal-content-bg flex flex-col w-full h-full">
        <div class="portal-compact-content no-scroll-x no-scroll-y h-full w-full" eeScrollTop>
          <ng-content select="[content]"></ng-content>
          <ee-layout-loader></ee-layout-loader>
        </div>
      </div>
    </div>

    <!-- footer -->
<!--    <portal-footer></portal-footer>-->
  </div>
</mat-sidenav-container>

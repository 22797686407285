import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {CustomField} from '@ee/common/models';

@Injectable({providedIn: 'root'})
export class CustomFieldService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  getCustomCaseFields(domain: 'case' | 'client'): Observable<CustomField[]> {
    return this.http.get<CustomField[]>(this.environment.api_prefix + `api/organizations/custom-fields/${domain}`);
  }

  upsertCustomCaseFields(domain: 'case' | 'client', field: CustomField): Observable<CustomField> {
    return this.http.post<CustomField>(this.environment.api_prefix + `api/organizations/custom-fields/${domain}`, field);
  }

  deleteCustomField(domain: 'case' | 'client', fieldName: string): Observable<boolean> {
    return this.http.delete<boolean>(this.environment.api_prefix + `api/organizations/custom-fields/${domain}/${fieldName}`);
  }

}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '@ee/common/services';

@Component({
  selector: 'ee-portal-auth',
  template: `
  `,
  styles: ['']
})
export class LoginComponent implements OnInit {
  constructor(private auth: AuthService) {}

  ngOnInit() {
    this.auth.login();
  }
}

import {createAction} from '@ngrx/store';
import {ChangePasswordModel, NotificationSettings, User, UserDashboardSettings} from '@ee/common/models';
import {ColDef} from '@ag-grid-community/core';

export const UPDATE_USER = '[User] Update User';
export const DELETE_USER = '[User] Delete User';
export const ACTIVATE_USER = '[User] Activate User';
export const DEACTIVATE_USER = '[User] Deactivate User';
export const UPDATE_USER_EMAIL = '[User] Update Email';
export const UPDATE_BAR_LICENSES = '[User] Update Bar Licenses';
export const UPDATE_USER_DETAILS = '[User] Update User Details';
export const UPDATE_NOTIFICATION_SETTINGS = '[Notification] Update Notification Settings';
export const UPDATE_USER_DASHBOARD_SETTINGS = '[User Dashboard] Update User Dashboard Settings';
export const UPDATE_USER_SETTING = '[User Dashboard] Update User Setting';
export const UPDATE_CASE_GRID_SETTINGS = '[User Case Grid] Update Case Grid Setting';
export const RESET_CASE_GRID_SETTINGS = '[User Case Grid] Reset Case Grid Setting';
export const CHANGE_PASSWORD = '[User] Change Password';
export const RESET_PASSWORD = '[User] Reset Password';
export const GUIDE_VIEWED = '[User] Guide Viewed';


export const ActivateUser = createAction(
  ACTIVATE_USER,
  (payload: string) => ({payload}) // user id
);

export const DeactivateUser = createAction(
  DEACTIVATE_USER,
  (payload: string) => ({payload}) // user id
);

export const UpdateEmail = createAction(
  UPDATE_USER_EMAIL,
  (email: string) => ({email}) // user id
);

export const UpdateBarLicenses = createAction(
  UPDATE_BAR_LICENSES,
  (licenses: {string: string}) => ({licenses})
);

export const UpdateUserDetails = createAction(
  UPDATE_USER_DETAILS,
  (user: User) => ({user}) // user id
);

export const ChangePassword = createAction(
  CHANGE_PASSWORD,
  (changePasswordModel: ChangePasswordModel) => ({changePasswordModel})
);

export const UpdateNotificationSettings = createAction(
  UPDATE_NOTIFICATION_SETTINGS,
  (settings: NotificationSettings, updateAllCases: boolean) => ({settings, updateAllCases})
);

export const UpdateUserDashboardSettings = createAction(
  UPDATE_USER_DASHBOARD_SETTINGS,
  (dashboardSettings: UserDashboardSettings) => ({dashboardSettings})
);

export const UpdateUserSetting = createAction(
  UPDATE_USER_SETTING,
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  (key: string, value: any | null) => ({key, value})
);

export const UpdateCaseGridSetting = createAction(
  UPDATE_CASE_GRID_SETTINGS,
  (value: ColDef[]) => ({value})
);

export const ResetCaseGridSetting = createAction(
  RESET_CASE_GRID_SETTINGS
);

export const ResetPassword = createAction(
  RESET_PASSWORD,
  (email: string) => ({email}) // login id
);

export const GuideViewed = createAction(
  GUIDE_VIEWED,
  (id: number) => ({id})
);


import {BillableItem} from './billable-item.model';

export class InvoicePresentation {
  case_id?: string;
  street_address_1?: string;
  tenants?: string;
  billable_items: BillableItem[];

  get header(): string | null {
    if (!this.case_id && !this.street_address_1 && !this.tenants) {
      return 'Other';
    }
    return this.case_id + ' / ' + this.street_address_1 + ' / ' + this.tenants;
  }
}

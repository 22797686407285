import {AbstractControl, ValidationErrors} from '@angular/forms';
import {debounceTime, map, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ActivationService} from '@ee/common/services';

export class ValidateInvitationCode {
  static createValidator(activationService: ActivationService) {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      activationService.validateInvitationCode(control.value).pipe(
        debounceTime(500),
        take(1),
        map(res => res ? null : {codeInvalid: true}));
  }
}

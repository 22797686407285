// logic found here: https://medium.com/better-programming/sync-your-state-in-local-storage-with-ngrx-9d6ceba93fc0

import { Action, ActionReducer } from '@ngrx/store';
import merge from 'lodash-es/merge';
import pick from 'lodash-es/pick';
import { SessionStorageService } from 'ngx-webstorage';
import { NOT_AUTHENTICATED } from '../actions/auth.actions';

export function storageMetaReducer<S, A extends Action = Action>(
  saveKeys: string[],
  localStorageKey: string,
  storageService: SessionStorageService
) {
  let onInit = true; // after load/refresh…
  return (reducer: ActionReducer<S, A>) => (state: S, action: A): S => {
    // get the next state.
    const nextState = reducer(state, action);
    // init the application state.
    if (action.type === NOT_AUTHENTICATED) {
      storageService.store(localStorageKey, {});
      return nextState;
    } else if (onInit) {
      onInit = false;
      const savedState = storageService.retrieve(localStorageKey);
      return merge(nextState, savedState);
    }

    // save the next state to the application storage.
    const stateToSave = pick(nextState, saveKeys);
    storageService.store(localStorageKey, stateToSave);

    return nextState;
  };
}

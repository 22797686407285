import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from './app.reducer';
import { Workflow } from '@ee/common/models';
import {
  ADD_WORKFLOW_COMPLETE,
  SET_CURRENT_WORKFLOW,
  SET_WORKFLOWS,
  UPDATE_WORKFLOW_COMPLETE,
  WORKFLOW_DELETE,
  WorkflowActions
} from '../actions/workflow.actions';
import findIndex from 'lodash-es/findIndex';
import cloneDeep from 'lodash-es/cloneDeep';

export interface WorkflowState {
  workflows: Workflow[];
  currentWorkflow: Workflow;
}

export interface State extends fromRoot.State {
  workflow: WorkflowState;
}

const initialState: WorkflowState = {
  workflows: [],
  currentWorkflow: null
};

export function workflowReducer(state = initialState, action: WorkflowActions) {
  let workflows;
  let i;
  switch (action.type) {
    case SET_WORKFLOWS:
      return {...state, workflows: action.payload};
    case SET_CURRENT_WORKFLOW:
      return {...state, currentWorkflow: action.payload};
    case ADD_WORKFLOW_COMPLETE:
      workflows = cloneDeep(state.workflows);
      workflows.push(action.payload);
      return {...state, workflows};
    case UPDATE_WORKFLOW_COMPLETE:
      workflows = cloneDeep(state.workflows);
      i = findIndex(workflows, {id: action.payload.id});
      workflows[i] = action.payload;
      return {...state, workflows};
    case WORKFLOW_DELETE:
      workflows = cloneDeep(state.workflows);
      i = findIndex(workflows, {id: action.payload});
      workflows.splice(i, 1);
      return {...state, workflows};
    default: {
      return state;
    }
  }
}

export const getWorkflowState = createFeatureSelector<WorkflowState>('workflow');

export const getWorkflows = createSelector(
  getWorkflowState,
  (state: WorkflowState) => state.workflows
);

export const getCurrentWorkflow = createSelector(
  getWorkflowState,
  (state: WorkflowState) => state.currentWorkflow
);

import {filter} from 'rxjs/operators';
import {NavigationStart, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {LocalStorageService} from 'ngx-webstorage';
import {ATTORNEY_LOCK} from '@ee/common/constants';
import {Organization} from '@ee/common/models';

@Component({
  selector: 'portal-vertical-icon-menu',
  templateUrl: './vertical-icon-menu.component.html',
  styleUrls: ['./vertical-icon-menu.component.scss']
})
export class VerticalIconMenuComponent implements OnDestroy {
  /**
   * Import material sidenav so we can access open close functions.
   */
  @Input() sidenav: MatSidenav;
  routerSubscription: Subscription;

  @Output() logoClicked = new EventEmitter();

  year = new Date().getFullYear();
  customLogoUrl: string;

  constructor(private router: Router, private localStorage: LocalStorageService) {
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(() => {
        if (this.sidenav && this.sidenav.mode === 'over' && this.sidenav.opened) {
          this.sidenav.close();
        }
      });

    const attorney = this.localStorage.retrieve(ATTORNEY_LOCK) as Organization;
    if (attorney?.logo) {
      this.customLogoUrl = attorney.logo;
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { generateTagColorToStyle } from './tag.constants';
import { Tag } from '@ee/common/models';

@Component({
  selector: 'ee-tag',
  template: `
    <div class="tagify" [style]="transformColorToStyle(tag.color)">
      <span title="{{tag.name}}" class="tagify__tag tagify--noAnim">
        <div class="tagify__text-wrapper">
          <span class="tagify__tag-text"
                [ngClass]="{'whitespace-nowrap': noWrap}">
            {{tag.name}}
          </span>
        </div>
      </span>
    </div>
  `,
  styles: [
    `
      .tagify {
        border: none;
        background-color: transparent;

        .tagify__tag:hover .tagify__text-wrapper::before {
            inset: 0;
        }
      }


      .whitespace-nowrap {
        white-space: nowrap !important;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagComponent {
  @Input({ required: true })
    tag: Tag;
  @Input({ required: false })
    noWrap = false;

  public transformColorToStyle = generateTagColorToStyle;
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivationService } from '@ee/common/services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getAvailableTimezones } from '../../../shared/util/timezones.util';
import { ActivationDetails, User } from '@ee/common/models';
import { PASSWORD_STRENGTH_REGEX } from '@ee/common/constants';
import { DateTime } from 'luxon';

@Component({
  selector: 'ee-activate-new-user',
  templateUrl: './activate-new-user.component.html',
  styleUrls: ['./activate-new-user.component.scss']
})
export class ActivateNewUserComponent implements OnInit, OnDestroy {
  tzNames: string[] = getAvailableTimezones();
  form: UntypedFormGroup;
  isLoading: boolean;
  activationDetails: ActivationDetails;
  private subs = new Subscription();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activationService: ActivationService,
    private router: Router,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
  ) {
    this.form = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern(PASSWORD_STRENGTH_REGEX)]],
      time_zone: [DateTime.local().zoneName, Validators.required],
      tos_confirm: true,
      policy_confirm: true,
      key: [null, Validators.required]
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.subs.add(this.route.queryParams.subscribe(params => {
      if (params.key) {
        this.form.get('key').setValue(params.key);
      }
    }));
    this.subs.add(
      this.route.queryParams.subscribe(params => {
        if (params && params.key) {
          const activationKey = params.key;
          this.form.get('key').setValue(activationKey);
          this.subs.add(
            this.activationService.getByUserActivationKey(activationKey).subscribe({
              next: details => {
                this.activationDetails = details;
                this.form.patchValue(details);
                this.isLoading = false;
              },
              error: error => {
                this.isLoading = false;
                this.snackbar.open('Invalid request.', 'Dismiss', { duration: 3000 });
                console.error(error);
                this.router.navigate(['/authorize']);
              }
            })
          );
        }
      })
    );
  }

  submit() {
    const user = this.form.value as User;
    this.subs.add(
      this.activationService.completeNewUserRegistration(user, this.form.value.key).subscribe({
        next: () => {
          this.snackbar.open('Your account is now active! Please log in.', 'Dismiss', { duration: 3000 });
          this.router.navigate(['/authorize']);
        },
        error: () => {
          this.snackbar.open('Activation failed. Please try again shortly.', 'Dismiss', { duration: 3000 });
        }
      })
    );
  }

  login() {
    this.router.navigate(['/authorize']);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

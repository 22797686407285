import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Task } from '@ee/common/models';
import { ShowAutoClosableAlert } from '../actions/alert.actions';
import { LOAD_ALL_TASKS, SetTaskResults, TasksRefreshing, SaveTask, CompleteTask, TaskSaved, DeleteTask, TaskDeleted } from '../actions/task.actions';
import { RefreshTaskCount } from '../actions/auth.actions';

@Injectable()
export class TaskEffects {
  loadTasks: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_ALL_TASKS),
      switchMap(() =>
        this.http
          .get<Task[]>(`${environment.api_prefix}api/tasks`)
          .pipe(switchMap((results: Task[]) => [SetTaskResults(results), TasksRefreshing(false)]))
      )
    )
  );

  saveTask: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(SaveTask),
      switchMap(({ task }) => {
        let obs: Observable<Task>;
        let alertText = 'New task saved.';
        if (task.id) {
          obs = this.http.put<Task>(`${environment.api_prefix}api/tasks/${task.id}`, task);
          alertText = 'Task updated.';
        } else {
          obs = this.http.post<Task>(`${environment.api_prefix}api/tasks`, task);
        }
        return obs.pipe(switchMap((savedTask: Task) => [new ShowAutoClosableAlert(alertText), RefreshTaskCount(), TaskSaved(savedTask)]));
      })
    )
  );

  completeTask: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompleteTask),
      switchMap(({ task }) =>
        this.http
          .put<Task>(`${environment.api_prefix}api/tasks/complete/${task.id}`, null)
          .pipe(switchMap((savedTask: Task) => [RefreshTaskCount(), TaskSaved(savedTask)]))
      )
    )
  );

  deleteTask: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteTask),
      switchMap(({ id }) =>
        this.http.delete<void>(`${environment.api_prefix}api/tasks/${id}`).pipe(switchMap(() => [RefreshTaskCount(), TaskDeleted(id)]))
      )
    )
  );

  constructor(private actions$: Actions, private http: HttpClient) {}
}

import {CustomFieldType} from '@ee/common/enums';

export class ReportField {
  client_property_name: string;
  display_name: string;
  sort_order: number;
  type: CustomFieldType;
  custom: boolean;
}

export class ReportSettingField {
  client_property_name: string;
  display_name: string;
  value?: any;
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'enumFilterByProp' })
export class EnumFilterByPropPipe implements PipeTransform {
  transform(items: any[], prop: string, filters: number[]): any[] {
    if (!items || !prop) {
      return items;
    }

    const results = items.filter(item => filters.indexOf(item[prop]) > -1);

    return results.length ? results : [];
  }
}

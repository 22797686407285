import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as searchActions from '../actions/search.actions';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SearchResults } from '@ee/common/models';

@Injectable()
export class AppSearchEffects {
  appSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.APP_SEARCH),
      map((action: searchActions.AppSearch) => action.payload),
      switchMap((searchKeyword: string) => {
        const emptySuggestion = {
          evictions: [],
          organizations: [],
          users: [],
          invoices: []
        } as SearchResults;
        if (searchKeyword && searchKeyword.length > 1) {
          return this.http
            .get(`${environment.api_prefix}api/search/suggestions?keyword=${encodeURIComponent(searchKeyword)}`)
            .pipe(
              map((searchSuggestion: SearchResults) => new searchActions.AppSearchSuggestion(searchSuggestion)),
              catchError(() => [new searchActions.AppSearchSuggestion(emptySuggestion)])
            );
        } else {
          return [new searchActions.AppSearchSuggestion(emptySuggestion)];
        }
      })
    )
  );

  constructor(private actions$: Actions, private http: HttpClient) {}
}

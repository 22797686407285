import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as fromRoot from './app.reducer';
import findIndex from 'lodash-es/findIndex';
import { User } from '@ee/common/models';
import { ActivateUser, DeactivateUser } from '../actions/user.actions';

export interface UserState {
  users: User[];
  loading: boolean;
}

export interface State extends fromRoot.State {
  user: UserState;
}

const initialState: UserState = {
  users: [],
  loading: false
};

export const userReducer = createReducer(
  initialState,
  on(DeactivateUser, (state, action) => {
    const users = [...state.users];
    const i = findIndex(users, {id: action.payload});
    const user = {...users[i], active: false};
    users[i] = user;
    return {...state, users};
  }),
  on(ActivateUser, (state, action) => {
    const users = [...state.users];
    const i = findIndex(users, {id: action.payload});
    const user = {...users[i], active: false};
    users[i] = user;
    return {...state, users};
  })
);

export const getUserState = createFeatureSelector<UserState>('user');

export const getUsers = createSelector(getUserState,
  (state: UserState) => state.users
);

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GenerateTaskForm, Task} from '@ee/common/models';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import {CompleteTask, DeleteTask, SaveTask} from '../core/actions/task.actions';
import {Permission} from '@ee/common/enums';

@Component({
  selector: 'ee-task-dialog',
  template: `
    <h2 mat-dialog-title>{{task?.id ? 'Edit' : 'Create New'}} Task</h2>

    <form mat-dialog-content class="no-scroll-y flex flex-col justify-stretch items-stretch" [formGroup]="form">
      <mat-form-field class="compact" subscriptSizing="fixed">
        <mat-label>{{title}}</mat-label>
        <input matInput type="text" aria-label="Title" formControlName="text" required
               cdkFocusInitial>
        <mat-error *ngIf="form.get('text').hasError('required')">
          Required.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="compact">
        <mat-label>Notes</mat-label>
        <textarea matInput rows="6" type="text" aria-label="Notes" formControlName="notes"></textarea>
        <mat-hint>Optional</mat-hint>
      </mat-form-field>
      <div class="flex flex-row justify-end items-center">
        <mat-form-field class="compact">
          <mat-label>Due Date</mat-label>
          <input matInput formControlName="due_date" [matDatepicker]="picker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-hint>Optional</mat-hint>
        </mat-form-field>
      </div>
    </form>

    <div mat-dialog-actions class="ee-modal-actions-split">
      <div class="ee-modal-actions-split-left">
        <button type="button" mat-icon-button [matMenuTriggerFor]="menu"
                *hasPermission="[permissions.TASKS_GENERAL_DELETE, permissions.TASKS_GENERAL_EDIT]; op: 'OR';addlCondition: !!task">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button *hasPermission="permissions.TASKS_GENERAL_DELETE" mat-menu-item type="button" (click)="delete()">
            DELETE
          </button>
          <button *hasPermission="permissions.TASKS_GENERAL_EDIT; addlCondition: !!task" mat-menu-item type="button" (click)="save()">
            SAVE
          </button>
        </mat-menu>
      </div>
      <div class="ee-modal-actions">
        <button mat-button type="button" [mat-dialog-close]="">CANCEL</button>
        <button *hasPermission="permissions.TASKS_COMPLETE_CREATE; addlCondition: !!task" cdkFocusInitial mat-button type="button" (click)="completeTask()">
          COMPLETE TASK
        </button>
        <button *hasPermission="permissions.TASKS_GENERAL_CREATE; addlCondition: !task" mat-button type="button" (click)="save()" color="accent">
          SAVE
        </button>
      </div>
    </div>
  `
})
export class TaskDialogComponent implements OnInit {

  form: UntypedFormGroup;
  title = 'New Task';
  permissions = Permission;

  constructor(private fb: UntypedFormBuilder, public dialog: MatDialogRef<TaskDialogComponent>,
              private store: Store, @Inject(MAT_DIALOG_DATA) public task?: Task) {
    if (task?.id) {
      this.title = 'Task Title';
    }
  }

  ngOnInit(): void {
    this.form = GenerateTaskForm(this.fb);
    if (this.task) {
      this.form.patchValue(this.task);
    }
  }

  completeTask(): void {
    if (this.task?.id) {
      this.store.dispatch(CompleteTask(this.task));
      this.dialog.close();
    }
  }

  save(): void {
    let task = this.form.value as Task;
    if (this.task) {
      task = {...this.task, ...task}; // overwrite existing task's details with what was changed
    }
    this.store.dispatch(SaveTask(task));
    this.dialog.close();
  }

  delete(): void {
    if (this.task?.id) {
      this.store.dispatch(DeleteTask(this.task.id));
      this.dialog.close();
    }
  }

}

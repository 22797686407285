import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { delay, filter } from 'rxjs/operators';

@Directive({
  selector: '[eeScrollTop]'
})
export class ScrollTopDirective implements OnDestroy {
  private subs: Subscription[] = [];

  // check again once this is merged: https://github.com/angular/angular/pull/20030
  constructor(private router: Router, private el: ElementRef) {
    this.subs.push(
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationStart),
          delay(0)
        )
        .subscribe(() => {
          this.el.nativeElement.scrollTop = 0;
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PortalComponent } from './portal.component';
import {AccountType, Permission, RolesEnum, SubscriptionPlan} from '@ee/common/enums';
import { canActivateUserRoute } from '../core/guards/user-route-access.service';
import { canActivateSubscriptionRoute } from '../core/guards/subscription-route-access.service';
import { ShowEditDashboardDialog } from '../core/actions/dialog.actions';

const routes: Routes = [
  {
    path: '',
    component: PortalComponent,
    data: {
      authorities: [RolesEnum.ATTORNEY, RolesEnum.CLIENT, RolesEnum.PROPERTY_MANAGER]
    },
    canActivate: [canActivateUserRoute, canActivateSubscriptionRoute],
    children: [
      {
        path: 'dashboard',
        pathMatch: 'full',
        loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
          authorities: [RolesEnum.ATTORNEY, RolesEnum.CLIENT, RolesEnum.PROPERTY_MANAGER],
          pageTitle: 'Dashboard',
          pageTitleButton: { display: 'Edit Settings', action: ShowEditDashboardDialog() }
        },
        canActivate: [canActivateUserRoute, canActivateSubscriptionRoute]
      },
      {
        path: 'invoices',
        loadChildren: () => import('../invoice/invoice.module').then(m => m.InvoiceModule),
        data: {
          authorities: [RolesEnum.ATTORNEY, RolesEnum.CLIENT],
          pageTitle: 'Invoices'
        },
        canActivate: [canActivateUserRoute, canActivateSubscriptionRoute]
      },
      {
        path: 'clients',
        loadChildren: () => import('../clients/clients.module').then(m => m.ClientsModule),
        data: {
          authorities: [RolesEnum.ATTORNEY],
          accountTypes: [AccountType.ATTORNEY],
          pageTitle: 'Clients'
        },
        canActivate: [canActivateUserRoute]
      },
      {
        path: 'properties',
        loadChildren: () => import('../properties/properties.module').then(m => m.PropertiesModule),
        data: {
          authorities: [RolesEnum.PROPERTY_MANAGER],
          accountTypes: [AccountType.PROPERTY_MANAGER],
          pageTitle: 'Properties'
        },
        canActivate: [canActivateUserRoute]
      },
      {
        path: 'tasks',
        loadChildren: () => import('../tasks/tasks.module').then(m => m.TasksModule),
        data: {
          authorities: [RolesEnum.ATTORNEY],
          accountTypes: [AccountType.ATTORNEY],
          pageTitle: 'Tasks'
        },
        canActivate: [canActivateUserRoute]
      },
      {
        path: 'messages',
        loadChildren: () => import('../messages/messages.module').then(m => m.MessagesModule),
        data: {
          authorities: [RolesEnum.ATTORNEY, RolesEnum.CLIENT],
          pageTitle: 'Messages'
        },
        canActivate: [canActivateUserRoute, canActivateSubscriptionRoute]
      },
      {
        path: 'launchpad',
        loadChildren: () => import('../launchpad/launchpad.module').then(m => m.LaunchpadModule),
        data: {
          authorities: [RolesEnum.PROPERTY_MANAGER, RolesEnum.CLIENT],
          pageTitle: 'Launchpad'
        },
        canActivate: [canActivateUserRoute, canActivateSubscriptionRoute]
      },
      {
        path: 'inbox',
        loadChildren: () => import('../inbox/inbox.module').then(m => m.InboxModule),
        data: {
          authorities: [RolesEnum.ATTORNEY],
          pageTitle: 'Inbox'
        },
        canActivate: [canActivateUserRoute, canActivateSubscriptionRoute]
      },
      {
        path: 'evictions',
        loadChildren: () => import('../eviction/eviction.module').then(m => m.EvictionModule),
        data: {
          authorities: [RolesEnum.ATTORNEY, RolesEnum.CLIENT, RolesEnum.PROPERTY_MANAGER],
          pageTitle: 'Evictions'
        },
        canActivate: [canActivateUserRoute, canActivateSubscriptionRoute]
      },
      {
        path: 'admin',
        loadChildren: () => import('../admin/admin.module').then(m => m.AdminModule),
        data: {
          isAdmin: true,
          pageTitle: 'Admin'
        },
        canActivate: [canActivateUserRoute]
      },
      {
        path: 'user-settings',
        loadChildren: () => import('../user-settings/user-settings.module').then(m => m.UserSettingsModule),
        data: {
          authorities: [RolesEnum.ATTORNEY, RolesEnum.CLIENT, RolesEnum.PROPERTY_MANAGER],
          pageTitle: 'User Settings'
        },
        canActivate: [canActivateUserRoute, canActivateSubscriptionRoute]
      },
      {
        path: 'daily-docket',
        loadChildren: () => import('../daily-docket/daily-docket.module').then(m => m.DailyDocketModule),
        data: {
          authorities: [RolesEnum.ATTORNEY],
          disabledPlans: [SubscriptionPlan.STARTER],
          accountTypes: [AccountType.ATTORNEY],
          permissions: [Permission.DAILY_DOCKET_GENERAL_VIEW],
          pageTitle: 'Daily Docket'
        },
        canActivate: [canActivateUserRoute, canActivateSubscriptionRoute]
      },
      {
        path: 'reports',
        loadChildren: () => import('../reports/reports.module').then(m => m.ReportsModule),
        data: {
          authorities: [],
          disabledPlans: [SubscriptionPlan.STARTER, SubscriptionPlan.CLIENT_FREE, SubscriptionPlan.CLIENT_PRO],
          accountTypes: [AccountType.ATTORNEY, AccountType.PROPERTY_MANAGER],
          permissions: [Permission.REPORTS_EVICTION_VIEW, Permission.REPORTS_TASK_VIEW, Permission.REPORTS_INVOICE_VIEW,
            Permission.REPORTS_BILLABLE_ITEM_VIEW, Permission.REPORTS_STATEMENT_VIEW],
          permissionOperator: 'OR',
          pageTitle: 'Reports'
        },
        canActivate: [canActivateUserRoute, canActivateSubscriptionRoute]
      },
      {
        path: 'notifications',
        loadChildren: () => import('../notifications/notifications.module').then(m => m.NotificationsModule),
        data: {
          authorities: [],
          disabledPlans: [SubscriptionPlan.STARTER, SubscriptionPlan.CLIENT_FREE],
          pageTitle: 'Notification Center'
        },
        canActivate: [canActivateUserRoute, canActivateSubscriptionRoute]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortalRoutingModule {
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as workflowActions from '../actions/workflow.actions';
import { AddWorkflow, CloneWorkflow, DeleteWorkflow } from '../actions/workflow.actions';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Workflow } from '@ee/common/models';
import { ShowAutoClosableAlert } from '../actions/alert.actions';
import { Router } from '@angular/router';

@Injectable()
export class WorkflowEffects {
  loadWorkflows$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(workflowActions.LOAD_WORKFLOWS),
      switchMap(() =>
        this.http
          .get<Workflow[]>(`${environment.api_prefix}api/workflows`)
          .pipe(switchMap((workflows: Workflow[]) => [new workflowActions.SetWorkflows(workflows)]))
      )
    )
  );

  loadWorkflowById$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(workflowActions.LOAD_WORKFLOW),
      map((action: workflowActions.LoadWorkflowById) => action.payload),
      switchMap((id: string) =>
        this.http
          .get<Workflow>(`${environment.api_prefix}api/workflows/${id}`)
          .pipe(switchMap((workflow: Workflow) => [new workflowActions.SetCurrentWorkflow(workflow)]))
      )
    )
  );

  createWorkflow$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(workflowActions.ADD_WORKFLOW),
      map((action: workflowActions.AddWorkflow) => action),
      switchMap((action: AddWorkflow) =>
        this.http.post<Workflow>(`${environment.api_prefix}api/workflows`, action.workflow).pipe(
          switchMap((addedWorkflow: Workflow) => {
            // redirect
            this.router.navigate([`../${addedWorkflow.id}`], { relativeTo: action.currentRoute });

            return [
              new workflowActions.AddWorkflowComplete(addedWorkflow),
              new ShowAutoClosableAlert('Workflow saved.')
            ];
          })
        )
      )
    )
  );

  updateWorkflow$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(workflowActions.UPDATE_WORKFLOW),
      map((action: workflowActions.UpdateWorkflow) => action.payload),
      switchMap((workflow: Workflow) =>
        this.http
          .put<Workflow>(`${environment.api_prefix}api/workflows/${workflow.id}`, workflow)
          .pipe(
            switchMap((updatedWorkflow: Workflow) => [
              new workflowActions.UpdateWorkflowComplete(updatedWorkflow),
              new ShowAutoClosableAlert('Workflow changes saved.')
            ])
          )
      )
    )
  );

  cloneWorkflow$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(workflowActions.CLONE_WORKFLOW),
      map((action: workflowActions.CloneWorkflow) => action),
      switchMap((action: CloneWorkflow) =>
        this.http.post<Workflow>(`${environment.api_prefix}api/workflows/${action.workflowId}/clone`, null).pipe(
          switchMap((clonedWorkflow: Workflow) => {
            // redirect
            this.router.navigate([`../${clonedWorkflow.id}`], { relativeTo: action.currentRoute });

            return [
              new workflowActions.AddWorkflowComplete(clonedWorkflow),
              new workflowActions.SetCurrentWorkflow(clonedWorkflow),
              new ShowAutoClosableAlert('Workflow cloned.')
            ];
          })
        )
      )
    )
  );

  deleteWorkflow$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(workflowActions.DELETE_WORKFLOW),
      map((action: workflowActions.DeleteWorkflow) => action),
      switchMap((action: DeleteWorkflow) =>
        this.http.delete(`${environment.api_prefix}api/workflows/${action.workflowId}`).pipe(
          switchMap(() => {
            // redirect
            this.router.navigate(['..'], { relativeTo: action.currentRoute });

            return [
              new workflowActions.WorkflowDeleted(action.workflowId),
              new workflowActions.SetCurrentWorkflow(null),
              new ShowAutoClosableAlert('Workflow Deleted.')
            ];
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private appStore: Store, private http: HttpClient, private router: Router) {}
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as formActions from '../actions/form.actions';
import {GenerateForm, GenerateForms, SaveNewForm} from '../actions/form.actions';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Attachment, DocumentTemplate } from '@ee/common/models';
import { ShowAutoClosableAlert } from '../actions/alert.actions';
import * as attachmentActions from '../actions/attachment.actions';
import { Router } from '@angular/router';
import { AttachmentType } from '@ee/common/enums';
import {FormService} from '@ee/common/services';

@Injectable()
export class FormEffects {
  loadAllForms$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(formActions.LOAD_ALL_FORMS),
      switchMap(() =>
        this.formService.getAllForms(false, true)
          .pipe(switchMap((forms: DocumentTemplate[]) => [new formActions.SetFormResults(forms)]))
      )
    )
  );

  loadFormById$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(formActions.LOAD_FORM),
      map((action: formActions.LoadFormById) => action.payload),
      switchMap((id: string) =>
        this.http
          .get<DocumentTemplate>(`${environment.api_prefix}api/forms/${id}`)
          .pipe(switchMap((form: DocumentTemplate) => [new formActions.SetCurrentForm(form)]))
      )
    )
  );

  loadFormsByType$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(formActions.LOAD_FORMS_BY_TYPE),
      map((action: formActions.LoadFormsByType) => action.payload),
      switchMap((type: AttachmentType) =>
        this.http
          .get<DocumentTemplate[]>(`${environment.api_prefix}api/forms/type/${type}`)
          .pipe(switchMap((forms: DocumentTemplate[]) => [new formActions.SetFormResults(forms)]))
      )
    )
  );

  saveNewForm$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(formActions.SAVE_NEW_FORM),
      map((action: formActions.SaveNewForm) => action),
      switchMap((action: SaveNewForm) =>
        this.http.post<DocumentTemplate>(`${environment.api_prefix}api/forms`, action.formData).pipe(
          switchMap((savedForm: DocumentTemplate) => {
            // redirect
            this.router.navigate([`/admin/document-templates/${savedForm.id}`]);

            // return new actions
            return [new formActions.FormSaved(savedForm), new ShowAutoClosableAlert(`'${savedForm.name}' Form Saved.`)];
          })
        )
      )
    )
  );

  updateForm$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(formActions.SAVE_EXISTING_FORM),
      map((action: formActions.SaveExistingForm) => action.payload),
      switchMap((form: FormData) =>
        this.http
          .put<DocumentTemplate>(`${environment.api_prefix}api/forms`, form)
          .pipe(
            switchMap((savedForm: DocumentTemplate) => [
              new formActions.FormSaved(savedForm),
              new ShowAutoClosableAlert(`'${savedForm.name}' Form Updated.`)
            ])
          )
      )
    )
  );

  deleteForm$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(formActions.DELETE_FORM),
      map((action: formActions.DeleteForm) => action.payload),
      switchMap((id: string) =>
        this.http
          .delete(`${environment.api_prefix}api/forms/${id}`)
          .pipe(switchMap(() => [new formActions.FormDeleted(id), new ShowAutoClosableAlert('Form Deleted.')]))
      )
    )
  );

  generateForm$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(formActions.GENERATE_FORM),
      map((action: formActions.GenerateForm) => action),
      switchMap((action: GenerateForm) =>
        this.http
          .post<Attachment[]>(
            `${environment.api_prefix}api/evictions/${action.caseId}/generate-form` +
              `/${action.formId}/hide-from-client/${action.hideFromClient ? 'true' : 'false'}`,
            action.fieldValues
          )
          .pipe(
            switchMap((attachments: Attachment[]) => {
              const resultActions = [];
              attachments.forEach(a => {
                resultActions.push(new attachmentActions.AttachmentAdded(a));
                resultActions.push(new attachmentActions.PollForAttachment(a.id));
              });
              return resultActions;
            })
          )
      )
    )
  );

  generateForms$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(formActions.GENERATE_FORMS),
      map((action: formActions.GenerateForms) => action),
      switchMap((action: GenerateForms) =>
        this.http
          .post<Attachment[]>(
            `${environment.api_prefix}api/evictions/${action.caseId}/generate-forms`,
            {
              document_templates: action.visibleDocuments,
              hidden_document_templates: action.hiddenDocuments
            }
          )
          .pipe(
            switchMap((attachments: Attachment[]) => {
              const resultActions = [];
              attachments.forEach(a => {
                resultActions.push(new attachmentActions.AttachmentAdded(a));
                resultActions.push(new attachmentActions.PollForAttachment(a.id));
              });
              return resultActions;
            })
          )
      )
    )
  );

  constructor(private actions$: Actions, private http: HttpClient, private router: Router,
              private formService: FormService) {}
}

import {Subscription} from 'rxjs';
import {Component, OnDestroy, OnInit} from '@angular/core';

import {MatDrawerMode} from '@angular/material/sidenav';
import {Event, NavigationEnd, Router} from '@angular/router';
import {MediaObserverService} from 'media-observer';

@Component({
  selector: 'portal-layout-compact',
  templateUrl: './layout-compact.component.html',
  styleUrls: ['./layout-compact.component.scss']
})
export class LayoutCompactComponent implements OnInit, OnDestroy {
  /**
   * Stores if left sidenav is open.
   */
  leftSidenavOpen: boolean;

  /**
   * Stores left sidenav display mode.
   */
  leftSidenavMode: MatDrawerMode;

  /**
   * Stores media observable subscription.
   */
  subs: Subscription[] = [];

  showAdminNestedNav = window.location.pathname.startsWith('/admin');
  showUserNestedNav = window.location.pathname.startsWith('/user');
  mediaObserverService: MediaObserverService = MediaObserverService.INSTANCE;

  constructor(
    private router: Router
  ) {
  }

  ngOnInit(): void {
    // Get initial state of the sidenav.
    this.calculateSidenavStatus();

    // Subscribe to changes in screen size to change sidenav behavior.
    this.subs.push(this.mediaObserverService.asObservable().subscribe(() => this.calculateSidenavStatus()));

    // subscribe to current route
    this.subs.push(
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationEnd) {
          this.showAdminNestedNav = event.url?.startsWith('/admin');
          this.showUserNestedNav = event.url?.startsWith('/user');
        } else {
          this.showAdminNestedNav = false;
          this.showUserNestedNav = false;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  logoClicked(): void {
    this.showAdminNestedNav = false;
    this.router.navigate(['/dashboard']);
  }

  calculateSidenavStatus(): void {
    const isMobile = this.mediaObserverService.isActive('lt-md');
    // Close sidenav on mobile.
    this.leftSidenavOpen = !isMobile;
    // Make sidenav open over content on mobile.
    this.leftSidenavMode = isMobile ? 'over' : 'side';
  }
}

import {UntypedFormBuilder, Validators} from '@angular/forms';
import {AccountType} from '@ee/common/enums';
import {Invoice, Organization} from '@ee/common/models';

export function GenerateInvoiceForm(fb: UntypedFormBuilder, value: Invoice = null) {
  const form = fb.group({
    id: null,
    attorney: [null, Validators.required],
    due_date: [null, Validators.required],
    issue_date: [null, Validators.required],
    notes: null,
    discount: [undefined, [Validators.max(100), Validators.min(0)]],
    sub_total: [0, Validators.required],
    tax: 0,
    total: [0, Validators.required],
    payment_status: null,
    status: null,
    terms: 'Due on Receipt',
    po_number: null,
    billable_items: [[]],
    custom_billable_items: [[]],
    email_details: fb.array([]),
    payments: fb.array([]),
    pdf_history: fb.array([]),
    generate_law_pay_invoice: false,
    law_pay_bank_account_id: null,
    law_pay_contact_id: null
  });

  if (value) {
    form.setValue(value);
  }

  // set up client
  form.addControl('client', GenerateInvoiceClientForm(fb, value?.client));

  return form;
}

export function GenerateInvoiceClientForm(fb: UntypedFormBuilder, value: Organization = null) {
  const form = fb.group({
    id: null,
    main_phone_number: null,
    main_fax_number: null,
    invoice_company_name: ['', Validators.required],
    invoice_email: null,
    invoice_vendor_id: null,
    invoice_street_address_1: ['', Validators.required],
    invoice_street_address_2: null,
    invoice_city: ['', Validators.required],
    invoice_state: ['', Validators.required],
    invoice_zip_code: ['', Validators.required],
    type: AccountType.CLIENT
  }, { nonNullable: false });

  if (value) {
    form.setValue(value);
  }

  return form;
}

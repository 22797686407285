

export interface UserSettings {
  classic_case_view: boolean;
  case_grid_settings: any
}

export function generateDefaultUserSettings(): UserSettings {
  return {
    classic_case_view: false,
    case_grid_settings: [],
  };
}

import { ColDef } from '@ag-grid-community/core';
import { ReportSettingField } from './report-field.model';
import { InvoiceStatus, ManualPaymentSource, PaymentStatus, PaymentType } from '@ee/common/enums';

export class ReportSettings {
  name: string;
  form_values: ReportSettingFormValue;
  column_definitions: ColDef[];
}

export class ReportSettingFormValue {
  client_id?: string;
  property_manager_id?: string;
  fields?: ReportSettingField[];
}

export class CaseReportSettingFormValue extends ReportSettingFormValue {
  status: number;
  tags: string[];
  and_tags: boolean;
}

export class TaskReportSettingFormValue extends ReportSettingFormValue {
  status: number;
  task_status: number;
}

export class InvoiceReportSettingFormValue extends ReportSettingFormValue {
  payment_status?: PaymentStatus;
  payment_type?: PaymentType | '';
  manual_payment_source?: ManualPaymentSource | '';
  draft_statuses: InvoiceStatus[];
  include_past_due_12_months?: boolean;
}

export enum RolesEnum {
  ACCOUNT_ADMIN = 'ACCOUNT_ADMIN',
  ATTORNEY = 'ATTORNEY',
  CLIENT = 'CLIENT',
  PROPERTY_MANAGER = 'PROPERTY_MANAGER',
  USER = 'ROLE_USER',
  ATTORNEY_USER = 'ATTORNEY_USER',
  CLIENT_USER = 'CLIENT_USER',
  SUPER_ADMIN = 'SUPER_ADMIN',
  ANONYMOUS = 'ROLE_ANONYMOUS'
}

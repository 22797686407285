import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {Workflow} from '@ee/common/models';

@Injectable({providedIn: 'root'})
export class WorkflowService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  getWorkflows(includeSteps = false): Observable<Workflow[]> {
    let queryParams = '';
    if (includeSteps) {
      queryParams += `include-steps=${includeSteps}`;
    }
    return this.http.get<Workflow[]>(`${this.environment.api_prefix}api/workflows?${queryParams}`);
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter, Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {PaymentMethod} from '@ee/common/models';
import {stripeLogo} from './logo';

declare let Stripe; // : stripe.StripeStatic;

@Component({
  selector: 'ee-payment-method-form',
  template: `
    <form (ngSubmit)="submit()" class="flex flex-col">
      <div class="card-details">
        <mat-label>Card Details</mat-label>
        <div #cardElement></div>
        <div class="card-errors">{{error}}</div>
      </div>
      <div class="flex flex-row justify-end items-start">
        <span class="mr-3">Payments processed securely by</span>
        <a href="https://www.stripe.com" target="_blank">
          <img class="logo" [src]="logo"
               alt="Payments are processed securely through Stripe">
        </a>
      </div>
    </form>
  `,
  styleUrls: ['./payment-method-form.component.scss']
})
export class PaymentMethodFormComponent implements AfterViewInit, OnDestroy {
  @ViewChild('cardElement') cardElement: ElementRef;

  @Input()
    stripeApiKey: string;

  logo = stripeLogo;

  @Output()
    paymentMethodSelected = new EventEmitter();

  stripe; // : stripe.Stripe;
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;

  constructor(private cd: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    this.stripe = Stripe(this.stripeApiKey);
    const elements = this.stripe.elements();
    this.card = elements.create('card');
    this.card.mount(this.cardElement.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    if (this.card) {
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }

  onChange({complete, error}) {
    if (error) {
      this.error = error.message;
      this.paymentMethodSelected.emit(undefined);
    } else {
      this.error = null;

      if (complete) {
        this.submit();
      } else {
        this.paymentMethodSelected.emit(undefined);
      }
    }

    this.cd.detectChanges();
  }

  async submit() {
    // @ts-ignore
    const {error, paymentMethod} = await this.stripe.createPaymentMethod({
      type: 'card',
      card: this.card
    });

    if (error) {
      this.error = error.message;
    } else {
      const billingPaymentMethod = {
        id: paymentMethod.id,
        brand: paymentMethod.card.brand,
        exp_month: paymentMethod.card.exp_month,
        exp_year: paymentMethod.card.exp_year,
        last4: paymentMethod.card.last4
      } as PaymentMethod;
      this.paymentMethodSelected.emit(billingPaymentMethod);
    }
  }
}

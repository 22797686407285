import {inject, Injectable, OnDestroy} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';

import {AuthService, StateStorageService} from '@ee/common/services';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers/app.reducer';
import {Organization} from '@ee/common/models';
import {SubscriptionPlan} from '@ee/common/enums';
import {UpgradeSubscriptionService} from '../services/upgrade-subscription.service';
import {SessionStorageService} from 'ngx-webstorage';
import {SUBSCRIPTION, SUBSCRIPTION_START} from '@ee/common/constants';


@Injectable({ providedIn: 'root' })
export class SubscriptionRouteAccessService  implements OnDestroy {
  private subs: Subscription[] = [];
  private org: Organization;

  constructor(private router: Router, private authService: AuthService, private stateStorageService: StateStorageService,
              private sessionStorage: SessionStorageService,
              private upgradeService: UpgradeSubscriptionService, private store: Store) {
    this.subs.push(this.store.select(fromRoot.getLoggedInOrg).subscribe((org: Organization) => {
      this.org = org;
    }));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    // We need to call the checkLogin / and so the accountService.identity() function, to ensure,
    // that the client has a principal too, if they already logged in by the server.
    // This could happen on a page refresh.
    return this.verifySubscription(state.url, route.data.disabledPlans);
  }

  verifySubscription(url: string = null, disabledPlans: SubscriptionPlan[]): boolean {
    let selectedPlan = this.sessionStorage.retrieve(SUBSCRIPTION);
    let orgCreatedDate = this.sessionStorage.retrieve(SUBSCRIPTION_START);
    if (!selectedPlan && this.org?.subscription_active) {
      selectedPlan = this.org.selected_plan;
      orgCreatedDate = this.org.created_date;
    }
    const planDisabled = disabledPlans?.indexOf(selectedPlan) > -1 &&
      new Date(orgCreatedDate) > new Date('2021-03-20');

    if (!planDisabled) {
      return true;
    }

    this.router.navigate(['accessdenied']).then(() => {
      // only show the login dialog, if the user hasn't logged in yet
      if (!this.org) {
        this.authService.login();
      } else if (this.org.subscription_status === 'incomplete') {
        // redirect to plan selection screen
        this.router.navigate(['/admin/billing']);
      } else if (planDisabled) {
        this.upgradeService.showUpgradeDialog(url, this.org.type);
        // this.router.navigate(['/dashboard']);
      } else {
        // redirect to plan selection screen
        this.router.navigate(['/plan']);
      }
    });

    return false;
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}

export const canActivateSubscriptionRoute: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(SubscriptionRouteAccessService).canActivate(route, state);
  };

// borrowed from: https://juri.dev/blog/2018/02/angular-permission-directive/
import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../reducers/app.reducer';
import { User } from '@ee/common/models';

/**
 * @whatItDoes Conditionally includes an HTML element if current user has any
 * of the authorities passed as the `expression`.
 *
 * @howToUse
 * ```
 *     <some-element *hasPermission="'all:case:general:view'">...</some-element>
 *
 *     <some-element *hasPermission="['all:case:general:view', 'all:case:general:create']; op 'OR'">...</some-element>
 *
 *     <some-element *hasPermission="['all:case:general:view', 'all:case:general:create']; op 'OR'; addlCondition false">...</some-element>
 * ```
 */
@Directive({
  selector: '[hasPermission]',
})
export class HasPermissionDirective implements OnDestroy {
  private permissions: string[] = [];
  private subs: Subscription[] = [];
  private user: User;
  private logicalOp: 'AND' | 'OR' = 'AND';
  private isHidden = true;
  private additionalCondition = true;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private store: Store) {
    this.subs.push(
      this.store.select(fromRoot.getLoggedInUser).subscribe((user: User) => {
        this.user = user;
        this.updateView();
      })
    );
  }

  @Input()
  set hasPermission(value) {
    this.permissions = typeof value === 'string' ? [value] : value;
    this.updateView();
  }

  @Input()
  set hasPermissionOp(permop: 'AND' | 'OR') {
    this.logicalOp = permop;
    this.updateView();
  }

  @Input()
  set hasPermissionAddlCondition(condition) {
    this.additionalCondition = condition;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    let hasPermission = false;

    if (this.user && this.user.permissions) {
      for (const checkPermission of this.permissions) {
        const permissionFound = this.user.permissions.find((x) => x.toUpperCase() === checkPermission.toUpperCase());

        if (permissionFound) {
          hasPermission = true;

          if (this.logicalOp === 'OR') {
            break;
          }
        } else {
          hasPermission = false;
          if (this.logicalOp === 'AND') {
            break;
          }
        }
      }
    }

    return hasPermission && this.additionalCondition;
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

import { createAction } from '@ngrx/store';
import { Task } from '@ee/common/models';

export const SHOW_ADD_CLIENT_DIALOG = '[Dialog] Show Add Client';
export const SHOW_TASK_DIALOG = '[Dialog] Show Task Entry';
export const SHOW_EDIT_DASHBOARD_DIALOG = '[Dialog] Show Edit Dashboard';
export const SHOW_CASE_SETTINGS_DIALOG = '[Dialog] Show Case Settings Dialog';
export const SHOW_ORG_SWITCHER_DIALOG = '[Dialog] Show Org Switch';
export const SHOW_INVOICE_CREATION_DIALOG = '[Dialog] Show Invoice Creation Dialog';
export const SHOW_REFERRAL_DIALOG = '[Dialog] Show Referral Dialog';


export const ShowAddClientDialog = createAction(
  SHOW_ADD_CLIENT_DIALOG,
  (redirectOnCreate: boolean = true) => ({ redirectOnCreate })
);

export const ShowTaskDialog = createAction(
  SHOW_TASK_DIALOG,
  (task?: Task) => ({ task })
);

export const ShowEditDashboardDialog = createAction(SHOW_EDIT_DASHBOARD_DIALOG);

export const ShowCaseSettingsDialog = createAction(SHOW_CASE_SETTINGS_DIALOG);

export const ShowReferralDialog = createAction(SHOW_REFERRAL_DIALOG);

export const ShowOrgSwitcherDialog = createAction(
  SHOW_ORG_SWITCHER_DIALOG,
  (currentOrgId: string) => ({ currentOrgId })
);


export const ShowInvoiceCreationDialog = createAction(SHOW_INVOICE_CREATION_DIALOG);

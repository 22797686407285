import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {BaseTask, DashboardDailyTasks} from '@ee/common/models';
import { DateTime } from 'luxon';

@Injectable({ providedIn: 'root' })
export class TaskService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  getScheduledTasks(date: DateTime): Observable<DashboardDailyTasks> {
    return this.http.get<DashboardDailyTasks>(`${this.environment.api_prefix}api/tasks/dashboard/${date.toISODate()}`);
  }

  getPlannedTasks(startDate: Date, endDate: Date): Observable<BaseTask[]> {
    const from = DateTime.fromJSDate(startDate).toISODate();
    const to = DateTime.fromJSDate(endDate).toISODate();
    return this.http.get<BaseTask[]>(`${this.environment.api_prefix}api/tasks/planned/${from}/${to}`);
  }

  getTask(id: string): Observable<BaseTask> {
    return this.http.get<BaseTask>(`${this.environment.api_prefix}api/tasks/${id}`);
  }

  saveTask(task: BaseTask): Observable<BaseTask> {
    return this.http.post<BaseTask>(`${this.environment.api_prefix}api/tasks`, task);
  }
}

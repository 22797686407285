import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from '@ag-grid-enterprise/core';

LicenseManager.setLicenseKey(environment.agGridLicense);

if (environment.production) {
  enableProdMode();
}

if ((window as any).newrelic) {
  (window as any).newrelic.setApplicationVersion(environment.version);
  (window as any).newrelic.addRelease(environment.version, environment.version);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UploadLogoComponent} from './upload-logo.component';
import {MatButtonModule} from '@angular/material/button';
import {ImageCropperModule} from 'ngx-image-cropper';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    ImageCropperModule,
    MatIconModule
  ],
  declarations: [
    UploadLogoComponent
  ],
  exports: [
    UploadLogoComponent
  ]
})
export class UploadLogoModule {
}

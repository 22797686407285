import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { User, UserDashboardSettings } from '@ee/common/models';
import { PortalType } from '@ee/common/enums';
import { Observable, Subscription } from 'rxjs';
import { UpdateUserDashboardSettings } from '../core/actions/user.actions';
import * as fromApp from '../core/reducers/app.reducer';

@Component({
  selector: 'ee-dashboard-settings-dialog',
  template: `
    <h2 mat-dialog-title>Dashboard Settings</h2>

    <div mat-dialog-content class="no-scroll-y double-column-list flex flex-col justify-stretch items-stretch">
      <dl class="flex flex-row justify-start items-center">
        <dt>Show Tasks</dt>
        <dd class="flex flex-row justify-start items-center">
          <mat-slide-toggle
            [(ngModel)]="dashboardSettings.show_tasks"
            color="accent">
            Show
          </mat-slide-toggle>
        </dd>
      </dl>
    </div>

    <div mat-dialog-actions class="ee-modal-actions">
      <button mat-button type="button" [mat-dialog-close]="">CANCEL</button>
      <button mat-button type="button" (click)="save()" cdkFocusInitial color="accent">
        SAVE
      </button>
    </div>
  `
})
export class DashboardSettingsDialogComponent implements OnInit, OnDestroy {
  portalType: Observable<string>;
  portalTypes = PortalType;
  dashboardSettings: UserDashboardSettings;
  subs: Subscription[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    public dialog: MatDialogRef<DashboardSettingsDialogComponent>,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.portalType = this.store.select(fromApp.getPortalType);
    this.subs.push(
      this.store.select(fromApp.getLoggedInUser).subscribe((user: User) => {
        this.dashboardSettings = { ...user.dashboard_settings };
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  save(): void {
    this.store.dispatch(UpdateUserDashboardSettings(this.dashboardSettings));
    this.dialog.close();
  }
}

import {UntypedFormBuilder, Validators} from '@angular/forms';
import {FormFieldName, CustomFieldType} from '@ee/common/enums';

export interface FormField {
  name: FormFieldName;
  type: CustomFieldType;
  default_value: string;
  required: boolean;
}

export function generateFormField(fb: UntypedFormBuilder) {
  return fb.group({
    name: [null, Validators.required],
    type: [null, Validators.required],
    default_value: null,
    required: false
  });
}

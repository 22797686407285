import { Route } from '@angular/router';
import { ActivateAttorneyComponent } from './activate-attorney.component';

export const activateAttorneyRoute: Route = {
  path: 'activate/attorney',
  component: ActivateAttorneyComponent,
  data: {
    authorities: [],
    pageTitle: 'Activate Account'
  }
};

import {createAction} from '@ngrx/store';
import {EmailDetails, IntakeEviction, IntakeEvictionList, LandingPageVisit, ProcessIntakeEviction as pie, Tenant} from '@ee/common/models';
import {ScraScanStatus} from '@ee/common/enums';

export const LOAD_INTAKE_EVICTIONS = '[Intake Eviction] Load Intake Evictions';
export const SET_INTAKE_EVICTIONS = '[Intake Eviction] Set Intake Eviction';
export const SET_CURRENT_INTAKE_EVICTIONS = '[Intake Eviction] Set Current Intake Eviction';
export const INTAKE_EVICTION_PROCESSED = '[Intake Eviction] Intake Eviction Processed';
export const UPDATE_LANDING_PAGE_VISIT = '[Intake Eviction] Update Landing Page Visit';
export const REJECT_INTAKE_EVICTION = '[Intake Eviction] Reject Intake Eviction';
export const PROCESS_INTAKE_EVICTION = '[Intake Eviction] Process Intake Eviction';
export const UPDATE_INTAKE_EVICTION = '[Intake Eviction] Update Intake Eviction';
export const INTAKE_EVICTION_UPDATED = '[Intake Eviction] Intake Eviction Updated';
export const GENERATE_FILLABLE_FORM_DETAILS = '[Intake Eviction] Generate Fillable Form Details';
export const INTAKE_EVICTION_SCRA_CHECK = '[Intake Eviction] SCRA Check';
export const UPDATE_INTAKE_EVICTION_TENANT = '[Intake Eviction] Update intake eviction tenant';
export const UPDATE_INTAKE_EVICTION_SCRA_STATUS = '[Intake Eviction] Update intake eviction SCRA status';

export const LoadIntakeEvictions = createAction(LOAD_INTAKE_EVICTIONS);

export const SetIntakeEvictions = createAction(
  SET_INTAKE_EVICTIONS,
  (payload: IntakeEvictionList[]) => ({payload})
);

export const SetCurrenIntakeEviction = createAction(
  SET_CURRENT_INTAKE_EVICTIONS,
  (payload: IntakeEviction) => ({payload})
);

export const ProcessIntakeEviction = createAction(
  PROCESS_INTAKE_EVICTION,
  (intakeEvictionId: string, intakeEviction: pie) => ({intakeEvictionId, intakeEviction})
);

export const RejectIntakeEviction = createAction(
  REJECT_INTAKE_EVICTION,
  (id: string, emailDetails?: EmailDetails) => ({id, emailDetails})
);

export const IntakeEvictionProcessed = createAction(
  INTAKE_EVICTION_PROCESSED,
  (intakeEvictionId: string) => ({intakeEvictionId})
);

export const UpdateLandingPageVisit = createAction(
  UPDATE_LANDING_PAGE_VISIT,
  (landingPageVisit: LandingPageVisit) => ({landingPageVisit})
);

export const UpdateIntakeEviction = createAction(
  UPDATE_INTAKE_EVICTION,
  (intakeEviction: IntakeEviction) => ({intakeEviction})
);

export const IntakeEvictionUpdated = createAction(
  INTAKE_EVICTION_UPDATED,
  (intakeEviction: IntakeEviction) => ({intakeEviction})
);

export const GenerateFillableFormDetails = createAction(
  GENERATE_FILLABLE_FORM_DETAILS,
  (intakeEviction: IntakeEviction, formPath: string) => ({intakeEviction, formPath})
);


export const IntakeEvictionScraCheck = createAction(
  INTAKE_EVICTION_SCRA_CHECK,
  (intakeEvictionId: string, tenantId: string) => ({intakeEvictionId, tenantId})
);

export const UpdateScraCheckStatus = createAction(
  UPDATE_INTAKE_EVICTION_SCRA_STATUS,
  (intakeEvictionId: string, tenants: Tenant[], status: ScraScanStatus) => ({intakeEvictionId, tenants, status})
);


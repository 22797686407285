<ee-banner [main]="true" type="error" *ngIf="enabled">
  <div class="flex flex-row justify-between items-center">
    <div>
      <b>{{issueType}}</b>: {{issueDescription}}
    </div>
    <button *ngIf="actionUrl && actionText" mat-flat-button type="button" color="primary" [routerLink]="actionUrl">
      {{actionText}}
    </button>
  </div>
</ee-banner>

import { Component, OnInit } from '@angular/core';
import { Organization } from '@ee/common/models';
import { AuthService } from '@ee/common/services';

@Component({
  selector: 'ee-portal-auth',
  template: `
  `,
  styles: ['']
})
export class AuthorizeComponent implements OnInit {
  loading: boolean;
  isActivated = true;
  attorney: Organization;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.login('/dashboard');
  }
}

import { Action } from '@ngrx/store';
import { DocumentTemplate } from '@ee/common/models';
import { ActivatedRoute } from '@angular/router';
import { AttachmentType } from '@ee/common/enums';

export const LOAD_ALL_FORMS = '[Form] Load All Forms';
export const LOAD_FORMS_BY_TYPE = '[Form] Load By Type';
export const SET_FORM_RESULTS = '[Form] Set Results';
export const GENERATE_FORM = '[Form] Generate Form';
export const GENERATE_FORMS = '[Form] Generate Forms';
export const LOAD_FORM = '[Form] Load Form';
export const SET_CURRENT_FORM = '[Form] Set Current Form';
export const SAVE_NEW_FORM = '[Form] Save New Form';
export const SAVE_EXISTING_FORM = '[Form] Save Existing Form';
export const FORM_SAVED = '[Form] Form Saved';
export const DELETE_FORM = '[Form] Delete Form';
export const FORM_DELETED = '[Form] Form Deleted';

export class LoadAllForms implements Action {
  readonly type = LOAD_ALL_FORMS;
}

export class LoadFormsByType implements Action {
  readonly type = LOAD_FORMS_BY_TYPE;

  constructor(public payload: AttachmentType) {
  } // eviction id
}

export class LoadFormById implements Action {
  readonly type = LOAD_FORM;

  constructor(public payload: string) {
  }
}

export class SetCurrentForm implements Action {
  readonly type = SET_CURRENT_FORM;

  constructor(public payload: DocumentTemplate) {
  }
}

export class SetFormResults implements Action {
  readonly type = SET_FORM_RESULTS;

  constructor(public payload: DocumentTemplate[]) {
  }
}

export class GenerateForm implements Action {
  readonly type = GENERATE_FORM;

  constructor(public caseId: string | undefined, public formId: string | undefined,
              public fieldValues: Map<string, string>, public hideFromClient: boolean = false) { // case id: form id
  }
}

export class GenerateForms implements Action {
  readonly type = GENERATE_FORMS;

  constructor(public caseId: string | undefined,
              public visibleDocuments: string[], public hiddenDocuments: string[]) { // case id: form id
  }
}

export class SaveNewForm implements Action {
  readonly type = SAVE_NEW_FORM;

  constructor(public formData: FormData, public currentRoute: ActivatedRoute) {
  }
}

export class SaveExistingForm implements Action {
  readonly type = SAVE_EXISTING_FORM;

  constructor(public payload: FormData) {
  }
}

export class FormSaved implements Action {
  readonly type = FORM_SAVED;

  constructor(public payload: DocumentTemplate) {
  }
}

export class DeleteForm implements Action {
  readonly type = DELETE_FORM;

  constructor(public payload: string) { // form id
  }
}

export class FormDeleted implements Action {
  readonly type = FORM_DELETED;

  constructor(public payload: string) { // form id
  }
}

export type FormActions =
  LoadFormsByType
  | LoadAllForms
  | SetFormResults
  | GenerateForm
  | LoadFormById
  | SetCurrentForm
  | SaveNewForm
  | SaveExistingForm
  | FormSaved
  | DeleteForm
  | FormDeleted;

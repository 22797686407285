import { Action } from '@ngrx/store';

export const SHOW_TOUR = '[Guided Tour] Show Tour';

export class ShowTour implements Action {
  readonly type = SHOW_TOUR;
  constructor(public tour: number, public forceShow: boolean = false,
              public additionalToursToMarkAsViewed: number[] = []) {
  }
}

export type GuidedTourActions =
  ShowTour;

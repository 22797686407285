import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from './app.reducer';
import {
  BILLING_DETAILS_LOADED,
  BillingActions,
  INVOICES_LOADED,
  SUBSCRIPTION_CANCELLED_REACTIVATED
} from '../actions/billing.actions';
import { BillingDetails, SubscriptionInvoices } from '@ee/common/models';

export interface BillingState {
  billingDetails: BillingDetails;
  invoices: SubscriptionInvoices[];
}

export interface State extends fromRoot.State {
  billing: BillingState;
}

const initialState: BillingState = {
  billingDetails: null,
  invoices: []
};

export function billingReducer(state = initialState, action: BillingActions) {
  switch (action.type) {
    case BILLING_DETAILS_LOADED:
      return {...state, billingDetails: action.payload};
    case SUBSCRIPTION_CANCELLED_REACTIVATED:
      const cancelledReactivatedBillingDetails = {...state.billingDetails};
      cancelledReactivatedBillingDetails.cancel_at_period_end = action.payload;
      return {...state, billingDetails: cancelledReactivatedBillingDetails};
    case INVOICES_LOADED:
      return {...state, invoices: action.payload};
    default: {
      return state;
    }
  }
}

export const getBillingMethod = createFeatureSelector<BillingState>('billing');

export const getBillingDetails = createSelector(
  getBillingMethod,
  (state: BillingState) => state.billingDetails
);

export const getSubscriptionInvoices = createSelector(getBillingMethod, (state: BillingState) => state.invoices);


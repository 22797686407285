import {FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function ValidateQuillEquation(equationEligibleFields: string[] | null): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    if (control?.value) {
      const equation = control.value.replace(/(<([^>]+)>)/gi, '').trim();
      const splitEquation = (equation.replace(/ /g,'')).match(/[^-+*/]+|[-+*/]+/g);

      let hasError = false;
      // confirm the structure of the equation is valid
      splitEquation.forEach((part: string, index: number) => {
        if (index % 2 === 0) {
          if (isNaN(part as any) && !equationEligibleFields?.includes(part.replace(/\s+/g, ''))) {
            hasError = true;
          }
        } else {
          if (part !== '+' && part !== '-' && part !== '*' && part !== '/') {
            hasError = true;
          }
        }
      });

      // confirm equation doesn't end in an operator
      if (splitEquation.length % 2 === 0) {
        hasError = true;
      }

      return !equationEligibleFields.length || !splitEquation.length || hasError ? { invalidEquation: true } : null;
    }
    return null;
  };
}

import { Injectable, OnDestroy } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class PwaService implements OnDestroy {
  private headers = new HttpHeaders().set('Cache-Control', 'no-cache').set('Pragma', 'no-cache');
  private subscriptions: Subscription[] = [];

  constructor(private readonly swUpdate: SwUpdate, private snackbar: MatSnackBar, private http: HttpClient) {}

  start() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(() => {
          const snackBarRef = this.snackbar.open('A new version of EasyEviction is available.', 'REFRESH');
          this.subscriptions.push(
            snackBarRef.afterDismissed().subscribe(() => {
              this.http.get('', { headers: this.headers, responseType: 'text' }).subscribe(() => location.reload());
            })
          );
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Organization, PlanModel } from '@ee/common/models';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AccountType } from '@ee/common/enums';
import { Router } from '@angular/router';

@Component({
  selector: 'ee-attorney-enterprise-subscribe',
  template: `
    <ee-order-summary [form]="form" [selectedPlan]="selectedPlan" [subscriptionStatus]="loggedInOrg?.status"
                      [currentOrg]="loggedInOrg"></ee-order-summary>
  `,
  styles: [
    `
      @import 'components/color-palette';

      .plan-feature {
        line-height: 17px;
        margin: 5px 0 !important;
      }

      .red {
        color: $primary-red;
      }

      .back-button {
        position: fixed;
        top: 1.5rem;
        left: 1.5rem;
        text-align: right;
        color: $c-gray-50;
        width: 120px;

        .mdc-icon-button {
          font-size: 1rem;
          width: 120px;
        }
      }

      :host ::ng-deep .mat-horizontal-content-container {
        padding: 24px;
      }

      :host ::ng-deep .mat-horizontal-stepper-header-container {
        display: none;
      }
    `
  ]
})
export class AttorneyEnterpriseSubscribeComponent implements OnInit, OnDestroy {
  @Input() plans: PlanModel[];

  @Input() loggedInOrg: Organization;

  @Input() form: UntypedFormGroup;

  selectedPlan: PlanModel;

  accountType = AccountType;

  isLoading = false;

  private subs: Subscription[] = [];

  constructor(
    private router: Router
  ) {}

  ngOnInit() {
    this.selectedPlan = this.plans.find(plan => plan.plan_id === this.form.get('plan').getRawValue());
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}

export enum Permission {
  // case permissions
  CASE_GENERAL_VIEW = 'case:general:view',
  CASE_GENERAL_CREATE = 'case:general:create',
  CASE_GENERAL_EDIT = 'case:general:edit',
  CASE_GENERAL_DELETE = 'case:general:delete',
  CASE_MESSAGES_VIEW = 'case:messages:view',
  CASE_MESSAGES_CREATE = 'case:messages:create',
  CASE_MESSAGES_DELETE = 'case:messages:delete',
  CASE_WORKFLOW_STEP_CREATE = 'case:complete-workflow-step:create',
  CASE_DOCUMENTS_VIEW = 'case:documents:view',
  CASE_UPLOAD_DOCUMENTS_CREATE = 'case:upload-documents:create',
  CASE_GENERATE_DOCUMENTS_CREATE = 'case:generate-documents:create',
  CASE_DOCUMENTS_DELETE = 'case:documents:delete',
  CASE_NOTES_VIEW = 'case:notes:view',
  CASE_NOTES_CREATE = 'case:notes:create',
  CASE_NOTES_DELETE = 'case:notes:delete',
  CASE_TASKS_VIEW = 'case:task:view',
  CASE_TASKS_CREATE = 'case:task:create',
  CASE_TASKS_EDIT = 'case:task:edit',
  CASE_TASKS_DELETE = 'case:task:delete',
  CASE_BILLABLES_VIEW = 'case:billable:view',
  CASE_BILLABLES_CREATE = 'case:billable:create',
  CASE_BILLABLES_EDIT = 'case:billable:edit',
  CASE_BILLABLES_DELETE = 'case:billable:delete',
  CASE_SCRA_CHECK_CREATE = 'case:scra-check:create',

  // tasks
  TASKS_GENERAL_VIEW = 'tasks:general:view',
  TASKS_GENERAL_CREATE = 'tasks:general:create',
  TASKS_GENERAL_EDIT = 'tasks:general:edit',
  TASKS_GENERAL_DELETE = 'tasks:general:delete',
  TASKS_COMPLETE_CREATE = 'tasks:complete:create',

  // daily docket
  DAILY_DOCKET_GENERAL_VIEW = 'daily-docket:general:view',

  // reports
  REPORTS_EVICTION_VIEW = 'reports:eviction:view',
  REPORTS_TASK_VIEW = 'reports:task:view',
  REPORTS_INVOICE_VIEW = 'reports:invoice:view',
  REPORTS_BILLABLE_ITEM_VIEW = 'reports:billable-item:view',
  REPORTS_STATEMENT_VIEW = 'reports:statement:view',

  // inbox
  INBOX_GENERAL_VIEW = 'inbox:general:view',
  INBOX_GENERAL_CREATE = 'inbox:general:create',
  INBOX_GENERAL_EDIT = 'inbox:general:edit',
  INBOX_GENERAL_DELETE = 'inbox:general:delete',

  // submitted cases (client only)
  SUBMITTED_CASES_GENERAL_VIEW = 'submitted-cases:general:view',
  SUBMITTED_CASES_GENERAL_EDIT = 'submitted-cases:general:edit',
  SUBMITTED_CASES_GENERAL_DELETE = 'submitted-cases:general:delete',

  // property manager permissions
  PROPERTY_MANAGERS_GENERAL_VIEW = 'pm:general:view',
  PROPERTY_MANAGERS_GENERAL_CREATE = 'pm:general:create',
  PROPERTY_MANAGERS_GENERAL_EDIT = 'pm:general:edit',
  PROPERTY_MANAGERS_GENERAL_DELETE = 'pm:general:delete',

  // client permissions
  CLIENT_GENERAL_VIEW = 'client:general:view',
  CLIENT_GENERAL_CREATE = 'client:general:create',
  CLIENT_GENERAL_EDIT = 'client:general:edit',
  CLIENT_GENERAL_DELETE = 'client:general:delete',
  CLIENT_MESSAGES_VIEW = 'client:messages:view',
  CLIENT_MESSAGES_CREATE = 'client:messages:create',
  CLIENT_NOTES_VIEW = 'client:notes:view',
  CLIENT_NOTES_EDIT = 'client:notes:edit',
  CLIENT_USER_VIEW = 'client:user:view',
  CLIENT_USER_CREATE = 'client:user:create',
  CLIENT_USER_DELETE = 'client:user:delete',
  CLIENT_USER_EDIT = 'client:user:edit',

  // client->attorney permissions
  ATTORNEY_MESSAGES_VIEW = 'attorney:messages:view',
  ATTORNEY_MESSAGES_CREATE = 'attorney:messages:create',

  // invoice permissions
  INVOICE_GENERAL_VIEW = 'invoice:general:view',
  INVOICE_GENERAL_CREATE = 'invoice:general:create',
  INVOICE_GENERAL_EDIT = 'invoice:general:edit',
  INVOICE_GENERAL_DELETE = 'invoice:general:delete',
  INVOICE_PAYMENT_CREATE = 'invoice:payment:create',
  INVOICE_PAYMENT_DELETE = 'invoice:payment:delete',
  INVOICE_CREDIT_CREATE = 'invoice:credit:create',
  INVOICE_CREDIT_DELETE = 'invoice:credit:delete',
  INVOICE_CREDIT_NOTE_VIEW = 'invoice:credit-note:view',
  INVOICE_CREDIT_NOTE_CREATE = 'invoice:credit-note:create',
  INVOICE_CREDIT_NOTE_EDIT = 'invoice:credit-note:edit',
  INVOICE_CREDIT_NOTE_DELETE = 'invoice:credit-note:delete',

  // admin settings
  ADMIN_COMPANY_SETTINGS_EDIT = 'admin:company-settings:edit',
  ADMIN_EXPORT_CLIENTS_CREATE = 'admin:export-clients:create',
  ADMIN_USERS_VIEW = 'admin:users:view',
  ADMIN_USERS_CREATE = 'admin:users:create',
  ADMIN_USERS_DELETE = 'admin:users:delete',
  ADMIN_USERS_EDIT = 'admin:users:edit',
  ADMIN_PERMISSION_GROUPS_VIEW = 'admin:permission-groups:view',
  ADMIN_PERMISSION_GROUPS_CREATE = 'admin:permission-groups:create',
  ADMIN_PERMISSION_GROUPS_DELETE = 'admin:permission-groups:delete',
  ADMIN_PERMISSION_GROUPS_EDIT = 'admin:permission-groups:edit',
  ADMIN_CASE_SETTINGS_VIEW = 'admin:case-settings:view',
  ADMIN_CASE_SETTINGS_EDIT = 'admin:case-settings:edit',
  ADMIN_INVOICE_SETTINGS_VIEW = 'admin:invoice-settings:view',
  ADMIN_INVOICE_SETTINGS_EDIT = 'admin:invoice-settings:edit',
  ADMIN_COUNTY_SETTINGS_VIEW = 'admin:county-settings:view',
  ADMIN_COUNTY_SETTINGS_EDIT = 'admin:county-settings:edit',
  ADMIN_DOCUMENT_TEMPLATES_VIEW = 'admin:document-templates:view',
  ADMIN_DOCUMENT_TEMPLATES_CREATE = 'admin:document-templates:create',
  ADMIN_DOCUMENT_TEMPLATES_DELETE = 'admin:document-templates:delete',
  ADMIN_DOCUMENT_TEMPLATES_EDIT = 'admin:document-templates:edit',
  ADMIN_FIELDS_VIEW = 'admin:fields:view',
  ADMIN_FIELDS_CREATE = 'admin:fields:create',
  ADMIN_FIELDS_DELETE = 'admin:fields:delete',
  ADMIN_FIELDS_EDIT = 'admin:fields:edit',
  ADMIN_INTAKE_FORM_VIEW = 'admin:intake-form:view',
  ADMIN_INTAKE_FORM_EDIT = 'admin:intake-form:edit',
  ADMIN_WORKFLOWS_VIEW = 'admin:workflows:view',
  ADMIN_WORKFLOWS_CREATE = 'admin:workflows:create',
  ADMIN_WORKFLOWS_DELETE = 'admin:workflows:delete',
  ADMIN_WORKFLOWS_EDIT = 'admin:workflows:edit',
  ADMIN_PLUGINS_VIEW = 'admin:plugins:view',
  ADMIN_PLUGINS_EDIT = 'admin:plugins:edit',

  // super admin settings
  ADMIN_BILLING_VIEW = 'admin:billing:view',
  ADMIN_BILLING_EDIT = 'admin:billing:edit'
}

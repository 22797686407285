import {AbstractControl, AsyncValidator, ValidationErrors} from '@angular/forms';
import {catchError, debounceTime, map, take} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {BillingService} from '@ee/common/services';

export class ValidateCouponCode implements AsyncValidator {
  static createValidator(billingService: BillingService) {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }

      // const result = billingService.findCoupon(control.value);

      return billingService.findCoupon(control.value).pipe(
        debounceTime(500),
        take(1),
        map(res => {
          let error = null;
          if (!res || !res.valid) {
            error = {couponInvalid: true};
          } else if (res.used) {
            error = {couponUsed: true};
          }
          return error;
        }),
        catchError(() => [{couponInvalid: true}])
      );
    };
  }

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null>;
  validate(control: AbstractControl): ValidationErrors | null;
  validate(): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> | ValidationErrors | null {
    return undefined;
  }
}

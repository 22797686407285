import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { AccountType, Permission, RolesEnum } from '@ee/common/enums';
import { AuthGuard } from '@auth0/auth0-angular';
import { canActivateUserRoute } from './core/guards/user-route-access.service';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: '/dashboard'
        },
        {
          path: '',
          loadChildren: () => import('./landing-pages/landing-pages.module').then(m => m.LandingPagesModule)
        },
        {
          path: '',
          loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
        },
        {
          path: '',
          loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule),
          data: {
            authorities: [RolesEnum.ATTORNEY, RolesEnum.CLIENT, RolesEnum.PROPERTY_MANAGER],
            pageTitle: ''
          },
          canActivate: [AuthGuard]
          // canLoad: [canActivateUserRoute]
        },
        {
          path: 'plan',
          loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule),
          canActivate: [canActivateUserRoute],
          data: {
            accountTypes: [AccountType.ATTORNEY, RolesEnum.PROPERTY_MANAGER],
            permissions: [Permission.ADMIN_BILLING_EDIT],
            permissionOperator: 'OR'
          }
        },
        {
          path: '**',
          redirectTo: '/404'
        }
      ],
      { enableTracing: !environment.production }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import cloneDeep from 'lodash-es/cloneDeep';
import { Organization } from '@ee/common/models';
import {
  OrganizationActions,
  SET_CURRENT_EVICTION_CLIENT,
  UPDATE_DEFAULT_WORKFLOW_COMPLETE
} from '../actions/organization.actions';

export interface State {
  currentClient: Organization;
  currentEvictionClient: Organization;
}

const initialState: State = {
  currentClient: null,
  currentEvictionClient: null,
};

export function organizationReducer(state = initialState, action: OrganizationActions) {
  // eslint-disable-next-line one-var,@typescript-eslint/no-unused-vars
  let i: number, j: number, clients, client, currentClient: Organization;
  switch (action.type) {
    case UPDATE_DEFAULT_WORKFLOW_COMPLETE:
      currentClient = cloneDeep(state.currentClient);
      currentClient.default_workflow_id = action.payload;
      return {
        ...state,
        currentClient
      };
    case SET_CURRENT_EVICTION_CLIENT:
      return {...state, currentEvictionClient: action.payload};
    default: {
      return state;
    }
  }
}

export const getClientState = createFeatureSelector<State>('client');
export const getCurrentClient = createSelector(
  getClientState,
  (state: State) => state.currentClient
);
export const getCurrentEvictionClient = (state: State) => state.currentEvictionClient;


import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {LogError} from '../actions/app.actions';
import {map} from 'rxjs/operators';

@Injectable()
export class AppEffects {

  logError$ = createEffect(() => this.actions$?.pipe(
    ofType(LogError),
    map(({payload}) => {
      if ((window as any).newrelic) {
        (window as any).newrelic.noticeError(payload);
      }
    })
  ), {dispatch: false});

  constructor(private actions$: Actions) {
  }
}

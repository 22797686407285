import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';

import {Tag} from '@ee/common/models';

@Injectable({providedIn: 'root'})
export class TagService {
  public resourceUrl = this.environment.api_prefix + 'api/organizations/tags';

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  getTags(type: string): Observable<Tag[]> {
    return this.http.get<Tag[]>(`${this.resourceUrl}/${type.toLowerCase()}`);
  }

  upsertTag(type: string, tag: Tag): Observable<Tag> {
    return this.http.post<Tag>(`${this.resourceUrl}/${type.toLowerCase()}`, tag);
  }

  deleteTag(type: string, tag: Tag): Observable<void> {
    return this.http.delete<void>(`${this.resourceUrl}/${type.toLowerCase()}/${tag.value}`);
  }

}

import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CreditNote } from '@ee/common/models';
import {CreditNoteStatus} from '@ee/common/enums';

@Injectable({ providedIn: 'root' })
export class CreditNoteService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  findByInvoiceId(invoiceId: string): Observable<CreditNote[]> {
    return this.http.get<CreditNote[]>(`${this.environment.api_prefix}api/credit-notes/invoice/${invoiceId}`);
  }

  findById(id: string): Observable<CreditNote> {
    return this.http.get<CreditNote>(`${this.environment.api_prefix}api/credit-notes/${id}`);
  }

  getDashboardValues(clientId: string | undefined = undefined): Observable<{[key: string]: any}> {
    const params = {};
    if (clientId) {
      params['client'] = clientId;
    }

    return this.http.get<{[key: string]: any}>(`${this.environment.api_prefix}api/credit-notes/dashboard-widgets`, { params });
  }

  save(creditNote: CreditNote): Observable<CreditNote> {
    return this.http.post<CreditNote>(`${this.environment.api_prefix}api/credit-notes`, creditNote);
  }

  delete(creditNote: CreditNote): Observable<boolean> {
    return this.http.delete<boolean>(`${this.environment.api_prefix}api/credit-notes/${creditNote.id}`);
  }

  findAvailableCreditNotes(invoiceId: string): Observable<CreditNote[]> {
    return this.http.get<CreditNote[]>(`${this.environment.api_prefix}api/credit-notes/available/${invoiceId}`);
  }

  findByClientId(clientId: string, creditNoteStatuses: CreditNoteStatus[]): Observable<CreditNote[]> {
    return this.http.get<CreditNote[]>(`${this.environment.api_prefix}api/credit-notes/client/${clientId}`, {
      params: {
        statuses: creditNoteStatuses.join(',').toUpperCase()
      }
    });
  }
}

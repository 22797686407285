import { createAction } from '@ngrx/store';
import { Task } from '@ee/common/models';

export const LOAD_ALL_TASKS = '[Task] Load';
export const LOAD_DASHBOARD_TASKS = '[Task] Load Dashboard';
export const TASKS_REFRESHING = '[Task] Loading';
export const DASHBOARD_TASKS_LOADED = '[Task] Dashboard Loaded';
export const SET_TASK_RESULTS = '[Task] Set Task Results';
export const COMPLETE_TASK = '[Task] Complete';
export const SAVE_TASK = '[Task] Save';
export const TASK_SAVED = '[Task] Saved';
export const DELETE_TASK = '[Task] Delete';
export const TASK_DELETED = '[Task] Deleted';

export const LoadTasks = createAction(LOAD_ALL_TASKS);
export const LoadDashboardTasks = createAction(LOAD_DASHBOARD_TASKS);

export const TasksRefreshing = createAction(
  TASKS_REFRESHING,
  (payload: boolean) => ({ payload })
);

export const SetTaskResults = createAction(
  SET_TASK_RESULTS,
  (tasks: Task[]) => ({ tasks })
);

export const CompleteTask = createAction(
  COMPLETE_TASK,
  (task: Task) => ({ task })
);

export const SaveTask = createAction(
  SAVE_TASK,
  (task: Task) => ({ task })
);

export const TaskSaved = createAction(
  TASK_SAVED,
  (task: Task) => ({ task })
);

export const DeleteTask = createAction(
  DELETE_TASK,
  (id: string) => ({ id })
);

export const TaskDeleted = createAction(
  TASK_DELETED,
  (id: string) => ({ id })
);

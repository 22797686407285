import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ParentChildService {

  eventTriggered = new Subject<ParentChildChangeEvent>();

  triggerEvent(): Observable<ParentChildChangeEvent> {
    return this.eventTriggered.asObservable();
  }
}

interface ParentChildChangeEvent {
  event: 'completed' | 'failed' | 'aborted' | 'unknown';
  id: string;
}

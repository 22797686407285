import { Injectable } from '@angular/core';
import { AccountType, SubscriptionPlan } from '@ee/common/enums';

export enum Events {
  REGISTRATION = 'Registration',
  AUTH = 'Auth',
  EVICTION = 'Eviction',
  GENERAL = 'General'
}

export enum RegistrationType {
  NEW_ORGANIZATION = 'New Organization',
  NEW_USER = 'New User'
}

export enum RegistrationSteps {
  PLAN_SELECTION = 'Plan Selection',
  CONFIRMATION = 'Confirmation'
}

export interface BaseProps {
  accountType: AccountType;
}

export interface GeneralProps extends BaseProps {
  event: string;
}

export interface RegistrationProps extends BaseProps {
  type: RegistrationType;
  step: RegistrationSteps;
  plan?: SubscriptionPlan;
}

export interface EvictionProps extends BaseProps {
  action: string;
  caseId: string;
  companyName: string;
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  recordEvent(event: Events, props?: BaseProps) {
    (window as any).plausible(event, { props });
  }
}

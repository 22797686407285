import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ee-organization-selector-dialog',
  template: `
    <h2 mat-dialog-title>Select Organization</h2>

    <form mat-dialog-content class="no-scroll-y flex flex-col justify-stretch items-stretch">
      <mat-form-field class="compact">
        <mat-label>Type</mat-label>
        <mat-select [required]="true">
<!--          <mat-option *ngFor="let type of customFieldTypes | keyvalue" [value]="type.value">-->
<!--            {{capitalCase(type.key)}}-->
<!--          </mat-option>-->
        </mat-select>
      </mat-form-field>
    </form>

    <div mat-dialog-actions class="ee-modal-actions">
        <button mat-button type="button" [mat-dialog-close]="">CANCEL</button>
        <button mat-button type="button" (click)="switch()" cdkFocusInitial color="accent">
          SWITCH
        </button>
    </div>
  `
})
export class OrganizationSelectorDialogComponent implements OnInit {
  constructor(
    public dialog: MatDialogRef<OrganizationSelectorDialogComponent>,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public currentOrgId?: string
  ) {}

  ngOnInit(): void {}

  switch(): void {
    // this.store.dispatch(new SaveTask());
    this.dialog.close();
  }
}

import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import {Organization, Results} from '@ee/common/models';
import {exhaustMap, Observable, scan, Subject, Subscription, takeWhile} from 'rxjs';
import {ClientService} from '@ee/common/services';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {debounceTime, filter, map, startWith, switchMap, tap} from 'rxjs/operators';
import {IServerSideGetRowsRequest} from '@ag-grid-community/core/dist/types/src/interfaces/iServerSideDatasource';
import {AccountType} from '@ee/common/enums';
import {IAutoCompleteScrollEvent} from '@ee/common/directives';

@Component({
  selector: 'ee-client-selection-dialog',
  template: `
    <h2 mat-dialog-title>{{ model.title ? model.title : 'Select a Client' }}</h2>
    <form class="content flex flex-col justify-stretch items-start" mat-dialog-content [formGroup]="searchForm">
      <mat-form-field class="compact w-full">
        <mat-label>Client Name</mat-label>
        <input type="text" placeholder="Pick a company" #text matInput formControlName="companyController"
               [matAutocomplete]="clientAuto">
        <mat-autocomplete #clientAuto="matAutocomplete" (optionsScroll)="onScroll()" [displayWith]="displayWith">
          @for (option of filteredClients$ | async; track option) {
            <mat-option [value]="option" class="flex flex-row justify-stretch items-center">
              <div class="flex flex-col">
                <span>{{ option.company_name }}</span>
                <span class="text-xs text-gray-500">{{ option.identifier ? option.identifier : '' }}</span>
              </div>
            </mat-option>
          }
        </mat-autocomplete>
        <mat-hint>Required. Select a client to continue.</mat-hint>
      </mat-form-field>
    </form>
    <div mat-dialog-actions class="ee-modal-actions">
      <button mat-button (click)="onCancel()" cdkFocusInitial type="button">Cancel</button>
      <button mat-button (click)="onSubmit()" [disabled]="!isCompanySelected" color="accent" type="button">
        Continue
      </button>
    </div>
  `,
  styles: [`
      @import 'components/color-palette';
  `]
})
export class ClientSelectionDialogComponent implements OnInit, OnDestroy {
  filteredClients$: Observable<Organization[]>;
  searchForm: FormGroup<{ companyController: FormControl<string> }>;
  subs: Subscription[] = [];
  fields: string[] = ['company_name', 'identifier', 'county_override_rules'];
  isCompanySelected = false;

  private maxPerPage = 20;
  private nextPage$ = new Subject();

  @Output('optionsScroll') scroll = new EventEmitter<IAutoCompleteScrollEvent>();
  _onDestroy = new Subject();

  constructor(@Inject(MAT_DIALOG_DATA) public model: { title: string },
              private dialogRef: MatDialogRef<ClientSelectionDialogComponent>,
              private fb: FormBuilder,
              private clientService: ClientService) {
  }

  ngOnInit() {
    this.searchForm = this.fb.group({
      companyController: ''
    });

    // listen for search text changes
    const filter$ = this.searchForm.get('companyController').valueChanges.pipe(
      startWith(''),
      debounceTime(200),
      filter(q => typeof q === 'string'));

    // listen for selection change
    this.subs.push(this.searchForm.get('companyController').valueChanges.subscribe((value) => {
      // @ts-ignore
      this.isCompanySelected = value && typeof value === 'object' && value?.company_name;
    }));

    this.filteredClients$ = filter$.pipe(
      switchMap(f => {
        //Note: Reset the page with every new search text
        let currentPage = 0;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(() => this.searchCompanies(f, currentPage)),
          tap(() => currentPage++),
          /** Note: This is a custom operator because we also need the last emitted value.
           Note: Stop if there are no more pages, or no results at all for the current search text.
           */
          takeWhile(p => p.results.length > 0),
          map((results: Results<any>) => results.results),
          scan((allCompanies: Organization[], newCompanies: Organization[]) => allCompanies.concat(newCompanies), []),
        );
      }));
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  displayWith(element: Organization) {
    return element ? element.company_name : null;
  }

  onScroll() {
    //Note: This is called multiple times after the scroll has reached the 80% threshold position.
    this.nextPage$.next(undefined);
  }

  onSubmit() {
    this.dialogRef.close(this.searchForm.get('companyController').value);
  }

  onCancel() {
    this.dialogRef.close();
  }

  private searchCompanies(query: string, page: number): Observable<Results<Organization>> {
    const search: IServerSideGetRowsRequest = {
      startRow: page * this.maxPerPage,
      endRow: (page + 1) * this.maxPerPage,
      rowGroupCols: [],
      valueCols: [],
      pivotCols: [],
      pivotMode: false,
      groupKeys: [],
      // @ts-ignore
      filterType: 'or',
      filterModel: {
        'company_name': {
          type: 'contains',
          filterType: 'text',
          filter: query?.toLowerCase() ?? ''
        },
        'identifier': {
          type: 'contains',
          filterType: 'text',
          filter: query?.toLowerCase() ?? ''
        }
      },
      sortModel: [{sort: 'asc', colId: 'company_name'}]
    };

    return this.clientService.findClients(search, AccountType.CLIENT);
  }

}

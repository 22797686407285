import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable, of, Subscription} from 'rxjs';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AddressSuggestion, Organization, populateForm} from '@ee/common/models';
import {GenerateClientIdentifier} from '@ee/common/forms';
import extend from 'lodash-es/extend';
import {STATES} from '@ee/common/constants';
import {AccountType} from '@ee/common/enums';
import {ClientService, PropertyManagerService} from '@ee/common/services';
import {ValidateClientIdentifierNotTaken} from '@ee/common/validators';

@Component({
  selector: 'ee-company-dialog',
  template: `
    <h2 mat-dialog-title [textContent]="client ? 'Edit Client Details' : 'Add Client'"></h2>

    <form mat-dialog-content [formGroup]="form" (ngSubmit)="save()" class="flex flex-col">

      <h2 class="pb-4">Company Information</h2>

      <div class="mb-4 flex flex-row justify-start items-start">
        <mat-form-field class="flex-[1_1_65%] mr-4">
          <mat-label>Company Name</mat-label>
          <input matInput formControlName="company_name">
          <mat-error *ngIf="form.get('company_name').hasError('required')">
            Company Name is required.
          </mat-error>
          <mat-hint>Required</mat-hint>
        </mat-form-field>

        <mat-form-field class="flex-[1_1_35%]">
          <mat-label>Identifier</mat-label>
          <input matInput formControlName="identifier" [maxlength]="5" [minlength]="2"
                 oninput="this.value = this.value.toUpperCase()" mask="AA||AAA||AAAA||AAAAA">
          <mat-hint>Required. 2-5 letter prefix to cases.</mat-hint>
          <mat-error
            *ngIf="form.get('identifier').hasError('required')">
            Company Identifier is required.
          </mat-error>
          <mat-error
            *ngIf="form.get('identifier').hasError('orgIdTaken')">
            Client Identifier is already taken.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="mb-4 flex flex-row">
        <mat-form-field class="flex-1">
          <mat-label>Additional Company Name Information</mat-label>
          <input matInput formControlName="company_name_2">
          <mat-hint>Optional</mat-hint>
        </mat-form-field>
      </div>
      <div class="mb-4 flex flex-row justify-stretch items-center" *ngIf="(propertyManagers$ | async) as propertyManagers">
        <mat-form-field class="flex-[1_0_100%]">
          <mat-label>Property Manager</mat-label>
          <mat-select formControlName="property_manager_id">
            <mat-option *ngFor="let pm of propertyManagers" [value]="pm.id">
              {{pm.company_name}}
            </mat-option>
            <mat-option [value]="undefined">
              None
            </mat-option>
          </mat-select>
          <mat-hint>Optional</mat-hint>
        </mat-form-field>
      </div>

      <h2 class="pb-4">Contact/Plaintiff Information</h2>

      <div class="mb-4 flex flex-row">
        <mat-form-field class="flex-1 mr-4">
          <mat-label>Contact First Name</mat-label>
          <input matInput formControlName="main_first_name">
          <mat-error *ngIf="form.get('main_first_name').hasError('required')">
            First name is required.
          </mat-error>
          <mat-hint>Required</mat-hint>
        </mat-form-field>
        <mat-form-field class="flex-1">
          <mat-label>Contact Last Name</mat-label>
          <input matInput formControlName="main_last_name">
          <mat-error *ngIf="form.get('main_last_name').hasError('required')">
            Last name is required.
          </mat-error>
          <mat-hint>Required</mat-hint>
        </mat-form-field>
      </div>
      <div class="mb-4 flex justify-stretch">
        <mat-form-field class="flex-1">
          <mat-label>Email</mat-label>
          <input matInput formControlName="main_email">
          <mat-error *ngIf="form.get('main_email').hasError('required')">
            Email is required.
          </mat-error>
          <mat-error *ngIf="form.get('main_email').hasError('email')">
            Invalid email format.
          </mat-error>
          <mat-hint>Required</mat-hint>
        </mat-form-field>
      </div>
      <div class="mb-4 flex flex-row">
        <mat-form-field class="mr-4 flex-1">
          <mat-label>Phone Number</mat-label>
          <input matInput mask="(000) 000-0000" formControlName="main_phone_number">
          <mat-hint>Optional</mat-hint>
        </mat-form-field>
        <mat-form-field class="flex-1 mr-4">
          <mat-label>Fax Number</mat-label>
          <input matInput mask="(000) 000-0000" formControlName="main_fax_number">
          <mat-hint>Optional</mat-hint>
        </mat-form-field>
        <div class="flex-1"></div>
      </div>

      <div class="mb-4">
        <ee-address-autocomplete class="flex-1" placeholder="Street Address" [required]="false"
                                 [formControl]="$any(form.get('street_address_1'))"
                                 (selectionChange)="onAddressSelected($event)">
        </ee-address-autocomplete>
      </div>

      <div class="mb-4 flex justify-stretch">
        <mat-form-field class="flex-1">
          <mat-label>Street Address 2</mat-label>
          <input matInput formControlName="street_address_2">
          <mat-hint>Optional</mat-hint>
        </mat-form-field>
      </div>

      <div class="mb-4 city-state-zip">
        <mat-form-field>
          <mat-label>City</mat-label>
          <input matInput formControlName="city">
          <mat-hint>Optional</mat-hint>
        </mat-form-field>

        <mat-form-field>
          <mat-label>State</mat-label>
          <mat-select formControlName="state">
            <mat-option *ngFor="let state of states | keyvalue" [value]="state.key">
              {{state.value}}
            </mat-option>
          </mat-select>
          <mat-hint>Optional</mat-hint>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Zip Code</mat-label>
          <input matInput formControlName="zip_code">
          <mat-hint>Optional</mat-hint>
        </mat-form-field>
      </div>

      <div class="flex flex-row justify-between items-baseline pb-4">
        <h2>Invoice Details</h2>
        <mat-slide-toggle formControlName="invoice_details_same_as_plaintiff" color="accent">
          Same as plaintiff details
        </mat-slide-toggle>
      </div>

      <div class="mb-4 flex flex-row justify-start items-start">
        <mat-form-field class="flex-1 mr-4">
          <mat-label>Invoice Email</mat-label>
          <input matInput formControlName="invoice_email">
          <mat-error *ngIf="form.get('invoice_email').hasError('required')">
            Invoice email is required.
          </mat-error>
          <mat-error *ngIf="form.get('invoice_email').hasError('email')">
            Invalid email format.
          </mat-error>
          <mat-hint>Optional. Default email address for sending invoices.</mat-hint>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Invoice Vendor Id</mat-label>
          <input matInput formControlName="invoice_vendor_id">
          <mat-hint>Optional</mat-hint>
        </mat-form-field>
      </div>

      <div class="mb-4 flex justify-stretch">
        <mat-form-field class="flex-1">
          <mat-label>Invoice Company Name</mat-label>
          <input matInput formControlName="invoice_company_name">
          <mat-hint>Optional</mat-hint>
        </mat-form-field>
      </div>

      <div class="mb-4">
        <ee-address-autocomplete class="flex-1" placeholder="Invoice Street Address"
                                 [required]="false"
                                 [formControl]="$any(form.get('invoice_street_address_1'))"
                                 (selectionChange)="onInvoiceAddressSelected($event)">
        </ee-address-autocomplete>
      </div>

      <div class="mb-4 flex justify-stretch">
        <mat-form-field class="flex-1">
          <mat-label>Invoice Street Address 2</mat-label>
          <input matInput formControlName="invoice_street_address_2">
          <mat-hint>Optional</mat-hint>
        </mat-form-field>
      </div>

      <div class="city-state-zip mb-4">
        <mat-form-field>
          <mat-label>Invoice City</mat-label>
          <input matInput formControlName="invoice_city">
          <mat-hint>Optional</mat-hint>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Invoice State</mat-label>
          <mat-select formControlName="invoice_state">
            <mat-option *ngFor="let state of states | keyvalue" [value]="state.key">
              {{state.value}}
            </mat-option>
          </mat-select>
          <mat-hint>Optional</mat-hint>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Invoice Zip Code</mat-label>
          <input matInput formControlName="invoice_zip_code">
          <mat-hint>Optional</mat-hint>
        </mat-form-field>
      </div>

      <h2 class="pb-4">County Override Rules</h2>
      <p class="mb-4">
        Define rules to override auto-selected counties for specific states and zip codes.
      </p>
      <ee-county-overrides [countyOverrideRules]="$any(form.get('county_override_rules'))"></ee-county-overrides>
    </form>

    <div mat-dialog-actions class="ee-modal-actions">
      <button mat-button [mat-dialog-close]="null" type="button">CANCEL</button>
      <button mat-button (click)="save()" cdkFocusInitial color="accent" [disabled]="form.invalid" type="submit">
        SAVE
      </button>
    </div>
  `,
  styles: [`
    .disabled-wrapper {
      height: 35px;
    }

    .actions {
      margin-bottom: 2px;
    }

    h2 {
      margin-bottom: 0;
    }
  `]
})
export class ClientDetailsDialogComponent implements OnInit, OnDestroy {

  subs: Subscription[] = [];
  form: UntypedFormGroup;
  states = STATES;
  propertyManagers$: Observable<Organization[]> = of([]);

  constructor(
    private clientService: ClientService,
    private propertyManagerService: PropertyManagerService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ClientDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public client: Organization) {
    const identifier = client ? client.identifier : null;
    const invoiceSameAsPlaintiff = client ? client.invoice_details_same_as_plaintiff : true;
    this.form = fb.group({
      company_name: [client ? client.company_name : null, Validators.required],
      company_name_2: [client ? client.company_name_2 : null],
      property_manager_id: [client?.property_manager ? client.property_manager?.id : undefined],
      main_email: [client ? client.main_email : null, {validators: [Validators.required, Validators.email]}],
      main_first_name: [client ? client.main_first_name : null, {validators: [Validators.required]}],
      main_last_name: [client ? client.main_last_name : null, {validators: [Validators.required]}],
      street_address_1: [client ? client.street_address_1 : null],
      street_address_2: [client ? client.street_address_2 : null],
      city: [client ? client.city : null],
      state: [client ? client.state : null],
      zip_code: [client ? client.zip_code : null],
      main_phone_number: [client ? client.main_phone_number : null],
      main_fax_number: [client ? client.main_fax_number : null],
      invoice_vendor_id: [client ? client.invoice_vendor_id : null],
      invoice_details_same_as_plaintiff: [invoiceSameAsPlaintiff],
      invoice_email: [{value: client ? client.invoice_email : null, disabled: invoiceSameAsPlaintiff}, {validators: [Validators.email]}],
      invoice_company_name:  [{value: client ? client.invoice_company_name : null, disabled: invoiceSameAsPlaintiff}],
      invoice_street_address_1:  [{value: client ? client.invoice_street_address_1 : null, disabled: invoiceSameAsPlaintiff}],
      invoice_street_address_2:  [{value: client ? client.invoice_street_address_2 : null, disabled: invoiceSameAsPlaintiff}],
      invoice_city:  [{value: client ? client.invoice_city : null, disabled: invoiceSameAsPlaintiff}],
      invoice_state:  [{value: client ? client.invoice_state : null, disabled: invoiceSameAsPlaintiff}],
      invoice_zip_code:  [{value: client ? client.invoice_zip_code : null, disabled: invoiceSameAsPlaintiff}],
      identifier: [{value: identifier, disabled: this.client != null}, Validators.required],
      county_override_rules: this.fb.array([])
    });
    if (!this.client) {
      this.form.get('identifier').setAsyncValidators(ValidateClientIdentifierNotTaken.createValidator(this.clientService));
    } else if (this.client.county_override_rules?.length > 0) {
      this.client.county_override_rules.forEach((rule) => {
        (this.form.get('county_override_rules') as UntypedFormArray).push(this.fb.group({
          state: [rule.state, Validators.required],
          zip_code: [rule.zip_code, Validators.required],
          county_id: [rule.county_id, Validators.required]
        }));
      });
    }

    this.subs.push(this.form.get('invoice_details_same_as_plaintiff').valueChanges.subscribe((value) => {
      if (value) {
        this.form.get('invoice_email').disable();
        this.form.get('invoice_email').setValue(this.form.get('main_email').value);
        this.form.get('invoice_company_name').disable();
        this.form.get('invoice_company_name').setValue(this.form.get('company_name').value);
        this.form.get('invoice_street_address_1').disable();
        this.form.get('invoice_street_address_1').setValue(this.form.get('street_address_1').value);
        this.form.get('invoice_street_address_2').disable();
        this.form.get('invoice_street_address_2').setValue(this.form.get('street_address_2').value);
        this.form.get('invoice_city').disable();
        this.form.get('invoice_city').setValue(this.form.get('city').value);
        this.form.get('invoice_state').disable();
        this.form.get('invoice_state').setValue(this.form.get('state').value);
        this.form.get('invoice_zip_code').disable();
        this.form.get('invoice_zip_code').setValue(this.form.get('zip_code').value);
      } else {
        this.form.get('invoice_company_name').enable();
        this.form.get('invoice_email').enable();
        this.form.get('invoice_street_address_1').enable();
        this.form.get('invoice_street_address_2').enable();
        this.form.get('invoice_city').enable();
        this.form.get('invoice_state').enable();
        this.form.get('invoice_zip_code').enable();
      }
    }));

    this.subs.push(this.form.get('company_name').valueChanges.subscribe((value) => {
      if (!this.client) {
        this.populateIdentifier();
      }
      if (this.form.get('invoice_details_same_as_plaintiff').value) {
        this.form.get('invoice_company_name').setValue(value);
      }
    }));

    this.subs.push(this.form.get('main_email').valueChanges.subscribe(() => {
      if (this.form.get('invoice_details_same_as_plaintiff').value) {
        this.form.get('invoice_email').setValue(this.form.get('main_email').value);
      }
    }));

    this.subs.push(this.form.get('street_address_1').valueChanges.subscribe(() => {
      if (this.form.get('invoice_details_same_as_plaintiff').value) {
        this.form.get('invoice_street_address_1').setValue(this.form.get('street_address_1').value);
      }
    }));

    this.subs.push(this.form.get('street_address_2').valueChanges.subscribe(() => {
      if (this.form.get('invoice_details_same_as_plaintiff').value) {
        this.form.get('invoice_street_address_2').setValue(this.form.get('street_address_2').value);
      }
    }));

    this.subs.push(this.form.get('city').valueChanges.subscribe(() => {
      if (this.form.get('invoice_details_same_as_plaintiff').value) {
        this.form.get('invoice_city').setValue(this.form.get('city').value);
      }
    }));

    this.subs.push(this.form.get('state').valueChanges.subscribe(() => {
      if (this.form.get('invoice_details_same_as_plaintiff').value) {
        this.form.get('invoice_state').setValue(this.form.get('state').value);
      }
    }));

    this.subs.push(this.form.get('zip_code').valueChanges.subscribe(() => {
      if (this.form.get('invoice_details_same_as_plaintiff').value) {
        this.form.get('invoice_zip_code').setValue(this.form.get('zip_code').value);
      }
    }));
  }

  ngOnInit() {
    this.propertyManagers$ = this.propertyManagerService.getPropertyManagers();
  }

  onAddressSelected(suggestion: AddressSuggestion) {
    populateForm(this.form, suggestion);
  }

  onInvoiceAddressSelected(suggestion: AddressSuggestion) {
    populateForm(this.form, suggestion, 'invoice_');
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }

  populateIdentifier() {
    // auto-populate the company identifier if and only if adding new client.
    if (!this.client) {
      const companyName = this.form.get('company_name').value;
      const companyIdentifier = GenerateClientIdentifier(companyName);
      const orgIdentifierControl = this.form.get('identifier');
      orgIdentifierControl.markAsTouched({onlySelf: true});
      orgIdentifierControl.setValue(companyIdentifier);
    }
  }

  save() {
    const clientForm = this.form.getRawValue() as Organization;
    const clientToUpdate = {...this.client, type: AccountType.CLIENT };
    extend(clientToUpdate, clientForm);
    this.dialogRef.close(clientToUpdate);
  }
}

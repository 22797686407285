import { Action } from '@ngrx/store';
import { ClientConfirmationDecision, EmailDetails, Organization, RegistrationModel } from '@ee/common/models';

export const UPDATE_DEFAULT_WORKFLOW = '[Organization] Update Default Workflow';
export const UPDATE_DEFAULT_WORKFLOW_COMPLETE = '[Organization] Update Default Workflow Complete';
export const ADD_NEW_CLIENT = '[Organization] Add New Organization';
export const ADD_ATTORNEY = '[Organization] Add Attorney';
export const ATTORNEY_ADDED = '[Organization] Attorney Added';
export const LOAD_EVICTION_CLIENT = '[Organization] Load Eviction Organization';
export const SET_CURRENT_EVICTION_CLIENT = '[Organization] Set Current Eviction Organization';
export const CLIENT_ADD_ATTORNEY_REQUEST = '[Organization] Client Add Attorney Request';
export const CONFIRM_ATTORNEY_REQUEST = '[Organization] Confirm Attorney Request';
export const INVITE_CLIENT = '[Organization] Invite Client';

export class UpdateDefaultWorkflow implements Action {
  readonly type = UPDATE_DEFAULT_WORKFLOW;

  constructor(public payload: string) {
  }
}

export class UpdateDefaultWorkflowComplete implements Action {
  readonly type = UPDATE_DEFAULT_WORKFLOW_COMPLETE;

  constructor(public payload: string) {
  }
}


export class AddNewClient implements Action {
  readonly type = ADD_NEW_CLIENT;

  constructor(public payload: Organization, public redirectOnCreate: boolean = true) {
  }
}

export class AddAttorney implements Action {
  readonly type = ADD_ATTORNEY;

  constructor(public payload: RegistrationModel) {
  }
}

export class AttorneyAdded implements Action {
  readonly type = ATTORNEY_ADDED;

  constructor(public payload: Organization) {
  }
}

export class LoadEvictionClient implements Action {
  readonly type = LOAD_EVICTION_CLIENT;

  constructor(public payload: string | undefined) {
  } // client id
}

export class SetCurrentEvictionClient implements Action {
  readonly type = SET_CURRENT_EVICTION_CLIENT;

  constructor(public payload: Organization) {
  }
}

export class ClientAddAttorneyRequest implements Action {
  readonly type = CLIENT_ADD_ATTORNEY_REQUEST;

  constructor(public attorneyKey: string) {
  }
}

export class ConfirmAttorneyRequest implements Action {
  readonly type = CONFIRM_ATTORNEY_REQUEST;

  constructor(public clientId: string, public decision: ClientConfirmationDecision) {
  }
}

export class InviteClient implements Action {
  readonly type = INVITE_CLIENT;

  constructor(public clientId: string, public emailDetails: EmailDetails) {
  }
}

export type OrganizationActions =
  UpdateDefaultWorkflow
  | UpdateDefaultWorkflowComplete
  | AddNewClient
  | AddAttorney
  | AttorneyAdded
  | LoadEvictionClient
  | SetCurrentEvictionClient
  | ClientAddAttorneyRequest
  | ConfirmAttorneyRequest
  | InviteClient;

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { User, UserSettings } from '@ee/common/models';
import { PortalType } from '@ee/common/enums';
import { Observable, Subscription } from 'rxjs';
import * as fromApp from '../core/reducers/app.reducer';
import { UpdateUserSetting } from '../core/actions/user.actions';

@Component({
  selector: 'ee-user-settings-dialog',
  template: `
    <h2 mat-dialog-title>Case Settings</h2>

    <div mat-dialog-content class="no-scroll-y double-column-list flex flex-col justify-stretch items-stretch">
      <dl class="flex flex-row justify-start items-center">
        <dt>Classic Case View</dt>
        <dd class="flex flex-row justify-start items-center">
          <mat-slide-toggle
            [(ngModel)]="settings.classic_case_view"
            (ngModelChange)="updateSetting('classic_case_view', $event)"
            color="accent">
            Enabled
          </mat-slide-toggle>
        </dd>
      </dl>
    </div>

    <div mat-dialog-actions class="ee-modal-actions">
      <button mat-button type="button" [mat-dialog-close]="" cdkFocusInitial color="accent">
        CLOSE
      </button>
    </div>
  `
})
export class CaseSettingsDialogComponent implements OnInit, OnDestroy {
  portalType: Observable<string>;
  portalTypes = PortalType;
  settings: UserSettings;
  subs: Subscription[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    public dialog: MatDialogRef<CaseSettingsDialogComponent>,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.subs.push(
      this.store.select(fromApp.getLoggedInUser).subscribe((user: User) => {
        this.settings = { ...user.settings };
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  updateSetting(key: string, value: any | null): void {
    this.store.dispatch(UpdateUserSetting(key, value));
  }
}

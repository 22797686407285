import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';
import {TagManagerDialogComponent} from './tag-manager-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {TagService} from './tag.service';
import {TagComponent} from './tag.component';
import {AgTagRendererComponent} from './ag-tag-renderer.component';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    CdkConnectedOverlay,
    CdkOverlayOrigin
  ],
  providers: [TagService],
  declarations: [TagManagerDialogComponent, TagComponent, AgTagRendererComponent],
  exports: [TagManagerDialogComponent, TagComponent, AgTagRendererComponent]
})
export class TagManagerModule {}

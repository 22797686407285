import { Action } from '@ngrx/store';
import { County } from '@ee/common/models';

export const LOAD_ALL_COUNTIES_BY_STATE = '[County] Load By State';
export const SET_COUNTY_RESULTS = '[County] Set County Results';
export const UPDATE_COUNTY = '[County] Update County';
export const COUNTY_UPDATED = '[County] County Updated';

export class LoadCountiesByState implements Action {
  readonly type = LOAD_ALL_COUNTIES_BY_STATE;

  constructor(public payload: string) {
  } // state
}

export class SetCountyResults implements Action {
  readonly type = SET_COUNTY_RESULTS;

  constructor(public payload: County[]) {
  } // Matching zipCodeToCounty results
}

export class UpdateCounty implements Action {
  readonly type = UPDATE_COUNTY;

  constructor(public payload: County) {
  }
}

export class CountyUpdated implements Action {
  readonly type = COUNTY_UPDATED;

  constructor(public payload: County) {
  }
}

export type CountyActions =
  LoadCountiesByState
  | SetCountyResults
  | UpdateCounty
  | CountyUpdated;

import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'ee-offline-snackbar',
  template: `
    <div class="offline-snackbar flex flex-1 flex-row justify-start items-center">
      <mat-icon class="mr-2">cloud_off</mat-icon>
      <div class="mr-2">{{data.message}}</div>
      <button *ngIf="data.dismissable" mat-button (click)="dismiss()" color="accent">
        Dismiss
      </button>
    </div>
  `,
  styles: [
    `
      .offline-snackbar .mat-icon {
        color: #d70000;
      }
    `
  ]
})
export class OfflineSnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public snackBar: MatSnackBar) {}

  dismiss() {
    this.snackBar.dismiss();
  }
}

import {
  ActivationRequestSent,
  AppSettingsLoaded,
  Authenticated,
  AuthError,
  CaseGridSettingsUpdated,
  CustomCaseFieldsUpdated,
  DashboardWidgetsUpdated,
  DefaultWorkflowUpdated,
  FeatureFlagUpdated,
  GetLoggedInUser,
  GuidesViewedUpdated,
  LoggedInOrgUpdated,
  LoggedInUserUpdated,
  Logout,
  NotAuthenticated,
  NotificationSettingsUpdated,
  SetAttorneyClientSettings,
  SetCaseSettings,
  SetLoggedInOrg,
  SetScraMonthlyLimit,
  UpdateAllTags,
  UpdatePermissionGroups,
  UpdateTags,
  UpdateUnreadMessageCount,
  UserBarLicensesUpdated,
  UserDashboardSettingsUpdated,
  UserDetailsUpdated,
  UserEmailUpdated,
  UserSettingUpdated
} from '../actions/auth.actions';
import {
  AttorneyClientSettings, AttorneySettings,
  CaseSettings,
  DashboardWidgets,
  generateDefaultUserSettings,
  Organization,
  PropertyManagerDashboardWidgets,
  Tag,
  User
} from '@ee/common/models';
import {PluginType} from '@ee/common/enums';
import copy from 'fast-copy';
import {UpdatePluginIntegrationStatus} from '../actions/plugin.actions';
import {createReducer, on} from '@ngrx/store';
import sortBy from 'lodash-es/sortBy';

export interface State {
  isActivated: boolean;
  isAuthenticated: boolean;
  loggedInUser: User;
  loggedInOrg: Organization;
  dashboardWidgets: DashboardWidgets | PropertyManagerDashboardWidgets;
  attorneyClientSettings: AttorneyClientSettings;
  caseSettings: CaseSettings | undefined;
  permissionGroups: { [key: string]: string };
  appSettings: any;
  loading: boolean;
  tags: Tag[];
  featureFlags: { string?: boolean };
  scraMonthlyLimit: number;
}

const initialState: State = {
  isActivated: true,
  isAuthenticated: false,
  loggedInUser: null,
  loggedInOrg: null,
  dashboardWidgets: null,
  appSettings: null,
  attorneyClientSettings: null,
  caseSettings: null,
  loading: false,
  permissionGroups: {},
  tags: [],
  featureFlags: {},
  scraMonthlyLimit: 0
};

export const authReducer = createReducer(
  initialState,
  on(GetLoggedInUser, (state) => ({...state, loading: true})),
  on(SetLoggedInOrg, (state, action) => ({...state,
    loggedInOrg: action.payload,
    tags: action.payload?.org_settings?.tags,
    roles: action.payload?.org_settings?.roles ?? {},
    caseSettings: action.payload.org_settings?.case_settings ?? undefined,
    featureFlags: (action.payload.org_settings as AttorneySettings)?.feature_flags,
    scraMonthlyLimit: (action.payload.org_settings as AttorneySettings)?.scra_monthly_limit,
    portalType: action.payload.type.toLowerCase()})),
  on(Authenticated, (state, action) => ({...state, isAuthenticated: true, loggedInUser: copy(action.user)})),
  on(ActivationRequestSent, (state, action) => ({...state, isActivated: action.payload})),
  on(NotAuthenticated, (state) => ({
    ...state,
    isAuthenticated: false,
    loading: false,
    loggedInUser: null,
    loggedInOrg: null,
    portalType: null,
    appSettings: null,
    caseSettings: null
  })),
  on(AuthError, (state) => ({...state, loading: false, isAuthenticated: false})),
  on(Logout, (state) => ({...state, loading: false})),
  on(NotificationSettingsUpdated, (state, action) => {
    const user = {...state.loggedInUser};
    user.notification_settings = action.payload;
    return {...state, loggedInUser: user};
  }),
  on(UserDashboardSettingsUpdated, (state, action) => {
    const user = {...state.loggedInUser};
    user.dashboard_settings = action.payload;
    return {...state, loggedInUser: user};
  }),
  on(UserSettingUpdated, (state, action) => {
    const user = {...state.loggedInUser};
    if (!user.settings) {
      user.settings = generateDefaultUserSettings();
    }
    user.settings[action.key] = action.value;
    return {...state, loggedInUser: user};
  }),
  on(CaseGridSettingsUpdated, (state, action) => {
    const user = {...state.loggedInUser};
    if (!user.settings) {
      user.settings = generateDefaultUserSettings();
    }
    user.settings.case_grid_settings = action.payload;
    return {...state, loggedInUser: user};
  }),
  on(UserDetailsUpdated, (state, action) => ({...state, loggedInUser: action.payload})),
  on(UserEmailUpdated, (state, action) => {
    const user = {...state.loggedInUser};
    user.email = action.payload;
    return {...state, loggedInUser: user};
  }),
  on(UserBarLicensesUpdated, (state, action) => {
    const user = {...state.loggedInUser};
    user.bar_licenses = action.payload;
    return {...state, loggedInUser: user};
  }),
  on(GuidesViewedUpdated, (state, action) => {
    const user = {...state.loggedInUser};
    user.recently_viewed_guides.push(action.payload);
    return {...state, loggedInUser: user};
  }),
  on(DashboardWidgetsUpdated, (state, action) => {
    if (action.payload) {
      const dashboardWidgets = copy(action.payload);
      if ((action.payload as DashboardWidgets).conversion_details) {
        (dashboardWidgets as DashboardWidgets).conversion_details = {...(action.payload as DashboardWidgets).conversion_details};
      }

      return {...state, dashboardWidgets};
    }
    return {...state};
  }),
  on(LoggedInOrgUpdated, (state, action) => {
    let loggedInOrg = {...state.loggedInOrg};
    if (loggedInOrg.id === action.payload.id) {
      loggedInOrg = action.payload;
    }
    return {...state, loggedInOrg};
  }),
  on(LoggedInUserUpdated, (state, action) => {
    let loggedInUser = state.loggedInUser;
    if (loggedInUser.id === action.payload.id) {
      loggedInUser = action.payload;
    }
    return {...state, loggedInUser};
  }),
  on(DefaultWorkflowUpdated, (state, action) => {
    const loggedInOrg = {...state.loggedInOrg} as Organization;
    loggedInOrg.default_workflow_id = action.payload;
    return {...state, loggedInOrg};
  }),
  on(UpdatePluginIntegrationStatus, (state, action) => {
    const loggedInOrg = {...state.loggedInOrg} as Organization;
    switch (action.pluginType) {
      case PluginType.INVOICE_PAYMENT:
        loggedInOrg.plugins.law_pay = action.connected;
        break;
      default:
        console.error('Invalid plugin type.');
    }
    return {...state, loggedInOrg};
  }),
  on(AppSettingsLoaded, (state, action) => {
    const appSettings = action.payload;
    return {...state, appSettings};
  }),
  on(UpdateUnreadMessageCount, (state, action) => {
    const dashboardWidgets = {...state.dashboardWidgets} as DashboardWidgets;
    dashboardWidgets.unread_message_count = action.count;
    return {...state, dashboardWidgets};
  }),
  on(SetCaseSettings, (state, action) => ({
    ...state,
    caseSettings: action.payload
  })),
  on(SetAttorneyClientSettings, (state, action) => ({
    ...state,
    attorneyClientSettings: action.payload
  })),
  on(SetScraMonthlyLimit, (state, action) => ({
    ...state,
    scraMonthlyLimit: action.payload
  })),
  on(UpdateAllTags, (state, action) => ({
    ...state,
    tags: sortBy(action.tags, ['name'])
  })),
  on(UpdateTags, (state, action) => {
    const tags = ([...state.tags] as Tag[]).filter(t => t.type !== action.tagType);
    return {...state, tags: sortBy([...tags, ...action.tags] as Tag[], ['name'])};
  }),
  on(UpdatePermissionGroups, (state, action) => {
    const permissionGroups = {};
    action.roles?.forEach((role) => {
      permissionGroups[role.id] = role.name;
    });
    return { ...state, permissionGroups };
  }),
  on(CustomCaseFieldsUpdated, (state, action) => {
    const loggedInOrg = {...state.loggedInOrg};
    loggedInOrg.custom_case_fields = action.fields;
    return {...state, loggedInOrg};
  }),
  on(FeatureFlagUpdated, (state, action) => {
    const featureFlags = {...state.featureFlags};
    featureFlags[action.feature] = action.enabled;
    return {...state, featureFlags};
  })
);

export const getIsAuth = (state: State) => state.isAuthenticated;
export const getIsActivated = (state: State) => state.isActivated;
export const getLoggedInUser = (state: State) => state.loggedInUser;
export const getIsLoading = (state: State) => state.loading;
export const getLoggedInOrg = (state: State) => state.loggedInOrg;
export const getCurrentPlan = (state: State) => state.loggedInOrg?.selected_plan;
export const getAppSettings = (state: State) => state.appSettings;
export const getCaseSettings = (state: State) => state.caseSettings;
export const getAttorneyClientSettings = (state: State) => state.attorneyClientSettings;
export const getScraMonthlyLimit = (state: State) => state.scraMonthlyLimit;
export const getFeatureFlags = (state: State) => state.featureFlags;
export const getClientTags = (state: State) => state.tags.filter((t) => t.type === 'client');
export const getPermissionGroups = (state: State) => state.permissionGroups;
export const getDashboardWidgets = (state: State) => state.dashboardWidgets;
export const getPortalType = (state: State) => state.loggedInOrg ? state.loggedInOrg.type.toLowerCase() : null;

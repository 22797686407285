import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from './app.reducer';
import orderBy from 'lodash-es/orderBy';
import findIndex from 'lodash-es/findIndex';
import cloneDeep from 'lodash-es/cloneDeep';
import { DocumentTemplate } from '@ee/common/models';
import { FORM_DELETED, FORM_SAVED, FormActions, SET_CURRENT_FORM, SET_FORM_RESULTS } from '../actions/form.actions';

export interface FormState {
  formResults: DocumentTemplate[];
  currentForm: DocumentTemplate;
}

export interface State extends fromRoot.State {
  forms: FormState;
  currentForm: DocumentTemplate;
}

const initialState: FormState = {
  formResults: [],
  currentForm: null
};

export function formReducer(state = initialState, action: FormActions) {
  let results;
  let i;
  switch (action.type) {
    case SET_FORM_RESULTS:
      return {...state, formResults: orderBy(action.payload, ['name'], ['asc'])};
    case SET_CURRENT_FORM:
      return {...state, currentForm: action.payload};
    case FORM_SAVED:
      results = [...state.formResults];
      i = findIndex(results, {id: action.payload.id});
      if (i > -1) {
        results[i] = {...action.payload};
      } else {
        results.push({...action.payload});
      }
      return {...state, formResults: orderBy(results, ['name'], ['asc']), currentForm: action.payload};
    case FORM_DELETED:
      results = cloneDeep(state.formResults);
      i = findIndex(results, {id: action.payload});
      results.splice(i, 1);
      return {...state, formResults: results};
    default: {
      return state;
    }
  }
}

export const getFormState = createFeatureSelector<FormState>('form');

export const getFormResults = createSelector(
  getFormState,
  (state: FormState) => state.formResults
);

export const getCurrentForm = createSelector(
  getFormState,
  (state: FormState) => state.currentForm
);

import {Component, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {GenerateAttorneyOrganizationSignUpForm, GenerateUserRegistrationForm} from '@ee/common/forms';
import {AccountType, SubscriptionPlan} from '@ee/common/enums';
import {RegistrationModel} from '@ee/common/models';
import {PASSWORD_STRENGTH_REGEX} from '@ee/common/constants';
import {Subscription} from 'rxjs';
import {ShowAutoClosableAlert} from '../../core/actions/alert.actions';
import {ActivationService, OrganizationService, UserService} from '@ee/common/services';
import {AnalyticsService, Events, RegistrationProps, RegistrationSteps, RegistrationType} from '../../core/services/analytics.service';
import {ValidateInvitationCode} from '@ee/common/validators/validate-invitation-code.validator';

@Component({
  selector: 'ee-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnDestroy {
  form: UntypedFormGroup;
  subs: Subscription[] = [];
  isLoading = false;
  attorneySignedUp = false;
  accountTypes = AccountType;
  subscriptionPlans = SubscriptionPlan;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private store: Store,
    private activationService: ActivationService,
    private organizationService: OrganizationService,
    private userService: UserService,
    private analyticsService: AnalyticsService
  ) {
    this.form = fb.group({
      organization: GenerateAttorneyOrganizationSignUpForm(this.fb),
      user: GenerateUserRegistrationForm(this.fb, userService),
      password: ['', [Validators.required, Validators.pattern(PASSWORD_STRENGTH_REGEX)]],
      plan: [null, Validators.required],
      trial_length: [30, [Validators.required, Validators.min(0)]],
      type: [null, [Validators.required]],
      invitation_code: [
        '',
        {
          validators: [Validators.required],
          asyncValidators: [ValidateInvitationCode.createValidator(activationService)],
          updateOn: 'blur'
        }
      ],
      tos_policy_confirm: [false, Validators.requiredTrue]
    });

    this.subs.push(
      this.route.queryParams.subscribe(() => {
        // if (params.type === 'attorney') {
        this.form.get('type').setValue(AccountType.ATTORNEY);
        const plan = SubscriptionPlan.ENTERPRISE;
        // if (params.plan === 'starter') {
        //   plan = SubscriptionPlan.STARTER;
        // } else if (params.plan === 'enterprise') {
        //   plan = SubscriptionPlan.ENTERPRISE;
        // }
        this.form.get('plan').setValue(plan);
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  register(): void {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    const clientAndUser = this.form.value as RegistrationModel;
    clientAndUser.user.tos_confirm = true;
    clientAndUser.user.policy_confirm = true;

    // record event
    this.analyticsService.recordEvent(Events.REGISTRATION, {
      accountType: clientAndUser.type,
      type: RegistrationType.NEW_ORGANIZATION,
      step: RegistrationSteps.PLAN_SELECTION,
      plan: clientAndUser.plan
    } as RegistrationProps);

    this.subs.push(
      this.userService.register(clientAndUser).subscribe(response => {
        if (response.ok) {
          this.attorneySignedUp = true;
        } else {
          this.store.dispatch(
            new ShowAutoClosableAlert('Registration failed! Please try again or contact us for help.')
          );
        }
        this.isLoading = false;
      })
    );
  }

  openEmail(target: string): void {
    window.open(target, '_blank');
  }
}

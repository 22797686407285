import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

export const SHOW_AUTO_CLOSABLE_ALERT = '[Alert] Show auto closable alert';
export const SHOW_MANUAL_CLOSABLE_ALERT = '[Alert] Show manual closable alert';
export const SHOW_OFFLINE_ALERT = '[Alert] Show offline alert';
export const SHOW_ALERT_WITH_LINK = '[Alert] Show alert with link';
export const SHOW_ERROR_ALERT = '[Alert] Show Error Alert';

export class ShowAutoClosableAlert implements Action {
  readonly type = SHOW_AUTO_CLOSABLE_ALERT;

  constructor(public payload: string, public dismissLabel: string = 'Dismiss', public duration: number = 3000) {
  }
}

export class ShowManualClosableAlert implements Action {
  readonly type = SHOW_MANUAL_CLOSABLE_ALERT;

  constructor(public payload: string, public dismissLabel: string = 'Dismiss') {
  }
}

export class ShowOfflineAlert implements Action {
  readonly type = SHOW_OFFLINE_ALERT;

  constructor(public message: string, public dismissable: boolean = true, public autoClose: boolean) {
  }
}

export class ShowAlertWithLink implements Action {
  readonly type = SHOW_ALERT_WITH_LINK;

  constructor(public alert: string, public buttonText: string, public link: string) {
  }
}

export class ShowErrorAlert implements Action {
  readonly type = SHOW_ERROR_ALERT;

  constructor(public payload: HttpErrorResponse) {
  }
}

export type AlertActions =
  ShowAutoClosableAlert
  | ShowManualClosableAlert
  | ShowOfflineAlert
  | ShowAlertWithLink
  | ShowErrorAlert;

import { Action } from '@ngrx/store';

export const START_SAVING = '[UI] Start Saving';
export const STOP_SAVING = '[UI] Stop Saving';
export const LOADING = '[UI] Loading';

export class StartSaving implements Action {
  readonly type = START_SAVING;
}

export class StopSaving implements Action {
  readonly type = STOP_SAVING;
}

export class Loading implements Action {
  readonly type = LOADING;
  constructor(public loading: boolean) {}
}

export type UIActions = StartSaving | StopSaving | Loading;

import { Action } from '@ngrx/store';
import { Notification } from '@ee/common/models';

export const GET_UNREAD_NOTIFICATIONS = '[Notification] Get Unread Notifications';
export const LOAD_UNREAD_NOTIFICATIONS = '[Notification] Load Notifications';
export const GET_TOTAL_UNREAD_NOTIFICATION_COUNT = '[Notification] Get Total Unread Notification Count';
export const LOAD_TOTAL_UNREAD_NOTIFICATION_COUNT = '[Notification] Load Total Unread Notification Count';
export const MARK_NOTIFICATIONS_READ = '[Notification] Mark Notifications Read';

export class GetUnreadNotification implements Action {
  readonly type = GET_UNREAD_NOTIFICATIONS;
}

export class LoadUnreadNotification implements Action {
  readonly type = LOAD_UNREAD_NOTIFICATIONS;

  constructor(public payload: Notification[]) {
  }
}

export class GetTotalUnreadNotificationCount implements Action {
  readonly type = GET_TOTAL_UNREAD_NOTIFICATION_COUNT;
}

export class LoadTotalUnreadNotificationCount implements Action {
  readonly type = LOAD_TOTAL_UNREAD_NOTIFICATION_COUNT;

  constructor(public payload: number) {
  }
}

export class MarkNotificationRead implements Action {
  readonly type = MARK_NOTIFICATIONS_READ;

  constructor(public payload: string[]) {
  }
}

export type NotificationActions =
  GetUnreadNotification
  | LoadUnreadNotification
  | GetTotalUnreadNotificationCount
  | LoadTotalUnreadNotificationCount
  | MarkNotificationRead;

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AddressSuggestion, Organization, PaymentMethod, PlanModel, populateForm, SubscriptionModel} from '@ee/common/models';
import {UpdateSubscription} from '../../core/actions/billing.actions';
import {Store} from '@ngrx/store';
import * as fromApp from '../../core/reducers/app.reducer';
import {BillingService} from '@ee/common/services';
import {Observable, Subscription} from 'rxjs';
import {AccountType, SubscriptionPlan, SubscriptionStatus} from '@ee/common/enums';
import {MatStepper} from '@angular/material/stepper';
import {StartSaving} from '../../core/actions/ui.actions';
import {STATES} from '@ee/common/constants';
import {environment} from '../../../environments/environment';
import {ErrorStateMatcher} from '@angular/material/core';

@Component({
  selector: 'ee-order-summary',
  template: `
    <form [formGroup]="form" (ngSubmit)="purchase()">
      <div class="flex flex-col justify-start item">
        <div class="mat-h1">{{title}}</div>
        <div class="mat-h3">
          Below is a summary of today's order. To purchase your subscription click to select an
          existing payment source or add a new one below and complete the purchase.
        </div>
      </div>
      <div class="flex flex-row justify-between items-start">
        <div class="flex flex-col justify-stretch items-stretch flex-[1_1_45%]">
          <div class="flex flex-col flex-1 mr-[50px]" *ngIf="!selectedPlan.plan_id.toLowerCase().includes('free'); else freePlan">
            <div class="flex flex-row flex-wrap justify-start items-stretch mb-2" *ngIf="paymentMethods?.length">
              <div class="flat-card credit-card flex flex-col flex-auto md:flex-[1_0_100%] mr-2" *ngFor="let payment of paymentMethods"
                   (click)="onPaymentMethod(payment)"
                   [ngClass]="{ 'selected': payment === form.get('payment_method').value }">
                <div class="card-type">
                  <mat-icon fontSet="fab" [fontIcon]="'fa-cc-' + payment.brand.replace('_', '-')"></mat-icon>
                </div>
                <div>{{payment.card_number}}</div>
                <div>{{payment.expiration}}</div>
              </div>
              <div class="flat-card new-credit-card flex flex-col flex-auto md:flex-[1_0_100%]"
                   [ngClass]="{ 'selected': !form.get('payment_method').value || !form.get('payment_method').value.display }"
                   (click)="onPaymentMethod(null)">
                <div>Add new payment method.</div>
              </div>
            </div>
            <div class="payment-method-wrapper mb-2"
                 *ngIf="!form.get('payment_method').value || !form.get('payment_method').value.display">
              <ee-payment-method-form [stripeApiKey]="stripeApiKey" (paymentMethodSelected)="onPaymentMethod($event)">
              </ee-payment-method-form>
            </div>
            <div class="users-coupon mb-2">
              <mat-form-field class="users mr-4 md:mr-0 md:mb-4" *ngIf="selectedPlan.type === accountTypes.ATTORNEY">
                <mat-label>Users</mat-label>
                <mat-select [errorStateMatcher]="matcher" formControlName="users">
                  <mat-option *ngFor="let num of users" [value]="num">
                    {{num}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('users').hasError('min')">
                  Must specify more than the current number of active users.
                </mat-error>
                <mat-error *ngIf="form.get('users').hasError('notEqual')">
                  User count must be different when not changing plans.
                </mat-error>
              </mat-form-field>
              <mat-form-field class="flex-1 coupon">
                <mat-label>Coupon Code</mat-label>
                <input matInput formControlName="coupon"/>
                <mat-error *ngIf="form.get('coupon').hasError('couponInvalid')">
                  Invalid coupon code.
                </mat-error>
                <mat-error *ngIf="form.get('coupon').hasError('couponUsed')">
                  You have already redeemed this coupon code.
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <b>Billing Address</b>
              <div class="address flex flex-col justify-stretch items-stretch">
                <mat-form-field class="portal-remove-fixed-infix-width flex-1 mb-4">
                  <mat-label>Street Address</mat-label>
                  <input matInput formControlName="street_address_1">
                </mat-form-field>
                <mat-form-field class="portal-remove-fixed-infix-width flex-1 mb-4">
                  <mat-label>Street Address 2</mat-label>
                  <input matInput formControlName="street_address_2">
                </mat-form-field>
                <div class="city-state-zip">
                  <mat-form-field>
                    <mat-label>City</mat-label>
                    <input matInput formControlName="city" required>
                    <mat-error *ngIf="form.get('city').hasError('required')">
                      City is required.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>State</mat-label>
                    <mat-select formControlName="state" name="state" required>
                      <mat-option *ngFor="let state of states | keyvalue" [value]="state.key">
                        {{state.value}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('state').hasError('required')">
                      State is required.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>Zip Code</mat-label>
                    <input matInput mask="00000" formControlName="zip_code" required>
                    <mat-error *ngIf="form.get('zip_code').hasError('required')">
                      Zip code is required.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <ng-template #freePlan>
            <div class="no-payment-needed">No Payment Details Needed.</div>
          </ng-template>
        </div>
        <div class="flex flex-col justify-start items-start flex-[1_1_45%]" *ngIf="selectedPlan">
          <div class="flex flex-row mb-2">
            <div class="mat-h1">
              <strong class="red">{{selectedPlan.plan_name.toUpperCase()}}</strong> Plan
            </div>
          </div>
          <div class="flex flex-col justify-start items-start mb-2">
            <div class="flex flex-row justify-start items-center" *ngFor="let feature of selectedPlan.features">
              <mat-icon class="red" fontSet="fa" fontIcon="fa-check"></mat-icon>
              <div class="plan-feature" [innerHTML]="feature"></div>
            </div>
          </div>
          <mat-divider class="mb-2"></mat-divider>
          <div class="mb-2">
            <div class="flex flex-row justify-between items-center">
              <div class="mat-h2">Order Summary</div>
            </div>
            <div class="flex flex-row flex-wrap justify-between items-stretch">
              <div>
                <mat-label>Monthly Charges</mat-label>
                <h2>{{monthlyCharges | currency}}</h2>
              </div>
              <div *ngIf="selectedPlan.type === accountTypes.ATTORNEY && selectedPlan.monthly_included_evictions">
                <mat-label *ngIf="selectedPlan.monthly_included_evictions > 0">
                  After {{selectedPlan.monthly_included_evictions}} evictions
                </mat-label>
                <mat-label *ngIf="selectedPlan.monthly_included_evictions === 0">
                  Flat fee
                </mat-label>
                <h2>{{selectedPlan.price_per_eviction | currency}} / eviction</h2>
              </div>
              <div *ngIf="!freeTrialEligible">
                <mat-label><b>Prorated Amount Due Today</b></mat-label>
                <h2 class="align-right">
                  <b>{{totalDueToday | currency}}*
                    <span *ngIf="selectedPlan?.plan_id === currentOrg.selected_plan &&
                                 ((form.get('users').value ?? 0) - this.currentOrg?.subscription_user_count ?? 0) > 0">
                      (+{{((form.get('users').value ?? 0) - this.currentOrg?.subscription_user_count ?? 0)}} users)
                    </span>
                  </b>
                </h2>
              </div>
              <div *ngIf="freeTrialEligible">
                <mat-label><b>Amount Due Today</b></mat-label>
                <h2 class="align-right">
                  <b>{{0 | currency}}</b>
                </h2>
              </div>
            </div>
            <div>
              We bill plans on the first of the month.
              <span *ngIf="!freeTrialEligible; else freeActive">
                The amount due today is calculated as the prorated amount through the end of the
                    month minus an optional coupon.
              </span>
              <ng-template #freeActive>
                Your first bill will be prorated and may be less than the monthly charge above.
              </ng-template>

              <div *ngIf="currentOrg.subscription_user_count > (this.form.get('users').value ?? 0)">
                <b>You have selected less users than you currently have. Your account will be credited the amount
                  already paid for the dropped user(s), which will be applied to future monthly bills.</b>
              </div>

              <div>*Taxes may apply</div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="stepper; else noStepper" class="flex flex-row justify-between items-start">
        <button type="button" mat-button matStepperPrevious>Change Plan</button>
        <button type="submit" mat-flat-button color="accent"
                [disabled]="form.invalid || (isLoading | async)">
          Complete Purchase
        </button>
      </div>

      <ng-template #noStepper>
        <div class="flex flex-row justify-end items-start">
          <button type="submit" mat-flat-button color="accent"
                  [disabled]="form.invalid || (isLoading | async)">
            {{totalDueToday ? 'Complete Purchase' : 'Update Subscription'}}
          </button>
        </div>
      </ng-template>
    </form>
  `,
  styles: [`
    @import 'components/color-palette';
    @import 'responsive.mixin.scss';

    form {
      border: 1px solid rgb(204, 204, 204);
      border-radius: 10px;
      padding: 10px;
    }

    .red {
      color: $primary-red;
    }

    .flat-card {
      max-width: 160px;
      height: auto;

      &.credit-card {
        text-align: end;

        .card-type {
          font-size: 44px;

          .mat-icon {
            width: 100px;
            height: 42px;
          }
        }
      }

      &.new-credit-card {
        font-size: 18px;
      }
    }

    .users-coupon {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch;

      .users {
        margin-bottom: 1rem;
      }
      .coupon {
      }
    }

    @media screen and (min-width: $break-lg) {
      .users-coupon {
        flex-direction: row;
        justify-content: flex-start;
        align-items: start;

        .users {
          width: 350px;
          margin-bottom: 0;
          margin-right: 1rem;
        }
        .coupon {

        }
      }

      .city-state-zip {
        flex-direction: row;
        justify-content: stretch;
        mat-form-field {
          margin-right: 1rem;
          margin-bottom: 0;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .payment-method-wrapper {
      height: 136px;
    }

    .no-payment-needed {
      font-weight: 600;
      text-align: center;
      font-size: 1.25rem;
    }
  `],
  changeDetection: ChangeDetectionStrategy.Default
})
export class OrderSummaryComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public stepper: MatStepper;

  @Input()
    form: UntypedFormGroup;

  @Input()
    selectedPlan: PlanModel;

  @Input()
    subscriptionStatus: SubscriptionStatus;

  @Input()
    title = 'Checkout';

  @Input()
    freeTrialEligible = false;

  @Input()
    currentOrg: Organization;

  isLoading: Observable<boolean>;

  paymentMethods: PaymentMethod[];

  accountTypes = AccountType;

  subscriptionStatuses = SubscriptionStatus;

  matcher = new MyErrorStateMatcher();

  stripeApiKey = environment.stripe_api_key;
  monthlyCharges = 0;
  dueToday = 0;
  totalDueToday = 0;
  coupon: string;
  states = STATES;
  users = Array.from({ length: 20 }, (_, i) => i + 1);

  private subs: Subscription[] = [];

  constructor(private store: Store,
              private billingService: BillingService,
              private ref: ChangeDetectorRef) {
    this.isLoading = store.select(fromApp.getIsSaving);
  }

  ngOnInit() {
    if (this.stepper) {
      this.subs.push(this.stepper.selectionChange.subscribe(() => {
        // reset select payment method and coupon codes
        this.form.get('payment_method').setValue(null);
        this.form.get('coupon').setValue(null);
        this.form.get('users').setValue(1);
        this.monthlyCharges = this.selectedPlan.minimum_monthly_price * this.form.get('users').value;
      }));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.selectedPlan?.plan_id?.indexOf('FREE') < 0) {
      this.monthlyCharges = this.selectedPlan.minimum_monthly_price * this.form.get('users').value;

      this.subs.push(this.billingService.getPaymentMethods().subscribe((paymentMethods) => {
        this.paymentMethods = paymentMethods;

        if (paymentMethods.length) {
          this.form.get('payment_method').setValue(paymentMethods[0]);
        }
        this.ref.detectChanges();
      }));


      this.recalculateTotal(this.selectedPlan.plan_id);

      // subscribe to coupon changes and apply automatically to the balance
      this.subs.push(this.form.get('coupon').statusChanges.subscribe((status) => {
        if (this.form.get('coupon').value !== this.coupon && status !== 'PENDING') {
          this.coupon = this.form.get('coupon').value;
          if (status === 'VALID') {
            this.recalculateTotal(this.selectedPlan.plan_id, this.form.get('users').value, this.form.get('coupon').value);
          } else {
            this.recalculateTotal(changes.selectedPlan.currentValue.plan_id, this.form.get('users').value);
          }
        }
      }));

      this.subs.push(this.form.get('users').valueChanges.subscribe((users: number) => {
        if (this.form.get('coupon').valid) {
          this.recalculateTotal(this.selectedPlan.plan_id, users, this.form.get('coupon').value);
        } else {
          this.recalculateTotal(changes.selectedPlan.currentValue.plan_id, users);
        }
      }));
    }
  }

  private recalculateTotal(plan: SubscriptionPlan, userCount = 1, coupon: string = null) {
    let userCountChange = userCount;
    if (this.currentOrg.status === SubscriptionStatus.SUBSCRIPTION_ACTIVE) {
      userCountChange = userCount - (this.currentOrg?.subscription_user_count ?? 0);
    }
    this.monthlyCharges = this.selectedPlan.minimum_monthly_price * userCount;
    if (userCountChange > 0) {
      this.subs.push(this.billingService.calculateProratedAmount(plan, userCountChange, coupon)
        .subscribe((amount: number) => {
          this.totalDueToday = amount / 100;
        }));
    } else {
      this.totalDueToday = 0;
    }
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  onPaymentMethod(paymentMethod: PaymentMethod) {
    this.form.get('payment_method').setValue(paymentMethod);
  }

  purchase() {
    const subscription = this.form.value as SubscriptionModel;
    this.store.dispatch(new StartSaving());
    this.store.dispatch(new UpdateSubscription(subscription));
  }

  onAddressSelected(suggestion: AddressSuggestion) {
    populateForm(this.form, suggestion);
  }

}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null): boolean {
    return (control && control.invalid);
  }
}

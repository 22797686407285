export const TTYPES = {
  MESSAGE: 0,
  NOTE: 1,
  ATTACHMENT: 2,
  BILLING: 3,
  UPDATES: 4,
  STEP_COMPLETED: 5,
  SCHEDULED_EMAILS: 6,
  TASK_HISTORY: 7
};

import {Action, ActionReducer, ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromUi from '../reducers/ui.reducer';
import * as fromAuth from '../reducers/auth.reducer';
import * as fromClient from './organization.reducer';
import {LOGOUT, NOT_AUTHENTICATED} from '../actions/auth.actions';
import * as fromRouter from '@ngrx/router-store';
import {routerReducer, RouterReducerState} from '@ngrx/router-store';
import {environment} from '../../../environments/environment';
import {storageMetaReducer} from './storage.metareducer';
import {SessionStorageService} from 'ngx-webstorage';


export interface State {
  ui: fromUi.State;
  auth: fromAuth.State;
  client: fromClient.State;
  router: RouterReducerState;
}

export const reducers: ActionReducerMap<State> = {
  ui: fromUi.uiReducer,
  auth: fromAuth.authReducer,
  client: fromClient.organizationReducer,
  router: routerReducer
};

export function logout(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: Action): State => {
    if (action.type === NOT_AUTHENTICATED || action.type === LOGOUT) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: any): State => {
    console.log('state', state);
    console.log('action', action);

    return reducer(state, action);
  };
}

export function getMetaReducers(saveKeys: string[],
  localStorageKey: string,
  storageService: SessionStorageService): any {
  const metaReducers: any[] = [];
  metaReducers.push(storageMetaReducer(saveKeys, localStorageKey, storageService));
  metaReducers.push(logout);
  if (!environment.production) {
    metaReducers.push(logger);
  }
  return metaReducers;
}

export const getUiState = createFeatureSelector<fromUi.State>('ui');
export const getAuthState = createFeatureSelector<fromAuth.State>('auth');
export const getClientState = createFeatureSelector<fromClient.State>('client');
export const selectRouter = createFeatureSelector<fromRouter.RouterReducerState<any>>('router');
export const getIsSaving = createSelector(
  getUiState,
  fromUi.getIsSaving
);
export const getIsLoading = createSelector(
  getUiState,
  fromUi.getIsLoading
);
export const getIsAuth = createSelector(
  getAuthState,
  fromAuth.getIsAuth
);
export const getIsActivated = createSelector(
  getAuthState,
  fromAuth.getIsActivated
);
export const getLoggedInUser = createSelector(
  getAuthState,
  fromAuth.getLoggedInUser
);
export const getCurrentEvictionClient = createSelector(
  getClientState,
  fromClient.getCurrentEvictionClient
);
export const getLoggedInOrg = createSelector(
  getAuthState,
  fromAuth.getLoggedInOrg
);
export const getClientTags = createSelector(
  getAuthState,
  fromAuth.getClientTags
);
export const getCurrentPlan = createSelector(
  getAuthState,
  fromAuth.getCurrentPlan
);
export const getPortalType = createSelector(
  getAuthState,
  fromAuth.getPortalType
);

export const getAuthLoading = createSelector(
  getAuthState,
  fromAuth.getIsLoading
);

export const getDashboardWidgets = createSelector(
  getAuthState,
  fromAuth.getDashboardWidgets
);

export const getAppSettings = createSelector(
  getAuthState,
  fromAuth.getAppSettings
);

export const getCaseSettings = createSelector(
  getAuthState,
  fromAuth.getCaseSettings
);

export const getAttorneyClientSettings = createSelector(
  getAuthState,
  fromAuth.getAttorneyClientSettings
);

export const getPermissionGroups = createSelector(
  getAuthState,
  fromAuth.getPermissionGroups
);

export const getScraMonthlyLimit = createSelector(
  getAuthState,
  fromAuth.getScraMonthlyLimit
);

export const getFeatureFlags = createSelector(
  getAuthState,
  fromAuth.getFeatureFlags
);

export const {
  selectQueryParams,    // select the current route query params
  selectQueryParam,     // factory function to select a query param
  selectRouteParams,    // select the current route params
  selectRouteParam,     // factory function to select a route param
  selectRouteData,      // select the current route data
  selectUrl,            // select the current url
} = fromRouter.getRouterSelectors(selectRouter);

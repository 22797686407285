import {TagifySettings} from 'ngx-tagify';
import {Tag, TagColor, TagType} from '@ee/common/models';

export const TAG_COLORS: { [K in TagColor]: string } = {
  red: 'rgba(213,0,0,1)',
  green: 'rgba(0,170,0,1)',
  blue: 'rgba(69,87,187,1)',
  yellow: 'rgba(186,169,63,1)',
  orange: 'rgba(255,165,0,1)',
  darkorange: 'rgba(213, 96, 0,1)',
  purple: 'rgba(132,84,253,1)',
  teal: 'rgba(0,128,128,1)',
  black: 'rgba(0,0,0,1)',
  gray: 'rgba(66,66,66,1)',
  lightgray: 'rgba(140,140,140,1)'
};

export function generateTagColorToStyle(color: TagColor): string {
  const textColor = TAG_COLORS[color] ?? TAG_COLORS['blue'];
  const bgColor = textColor.replace(',1)', ',.15)');
  return `--tag-bg:${bgColor}; --tag-text-color:${textColor}; ` +
    `--tag-remove-btn-color:${textColor}; --tag-hover:${bgColor}`;
}

export function generateTagifySettings(type: TagType, change: (a: CustomEvent<Tagify.ChangeEventData>) => void): TagifySettings {
  return {
    placeholder: `Tag this ${type}`,
    editTags: false,
    dropdown: {
      closeOnSelect: false,
      enabled: 0,
      maxItems: 100
    },
    whitelist: [],
    skipInvalid: true,
    enforceWhitelist: false,
    userInput: false,
    duplicates: false,
    transformTag: (tagData: Tag) => {
      tagData.style = generateTagColorToStyle(tagData.color);
    },
    callbacks: {
      change
    },
    templates: {
      tag(tagData) {
        return `
          <tag contenteditable='false'
                spellcheck='false'
                tabIndex="-1"
                class="tagify__tag"
                ${this.getAttributes(tagData)}>
            <x title='' class='tagify__tag__removeBtn' role='button' aria-label='remove tag'></x>
            <div>
                <span class='tagify__tag-text'>${tagData.name}</span>
            </div>
        </tag>
        `;
      },
      dropdownItem(tagData) {
        return `
          <div ${this.getAttributes(tagData)}
            class='tagify__dropdown__item'
            tabindex="0"
            tagifySuggestionIdx="${tagData.value}"
            role="option">
            ${tagData.name}
        </div>
        `;
      }
    }
  };
}


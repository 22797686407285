import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {Notification} from '@ee/common/models';
import {IServerSideGetRowsRequest} from '@ag-grid-community/core';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  getRecentNotifications(): Observable<Notification[]> {
    return this.http.get<Notification[]>(`${this.environment.api_prefix}api/notifications`);
  }

  searchNotifications(request: IServerSideGetRowsRequest): Observable<Notification[]> {
    return this.http.post<Notification[]>(`${this.environment.api_prefix}api/notifications/search`, request);
  }

  getUnreadCount(): Observable<number> {
    return this.http.get<number>(`${this.environment.api_prefix}api/notifications/count`);
  }

  markRead(notificationIds: string[]): Observable<Notification[]> {
    return this.http.put<Notification[]>(`${this.environment.api_prefix}api/notifications`, notificationIds);
  }
}

import { Action } from '@ngrx/store';
import { SearchResults } from '@ee/common/models';

export const APP_SEARCH = '[App] Search';
export const SEARCH_SUGGESTION = '[App] Search Suggestion';

export class AppSearch implements Action {
  readonly type = APP_SEARCH;

  constructor(public payload: string) {
  }
}

export class AppSearchSuggestion implements Action {
  readonly type = SEARCH_SUGGESTION;

  constructor(public payload: SearchResults) {
  }

}

export type AppSearchActions = AppSearch | AppSearchSuggestion;

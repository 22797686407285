import { DateTime } from 'luxon';

export function notificationTimeSince(notificationDate: DateTime): string {
  const duration = DateTime.local().diff(notificationDate);
  const durationInHours = duration.as('hours');
  const durationInMinutes = duration.as('minutes');

  let result;
  if (durationInMinutes < 20) {
    result = 'Just now';
  } else if (durationInMinutes < 60) {
    result = `${Math.trunc(durationInMinutes)} mins ago`;
  } else if (durationInHours < 24) {
    result = `${Math.trunc(durationInHours)} hours ago`;
  } else if (durationInHours > 24) {
    result = `${Math.trunc(duration.as('days'))} days ago`;
  }
  return result;
}

import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Store} from '@ngrx/store';
import {tap} from 'rxjs/operators';
import {StartSaving, StopSaving} from '../actions/ui.actions';
import {DateTime} from 'luxon';
import {AuthService as Auth0Service, } from '@auth0/auth0-angular';

@Injectable()
export class SavingInterceptor implements HttpInterceptor, OnDestroy {

  private subs: Subscription[] = [];

  constructor(private store: Store,
              private auth0Service: Auth0Service) {
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      !request ||
      !request.url ||
      (/^http/.test(request.url) && !(environment.api_prefix && request.url.startsWith(environment.api_prefix)))
    ) {
      return next.handle(request);
    }

    if (request.method !== 'GET') {
      this.store.dispatch(new StartSaving());
    }

    // include timezone with all requests
    let headers = request.headers;
    headers = headers.set('X-Timezone-Name', DateTime.local().zoneName);

    return next.handle(request.clone({headers})).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.store.dispatch(new StopSaving());
          }
        },
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            this.store.dispatch(new StopSaving());
            if (err.status === 401) {
              this.subs.push(this.auth0Service.isAuthenticated$.subscribe({
                next: (isAuthenticated: boolean) => {
                  if (!isAuthenticated) {
                    this.auth0Service.logout();
                  }
                }, error: () => {
                  this.auth0Service.logout();
                }
              }));
            }
          }
        }
      })
    );
  }
}

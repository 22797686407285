import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  getAttorneyFeatureFlags(): Observable<{string: boolean}> {
    return this.http.get<{string: boolean}>(`${this.environment.api_prefix}api/feature-flags/attorney`);
  }

  updateAttorneyFeatureFlag(feature: string, enabled: boolean): Observable<boolean> {
    return this.http.put<boolean>(`${this.environment.api_prefix}api/feature-flags/attorney/${feature}`, enabled);
  }

}

// code adapted from https://coryrylan.com/blog/creating-a-dynamic-checkbox-list-in-angular

import {UntypedFormArray, ValidatorFn} from '@angular/forms';

export class MinSelectedCheckboxesValidator {
  static createValidator(min = 1) {
    const validator: ValidatorFn = (formArray: UntypedFormArray) => {
      const totalSelected = formArray.controls
      // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);

      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }
}

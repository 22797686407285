import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {Organization} from '@ee/common/models';

@Injectable({ providedIn: 'root' })
export class PropertyManagerService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  getPropertyManagers(): Observable<Organization[]> {
    return this.http.get<Organization[]>(`${this.environment.api_prefix}api/property-managers`);
  }

  invitePropertyManager(organization: Organization): Observable<Organization> {
    return this.http.put<Organization>(`${this.environment.api_prefix}api/clients`, organization);
  }

  deletePropertyManager(organization: Organization): Observable<Organization> {
    return this.http.delete<Organization>(`${this.environment.api_prefix}api/property-managers/${organization.id}`);
  }
}

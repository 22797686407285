import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Organization, PlanModel} from '@ee/common/models';
import {MatStepper} from '@angular/material/stepper';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {AccountType, SubscriptionPlan, SubscriptionStatus} from '@ee/common/enums';
import { HttpClient } from '@angular/common/http';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'ee-property-manager-subscription',
  template: `
    <div class="card property-manager-subscription-wrapper flex-[1_1_1200px]">
      <div>
        <ee-order-summary *ngIf="selectedPlan" [form]="form" [selectedPlan]="selectedPlan"
                          [subscriptionStatus]="loggedInOrg?.status" [freeTrialEligible]="!this.loggedInOrg?.trial_used"
                          [title]="title" [currentOrg]="loggedInOrg"></ee-order-summary>
      </div>
    </div>
  `,
  styles: [`
    @import 'components/color-palette';

    .property-manager-subscription-wrapper {
      padding: 20px;
    }

    .plan-feature {
      line-height: 17px;
      margin: 5px 0 !important;
    }

    .red {
      color: $primary-red;
    }

    .back-button {
      position: fixed;
      top: 1.5rem;
      left: 1.5rem;
      text-align: right;
      color: $c-gray-50;
      width: 120px;

      .mdc-icon-button {
        font-size: 1rem;
        width: 120px;
      }
    }
  `]
})
export class PropertyManagerSubscriptionComponent implements OnInit, OnDestroy {

  @Input()
    plans: PlanModel[] | undefined;

  @Input()
    loggedInOrg: Organization | undefined;

  @Input()
    form: UntypedFormGroup | undefined;

  selectedPlan: PlanModel | undefined;

  @ViewChild('stepper')
  private stepper: MatStepper | undefined;

  accountType = AccountType;

  title: string | undefined;

  isLoading = false;

  private subscription: Subscription = new Subscription();

  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private store: Store,
              private httpClient: HttpClient, private location: Location, private router: Router) {
  }

  ngOnInit(): void {
    this.selectedPlan = this.plans?.find((p) => {
      if (this.loggedInOrg?.status === SubscriptionStatus.SUBSCRIPTION_TRIAL_ACTIVE && !this.loggedInOrg?.payment_method_attached) {
        return p.plan_id === this.loggedInOrg?.selected_plan;
      } else {
        return p.plan_id !== this.loggedInOrg?.selected_plan;
      }
    });
    this.title = this.selectedPlan?.minimum_monthly_price ?
      `Upgrade Plan${this.loggedInOrg?.trial_used ? '' : ' w/ 14 Day Free Trial!'}` : 'Downgrade Plan';
    this.form?.get('plan')?.setValue(this.selectedPlan?.plan_id);
    if (this.selectedPlan?.plan_id === SubscriptionPlan.CLIENT_FREE) {
      this.form?.removeControl('payment_method');
      this.form?.removeControl('coupon');
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  goBack() {
    this.router.navigate(['dashboard']);
  }

}

import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UpgradeDialogComponent } from '../../shared-components/upgrade-dialog/upgrade-dialog.component';
import { UpgradeDialogDetails } from '@ee/common/models';
import { firstValueFrom, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AccountType } from '@ee/common/enums';
import { ShowAutoClosableAlert } from '../actions/alert.actions';
import { Store } from '@ngrx/store';
import * as fromRoot from '../reducers/app.reducer';

@Injectable({ providedIn: 'root' })
export class UpgradeSubscriptionService implements OnDestroy {
  subs: Subscription[] = [];

  constructor(private dialog: MatDialog, private router: Router, private store: Store) {}

  async showUpgradeDialog(url: string, type: AccountType) {
    let data: UpgradeDialogDetails = null;

    if (url.startsWith('/reports')) {
      data = DIALOG_DETAILS.REPORT;
    } else if (type === AccountType.CLIENT) {
      if (url.startsWith('/admin/users')) {
        data = DIALOG_DETAILS.USERS_PM;
      } else if (url.startsWith('/admin/plugins/law-pay')) {
        data = DIALOG_DETAILS.ONLINE_PAYMENTS_PM;
      } else if (url.startsWith('/admin/document-templates')) {
        data = DIALOG_DETAILS.DOCUMENT_TEMPLATES;
      }
    }

    if (data) {
      data.user = await firstValueFrom(this.store.select(fromRoot.getLoggedInUser));
      const dialogRef = this.dialog.open(UpgradeDialogComponent, { data, maxWidth: '750px' });

      this.subs.push(
        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.router.navigate(['/plan']);
          } else {
            this.router.navigate(['/dashboard']);
          }
        })
      );
    } else {
      this.store.dispatch(new ShowAutoClosableAlert('Please upgrade to a PRO account to access this feature.'));
    }
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}

export const DIALOG_DETAILS = {
  REPORT: {
    title: 'EasyEviction Pro: Upgrade for Powerful Reports',
    // image_path: '/assets/images/features/report.png',
    // inner_html: '<p class="align-center">testing!!!</p>',
    features: [
      'Generate unlimited eviction and invoice reports',
      'Customize your reports with powerful filters including start/end dates, custom tags, customizable columns, and more',
      'Export to Excel',
      'Coming soon: save your report filters',
      'Coming soon: schedule reports to be emailed on a regular basis'
    ]
  },
  USERS_PM: {
    title: 'EasyEviction Pro: Upgrade for Unlimited Users',
    features: [
      'Add an unlimited number of users to your account',
      'Coming soon: restrict user access to only evictions within their region'
    ]
  },
  ONLINE_PAYMENTS_PM: {
    title: 'EasyEviction Pro: Upgrade to Pay Online',
    features: [
      'Save time and mail costs by paying your invoices online',
      'Pay for invoices directly from your bank account using ACH',
      'Rest assured that your payments and payment information are safely stored by our licensed third-party processor'
    ]
  },
  DOCUMENT_TEMPLATES: {
    title: 'EasyEviction Pro: Upgrade to Auto-Fill Document Templates',
    features: [
      'Quickly set up word documents to be auto-filled with details from your evictions',
      'Word too hard? You can create a custom document using our fancy editor with field auto-completions',
      'Talk to us about setting up PDF documents to be auto-filled'
    ]
  }
};

import { Route } from '@angular/router';
import { AuthorizeComponent } from './authorize.component';
import { LoginComponent } from './login.component';

export const authorizeRoute: Route = {
  path: 'authorize',
  component: AuthorizeComponent,
  data: {
    pageTitle: 'Login'
  }
};

export const loginRoute: Route = {
  path: 'login',
  component: LoginComponent,
  data: {
    pageTitle: 'Login'
  }
};

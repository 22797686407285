<div class="portal-compact-sidemenu h-full w-full flex flex-col">
  <mat-toolbar class="portal-side-menu-top-toolbar"
               tooltip="Dashboard" placement="right">
    <img *ngIf="customLogoUrl && false; else defaultLogo" (click)="logoClicked.emit()"
         id="custom-logo" class="attorney-logo" [src]="customLogoUrl" alt="Dashboard">
    <ng-template #defaultLogo>
      <img [src]="'/assets/images/logo-white-door.png'" (click)="logoClicked.emit()"
           class="attorney-logo" alt="EasyEviction">
    </ng-template>
  </mat-toolbar>
  <div class="portal-sidnav-menu flex justify-stretch scrollbar-hide overflow-auto h-full mt-4">
    <ng-content></ng-content>
  </div>
</div>

<portal-layout-compact>
  <div class="portal-compact-sidemenu tour-portal flex flex-col justify-stretch items-stretch flex-1" sidenav *ngIf="org">
    <button mat-icon-button class="nav-button nav-option-tasks" routerLink="/tasks" [disableRipple]="true" [hideDelay]="0"
            #cliRla="routerLinkActive" routerLinkActive="nav-button-active" tooltip="Tasks" placement="right"
            [matBadge]="taskCount" matBadgeColor="accent" matBadgePosition="below after" matBadgeSize="medium"
            [matBadgeHidden]="taskCount === 0" type="button" [matBadgeOverlap]="true"
            *hasPermission="[permission.TASKS_GENERAL_VIEW, permission.CASE_TASKS_VIEW]; op: 'OR'; addlCondition: org.type === accountType.ATTORNEY">
      <mat-icon [fontSet]="cliRla.isActive ? 'fa' : 'far'" fontIcon="fa-list-check" inline></mat-icon>
    </button>
    <button mat-icon-button class="nav-button nav-option-daily-docket" [disableRipple]="true" routerLink="/daily-docket" [hideDelay]="0"
            #ddRla="routerLinkActive" routerLinkActive="nav-button-active" tooltip="Daily Docket" placement="right" type="button"
            *hasPermission="permission.DAILY_DOCKET_GENERAL_VIEW; addlCondition: org.type === orgTypes.ATTORNEY && currentPlan !== plans.STARTER">
      <mat-icon [fontSet]="ddRla.isActive ? 'fa' : 'far'" fontIcon="fa-gavel" inline></mat-icon>
    </button>
    <button mat-icon-button class="nav-button tour-eviction-inbox-link nav-option-eviction-inbox" [disableRipple]="true" routerLink="/inbox"
            #inbRla="routerLinkActive" routerLinkActive="nav-button-active" tooltip="Eviction Inbox" placement="right"
            [matBadge]="intakeEvictionCount" matBadgeColor="accent" matBadgePosition="below after" matBadgeSize="medium"
            [matBadgeHidden]="intakeEvictionCount === 0" [hideDelay]="0" type="button" [matBadgeOverlap]="true"
            *hasPermission="permission.INBOX_GENERAL_VIEW; addlCondition: org.type === accountType.ATTORNEY">
      <mat-icon [fontSet]="inbRla.isActive ? 'fa' : 'far'" fontIcon="fa-inbox" inline></mat-icon>
    </button>
    <!--    <button mat-icon-button class="nav-button tour-eviction-launchpad-link nav-option-launchpad" [disableRipple]="true" routerLink="/launchpad"-->
    <!--            #inbRla="routerLinkActive" routerLinkActive="nav-button-active" tooltip="Launchpad" placement="right"-->
    <!--            [hideDelay]="0" *ngIf="org.type === orgTypes.PROPERTY_MANAGER" type="button">-->
    <!--      <mat-icon [fontSet]="inbRla.isActive ? 'fa' : 'far'" fontIcon="fa-rocket-launch" inline></mat-icon>-->
    <!--    </button>-->
    <button mat-icon-button class="nav-button tour-evictions-link nav-option-cases" [disableRipple]="true" routerLink="/properties"
            #evicRla="routerLinkActive" routerLinkActive="nav-button-active" tooltip="Properties" placement="right"
            [hideDelay]="0" *ngIf="org.type === orgTypes.PROPERTY_MANAGER" type="button">
      <mat-icon [fontSet]="evicRla.isActive ? 'fa' : 'far'" fontIcon="fa-buildings" inline></mat-icon>
    </button>
    <button mat-icon-button class="nav-button tour-evictions-link nav-option-cases" [disableRipple]="true" routerLink="/evictions/open"
            #evicRla="routerLinkActive" routerLinkActive="nav-button-active" tooltip="Open Evictions" placement="right" [hideDelay]="0"
            *hasPermission="permission.CASE_GENERAL_VIEW" type="button">
      <mat-icon [fontSet]="evicRla.isActive ? 'fa' : 'far'" fontIcon="fa-briefcase" inline></mat-icon>
    </button>
    <button mat-icon-button class="nav-button tour-eviction-inbox-link nav-option-pending-evictions"
            [disableRipple]="true" routerLink="/evictions/pending-review"
            #inbRla="routerLinkActive" routerLinkActive="nav-button-active" tooltip="Evictions Pending Review" placement="right" type="button"
            *hasPermission="permission.CASE_GENERAL_CREATE || permission.SUBMITTED_CASES_GENERAL_VIEW; addlCondition: org.type === accountType.CLIENT">
      <mat-icon [fontSet]="inbRla.isActive ? 'fa' : 'far'" fontIcon="fa-inbox-full" inline></mat-icon>
    </button>
    <button mat-icon-button class="nav-button nav-option-closed-evictions" routerLink="/evictions/closed" [disableRipple]="true"
            #cloRla="routerLinkActive" routerLinkActive="nav-button-active" type="button" tooltip="Closed Evictions" placement="right" [hideDelay]="0"
            *hasPermission="permission.CASE_GENERAL_VIEW; addlCondition: org.type !== orgTypes.ATTORNEY">
      <mat-icon [fontSet]="cloRla.isActive ? 'fa' : 'far'" fontIcon="fa-archive" inline></mat-icon>
    </button>
    <button mat-icon-button class="nav-button tour-messages-link nav-option-messages" routerLink="/messages" [disableRipple]="true" [hideDelay]="0"
            #msgRla="routerLinkActive" routerLinkActive="nav-button-active"
            [tooltip]="!!unreadMessageCount ? 'Messages&nbsp;&nbsp;(' + unreadMessageCount + ')' : 'Messages'" placement="right"
            [matBadge]="unreadMessageCount" matBadgeColor="accent" matBadgePosition="below after"
            [matBadgeHidden]="!unreadMessageCount" type="button"
            *hasPermission="[permission.CASE_MESSAGES_VIEW, permission.CLIENT_MESSAGES_VIEW]; op: 'OR'; addlCondition: org.type !== orgTypes.PROPERTY_MANAGER">
      <mat-icon [fontSet]="msgRla.isActive ? 'fa' : 'far'" fontIcon="fa-comment-alt" inline></mat-icon>
    </button>
    <button mat-icon-button class="nav-button tour-clients-link nav-option-clients" routerLink="/clients" [disableRipple]="true" [hideDelay]="0"
            #cliRla="routerLinkActive" routerLinkActive="nav-button-active" tooltip="Clients" placement="right" type="button"
            *hasPermission="[permission.CLIENT_GENERAL_VIEW, permission.PROPERTY_MANAGERS_GENERAL_VIEW]; op: 'OR'; addlCondition: org.type === orgTypes.ATTORNEY">
      <mat-icon [fontSet]="cliRla.isActive ? 'fa' : 'far'" fontIcon="fa-address-book" inline></mat-icon>
    </button>
    <button mat-icon-button class="nav-button tour-invoices-link nav-option-invoices" routerLink="/invoices" [disableRipple]="true" [hideDelay]="0"
            #invRla="routerLinkActive" routerLinkActive="nav-button-active" tooltip="Invoices" placement="right" type="button"
            *hasPermission="permission.INVOICE_GENERAL_VIEW; addlCondition: org.type !== orgTypes.PROPERTY_MANAGER">
      <mat-icon [fontSet]="invRla.isActive ? 'fa' : 'far'" fontIcon="fa-file-invoice-dollar" inline></mat-icon>
    </button>
    <button mat-icon-button class="nav-button pro nav-option-reports" [disableRipple]="true" [hideDelay]="0" type="button"
            #repRla="routerLinkActive" routerLinkActive="nav-button-active" tooltip="Reports" placement="right"
            *hasPermission="[permission.REPORTS_EVICTION_VIEW, permission.REPORTS_TASK_VIEW, permission.REPORTS_INVOICE_VIEW,
            permission.REPORTS_BILLABLE_ITEM_VIEW, permission.REPORTS_STATEMENT_VIEW];
                            op: 'OR'; addlCondition: org.type !== orgTypes.CLIENT"
            [routerLink]="org.type === orgTypes.ATTORNEY ? '/reports' : '/reports/case'">
      <mat-icon [fontSet]="repRla.isActive ? 'fa' : 'far'" fontIcon="fa-file-chart-line" inline></mat-icon>
    </button>
    <button mat-icon-button class="nav-button nav-option-user-settings" routerLink="/user-settings" [disableRipple]="true" [hideDelay]="0"
            #accRla="routerLinkActive" routerLinkActive="nav-button-active" tooltip="User Settings" placement="right"
            [matBadge]="dashboardWidgets?.account_invite_count" matBadgeColor="accent" matBadgePosition="below after"
            [matBadgeHidden]="!dashboardWidgets?.account_invite_count" type="button">
      <mat-icon [fontSet]="accRla.isActive ? 'fa' : 'far'" fontIcon="fa-user-circle" inline></mat-icon>
    </button>
    <button *ngIf="isAdmin" mat-icon-button class="nav-button nav-option-admin-settings" routerLink="/admin" [disableRipple]="true" [hideDelay]="0"
            #admRla="routerLinkActive" routerLinkActive="nav-button-active" tooltip="Admin Settings" placement="right" type="button">
      <mat-icon [fontSet]="admRla.isActive ? 'fa' : 'far'" fontIcon="fa-cog" inline></mat-icon>
    </button>
    <button id="support-button" mat-icon-button class="nav-button nav-option-support" *ngIf="org.type === orgTypes.ATTORNEY"
            [disableRipple]="true" [hideDelay]="0" tooltip="Support" placement="right" type="button" (click)="openSupport()">
      <mat-icon fontSet="fa" fontIcon="fa-comment-question" inline></mat-icon>
    </button>
    <div class="flex-1"></div>
    <button id="referral" mat-icon-button class="nav-button nav-option-referral self-end justify-end align-bottom"
            [disableRipple]="true" [hideDelay]="0" tooltip="Referral Program" placement="right" type="button" (click)="openReferralDialog()">
      <mat-icon fontSet="fa" fontIcon="fa-gift" inline></mat-icon>
    </button>
  </div>
  <div content class="h-full w-full flex flex-col">
    <ee-subscription-banner *ngIf="org?.type === orgTypes.ATTORNEY" [org]="org"></ee-subscription-banner>
    <router-outlet class="router-flex-full-height no-scroll-y"></router-outlet>
  </div>
</portal-layout-compact>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivationService } from '@ee/common/services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { STATES } from '@ee/common/constants';
import { AddressSuggestion, Organization, populateForm } from '@ee/common/models';
import { AccountType } from '@ee/common/enums';
import { combineLatest, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AnalyticsService,
  Events,
  RegistrationProps,
  RegistrationSteps,
  RegistrationType
} from '../../../core/services/analytics.service';

@Component({
  selector: 'ee-activate-property-manager',
  templateUrl: './activate-property-manager.component.html',
  styleUrls: ['./activate-property-manager.component.scss']
})
export class ActivatePropertyManagerComponent implements OnInit, OnDestroy {
  activationKeyForm: UntypedFormGroup;
  companyForm: UntypedFormGroup;
  states = STATES;
  nameOfUser = undefined;
  isLoading: boolean;
  private subs = new Subscription();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activationService: ActivationService,
    private router: Router,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private analyticsService: AnalyticsService
  ) {
    this.activationKeyForm = this.formBuilder.group({
      key: [null, Validators.required]
    });
    this.companyForm = this.formBuilder.group({
      street_address_1: ['', Validators.required],
      street_address_2: [null],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip_code: ['', Validators.required],
      lat: [null],
      lng: [null],
      main_phone_number: ['', Validators.required],
      logo: [null]
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.subs.add(
      this.route.queryParams.subscribe(params => {
        if (params.key) {
          this.activationKeyForm.get('key').setValue(params.key);
        }
      })
    );
    this.subs.add(
      combineLatest([this.route.queryParams]).subscribe(([params]) => {
        if (params && params.key) {
          const activationKey = params.key;
          if (activationKey && !this.nameOfUser) {
            this.activationKeyForm.get('key').setValue(activationKey);
            this.subs.add(
              this.activationService.getByUserActivationKey(activationKey).subscribe({
                next: details => {
                  if (details.account_type !== AccountType.CLIENT) {
                    this.router.navigate(['/authorize']);
                    this.snackbar.open('Invalid request. Please try again.', 'Dismiss', { duration: 3000 });
                  }
                  this.nameOfUser = details.first_name;
                  this.isLoading = false;
                },
                error: error => {
                  this.isLoading = false;
                  this.snackbar.open('Invalid request.', 'Dismiss', { duration: 3000 });
                  this.router.navigate(['/authorize']);
                  console.log(error);
                }
              })
            );
          }
        }
      })
    );
  }

  onAddressSelected(result: AddressSuggestion) {
    populateForm(this.companyForm, result);
  }

  submit() {
    const organization = this.companyForm.value as Organization;
    const activationKey = this.activationKeyForm.value.key;
    organization.logo = this.companyForm.value.logo;

    // record event
    this.analyticsService.recordEvent(Events.REGISTRATION, {
      accountType: organization.type,
      type: RegistrationType.NEW_ORGANIZATION,
      step: RegistrationSteps.CONFIRMATION
    } as RegistrationProps);

    this.subs.add(
      this.activationService.completeRegistration(organization, activationKey).subscribe({
        next: () => {
          this.snackbar.open('Your account is now active! Please log in.', 'Dismiss', { duration: 3000 });
          this.router.navigate(['/authorize']);
        },
        error: () => {
          this.snackbar.open('Activation failed. Please try again shortly.', 'Dismiss', { duration: 3000 });
        }
      })
    );
  }

  login() {
    this.router.navigate(['/authorize']);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as notificationActions from '../actions/notification.actions';
import {
  GET_TOTAL_UNREAD_NOTIFICATION_COUNT,
  GET_UNREAD_NOTIFICATIONS,
  MARK_NOTIFICATIONS_READ,
  MarkNotificationRead
} from '../actions/notification.actions';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Notification } from '@ee/common/models';
import { NotificationService } from '@ee/common/services';

@Injectable()
export class NotificationEffects {
  unreadNotifications$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_UNREAD_NOTIFICATIONS),
      switchMap(() =>
        this.notificationService
          .getRecentNotifications()
          .pipe(map((notifications: Notification[]) => new notificationActions.LoadUnreadNotification(notifications)))
      )
    )
  );

  totalUnreadNotificationCount$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_TOTAL_UNREAD_NOTIFICATION_COUNT),
      switchMap(() =>
        this.notificationService
          .getUnreadCount()
          .pipe(map((total: number) => new notificationActions.LoadTotalUnreadNotificationCount(total)))
      )
    )
  );

  markNotificationsRead$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(MARK_NOTIFICATIONS_READ),
      map((action: MarkNotificationRead) => action.payload),
      switchMap((notificationIds: string[]) =>
        this.notificationService
          .markRead(notificationIds)
          .pipe(map((notifications: Notification[]) => new notificationActions.LoadUnreadNotification(notifications)))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private snackBar: MatSnackBar,
    private http: HttpClient
  ) {}
}

export enum EvictionUpdateType {
  DISMISSED = 'Dismissed',
  NONSUIT = 'Nonsuit',
  REDEEMED = 'Redeemed',
  FILED_IN_ERROR = 'Filed In Error',
  CONTINUANCE = 'Continuance',
  OTHER = 'Other',
  CLOSED_BY_ATTORNEY = 'Closed By Attorney',
  DOCKET_NUMBER_UPDATE = 'Docket Number Updated',
  PARTIAL_PAYMENT_MADE = 'Partial Payment Made',
  INCREASE_TENANT_BALANCE = 'Increase Tenant Balance',
  STEP_COMPLETED = 'Step Completed',
  LAST_STEP_COMPLETED = 'Last Step Completed',
  ATTORNEY_CREATED_CASE = 'Attorney Created Case',
  INTAKE_CASE_PROCESSED = 'Intake Case Processed',
  STEP_SKIPPED = 'Step Skipped',
  SET_COURT_DATE = 'Set Court Date',
  RESET_COURT_DATE = 'Reset Court Date',
  REOPENED = 'Case Reopened',
  ARCHIVED = 'Case Archived',
  UPDATED_JUDGEMENT = 'Judgement Updated',
  CASE_CHANGES = 'Case Details Changed',
  CLIENT_SURVEY_ANSWERED = 'Client Survey Answered',
  MESSAGE_RECEIVED = 'Message Received'
}

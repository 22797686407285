import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {Organization, Results} from '@ee/common/models';
import {IServerSideGetRowsRequest} from '@ag-grid-community/core';

@Injectable({ providedIn: 'root' })
export class PropertyService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  searchPropertiesByCompanyName(query: string, fields: string[] = []):
    Observable<Organization[]> {
    const params = {};
    if (query) {
      params['q'] = encodeURIComponent(query);
    }
    if (fields?.length) {
      params['f'] = fields.join(',');
    }

    return this.http.get<Organization[]>(`${this.environment.api_prefix}api/properties/search`, { params });
  }

  findProperties(request: IServerSideGetRowsRequest):
    Observable<Results<Organization>> {
    const apiPath = 'api/properties/search';
    return this.http.post<Results<Organization>>(
      this.environment.api_prefix + apiPath, request);
  }

  getProperties(): Observable<Organization[]> {
    return this.http.get<Organization[]>(`${this.environment.api_prefix}api/properties`);
  }

  deleteProperty(organization: Organization): Observable<Organization> {
    return this.http.delete<Organization>(`${this.environment.api_prefix}api/properties/${organization.id}`);
  }
}

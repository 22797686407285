import { Action } from '@ngrx/store';
import { Message } from '@ee/common/models';

export const SET_EVICTION_MESSAGE_SUMMARY_LIST = '[Message] Set Eviction Message Summary List';
export const SET_EVICTION_MESSAGES = '[Message] Set Eviction Messages';
export const SEND_MESSAGE = '[Message] Send Message';
export const MESSAGE_SENT = '[Message] Message Sent';
export const RESET_CASE_UNREAD_COUNT = '[Message] Reset Unread Count';

export class SetEvictionMessages implements Action {
  readonly type = SET_EVICTION_MESSAGES;

  constructor(public payload: Message[] | undefined) {
  }
}

export class SendMessage implements Action {
  readonly type = SEND_MESSAGE;

  constructor(public payload: Message) {
  }
}

export class MessageSent implements Action {
  readonly type = MESSAGE_SENT;

  constructor(public payload: Message) {
  }
}

export class ResetUnreadCount implements Action {
  readonly type = RESET_CASE_UNREAD_COUNT;

  constructor(public caseId: string) {
  }
}

export type MessageActions =
  SetEvictionMessages
  | SendMessage
  | MessageSent
  | ResetUnreadCount;

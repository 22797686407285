import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Message, Results, MessageSummary } from '@ee/common/models';
import { MessageSummaryType } from '@ee/common/enums';

export interface MessageSummaryQuery {
  search: string;
}

export interface Sort<T> {
  property: keyof T;
  order: 'asc' | 'desc';
}

export interface PageRequest<T, S> {
  page: number;
  size: number;
  type: S;
  sort?: Sort<T>;
}

export interface Page<T, S> {
  content: T[];
  totalElements: number;
  size: number;
  type: S;
  number: number;
}

export type PaginatedEndpoint<T, Q, S> = (pageable: PageRequest<T, S>, query: Q) => Observable<Page<T, S>>;

@Injectable({ providedIn: 'root' })
export class MessageService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  loadMessageSummaryPage(
    page: number,
    limit: number,
    type: MessageSummaryType,
    search: string | undefined = undefined,
    unreadOnly = false,
    daysLimit: number
  ): Observable<Results<MessageSummary>> {
    const params: any = {
      page,
      limit,
      type
    };
    if (search) {
      params.query = encodeURIComponent(search);
    }
    if (unreadOnly) {
      params.unread = true;
    }
    if (daysLimit) {
      params['days-limit'] = daysLimit;
    }
    return this.http.get<Results<MessageSummary>>(`${this.environment.api_prefix}api/messages`, {
      params
    });
  }

  loadClientAttorneyMessageSummary(): Observable<MessageSummary> {
    return this.http.get<MessageSummary>(`${this.environment.api_prefix}api/messages/attorney/summary`);
  }

  getDirectMessagesByIdentifier(identifier: string): Observable<Message[]> {
    return this.http.get<Message[]>(`${this.environment.api_prefix}api/messages/direct/${identifier}`);
  }

  getByCaseId(caseId: string): Observable<Message[]> {
    return this.http.get<Message[]>(`${this.environment.api_prefix}api/messages/case/${caseId}`);
  }

  sendDirectMessage(orgId: string, message: string): Observable<Message> {
    return this.http.post<Message>(`${this.environment.api_prefix}api/messages/direct/${orgId}`, {
      message
    });
  }

  markAllAsRead(): Observable<number> {
    return this.http.put<number>(`${this.environment.api_prefix}api/messages/mark-all-read`, {});
  }

  sendMessage(caseId: string, message: string): Observable<Message> {
    return this.http.post<Message>(`${this.environment.api_prefix}api/messages/case/${caseId}`, {
      case_id: caseId,
      message
    });
  }
}

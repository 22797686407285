export enum FormFieldName {
  PLAINTIFF = 'plaintiff',
  PLAINTIFF_ADDRESS = 'plaintiff_address',
  PLAINTIFF_STREET_ADDRESS = 'plaintiff_street_address',
  PLAINTIFF_CITY_STATE_ZIP = 'plaintiff_city_state_zip',
  PLAINTIFF_CITY = 'plaintiff_city',
  PLAINTIFF_STATE = 'plaintiff_state',
  PLAINTIFF_ZIP = 'plaintiff_zip',
  PLAINTIFF_PHONE = 'plaintiff_phone',
  TENANTS = 'tenants',
  TENANTS_AND_SEPARATED = 'tenants_and_separated',
  TENANTS_PLURAL = 'tenants_plural',
  TENANTS_LN_FN = 'tenants_ln_fn',
  TENANT_1 = 'tenant_1',
  TENANT_1_LN_FN = 'tenant_1_ln_fn',
  TENANT_1_FN = 'tenant_1_fn',
  TENANT_1_LN = 'tenant_1_ln',
  TENANT_1_PHONE = 'tenant_1_phone',
  TENANT_1_SSN = 'tenant_1_ssn',
  TENANT_2 = 'tenant_2',
  TENANT_2_LN_FN = 'tenant_2_ln_fn',
  TENANT_2_FN = 'tenant_2_fn',
  TENANT_2_LN = 'tenant_2_ln',
  TENANT_2_PHONE = 'tenant_2_phone',
  TENANT_2_SSN = 'tenant_2_ssn',
  TENANT_3 = 'tenant_3',
  TENANT_3_LN_FN = 'tenant_3_ln_fn',
  TENANT_3_FN = 'tenant_3_fn',
  TENANT_3_LN = 'tenant_3_ln',
  TENANT_3_PHONE = 'tenant_3_phone',
  TENANT_3_SSN = 'tenant_3_ssn',
  TENANT_4 = 'tenant_4',
  TENANT_4_LN_FN = 'tenant_4_ln_fn',
  TENANT_4_FN = 'tenant_4_fn',
  TENANT_4_LN = 'tenant_4_ln',
  TENANT_4_PHONE = 'tenant_4_phone',
  TENANT_4_SSN = 'tenant_4_ssn',
  TENANT_PHONE = 'tenant_phone',
  TENANT_TOTAL_OWED = 'tenant_total_owed',
  PROPERTY_ADDRESS = 'property_address',
  PROPERTY_STREET_ADDRESS = 'property_street_address',
  PROPERTY_CITY_STATE_ZIP = 'property_city_state_zip',
  PROPERTY_CITY = 'property_city',
  PROPERTY_STATE = 'property_state',
  PROPERTY_ZIP = 'property_zip',
  PROPERTY_UNIT_TYPE = 'property_unit_type',
  PROPERTY_UNIT_NUMBER = 'property_unit_number',
  PROPERTY_UNIT_TYPE_AND_NUMBER = 'property_unit_type_and_number',
  DOCKET_NUMBER = 'docket_number',
  ATTORNEY_NAME = 'attorney_name',
  ATTORNEY_ADDRESS = 'attorney_address',
  ATTORNEY_PHONE = 'attorney_phone',
  ATTORNEY_FAX = 'attorney_fax',
  COURT_DATE = 'court_date',
  COURT_DAY = 'court_day',
  COURT_MONTH = 'court_month',
  COURT_YEAR = 'court_year',
  COURT_YEAR_LAST_TWO = 'court_year_last_two',
  COURT_DAY_OF_WEEK = 'court_day_of_week',
  COURT_TIME = 'court_time',
  NOTICE_DATE = 'notice_date',
  NOTICE_DAY = 'notice_day',
  NOTICE_MONTH = 'notice_month',
  NOTICE_YEAR = 'notice_year',
  NOTICE_YEAR_LAST_TWO = 'notice_year_last_two',
  NOTICE_MONTH_FULL = 'notice_month_full',
  REASON_OTHER_CHECK = 'reason_other_check',
  REASON_UNPAID_RENT_CHECK = 'reason_unpaid_rent_check',
  REASON = 'reason',
  PAST_DUE_RENT = 'past_due_rent',
  RENT_CHECK = 'rent_check',
  DELINQUENCY_LENGTH = 'delinquency_length',
  DAMAGES = 'damages',
  DAMAGES_CHECK = 'damages_check',
  DAMAGES_DESCRIPTION = 'damages_description',
  INTEREST_RATE = 'interest_rate',
  INTEREST_START_DATE = 'interest_start_date',
  COSTS = 'costs',
  CIVIL_RECOVERY_FEES = 'civil_recovery_fees',
  COURT_COSTS = 'court_costs',
  ATTORNEY_FEE = 'attorney_fee',
  ATTORNEY_FEE_CHECK = 'attorney_fee_check',
  LATE_FEES = 'late_fees',
  SECURITY_DEPOSIT = 'security_deposit',
  LEASE_RENT_AMOUNT = 'lease_rent_amount',
  LEASE_RENT_DUE_DAY = 'lease_rent_due_day',
  LEASE_RENT_FREQUENCY = 'lease_rent_frequency',
  AMOUNT_PAID = 'amount_paid',
  CURRENT_DATE = 'current_date',
  CURRENT_DATE_FULL = 'current_date_full',
  CURRENT_DATE_LONG = 'current_date_long',
  CURRENT_DATE_MEDIUM = 'current_date_medium',
  CURRENT_DAY = 'current_day',
  CURRENT_MONTH = 'current_month',
  CURRENT_YEAR_LAST_TWO = 'current_year_last_two',
  CURRENT_YEAR = 'current_year',
  COUNTY = 'county',
  COUNTY_NAME_ONLY = 'county_name_only',
  GENERAL_SESSIONS_CLERK = 'general_sessions_clerk',
  COURT_ADDRESS = 'court_address',
  COURT_ADDRESS_AND_ROOM = 'court_address_and_room',
  COURT_ROOM = 'court_room',
  DIVISION = 'division'
}

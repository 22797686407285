import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {GenerateTagForm, TagFormType} from './tag.model';
import {TAG_COLORS} from './tag.constants';
import {ConnectionPositionPair} from '@angular/cdk/overlay';
import {IntakeEvictionType} from '@ee/common/enums';
import {TagService} from './tag.service';
import {Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as changeCase from 'change-case';
import {Tag} from '@ee/common/models';

@Component({
  selector: 'ee-tag-editor-dialog',
  template: `
    <h2 mat-dialog-title>{{title}}</h2>

    <div mat-dialog-content class="tag-editor" *ngIf="!!tags?.length; else empty">
      <div class="tag-editor-table">
        <div class="tag-editor-row-wrapper" *ngFor="let l of tags; let i = index">
          <form class="tag-editor-row" *ngIf="i === editing && !!tagForm; else view"
                (ngSubmit)="saveTag(i)">
            <div class="name mr-2">
              <mat-form-field class="compact">
                <mat-label>Tag Name</mat-label>
                <input matInput [formControl]="$any(tagForm.get('name'))">
              </mat-form-field>
            </div>
            <div class="color mr-2 cursor-pointer" [ngStyle]="{ backgroundColor: colors[tagForm.get('color').value] }"
                 cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="isColorPickerOpen = !isColorPickerOpen">
              &nbsp;
            </div>
            <ng-template
              cdkConnectedOverlay
              cdkConnectedOverlayPush
              [cdkConnectedOverlayPositions]="positions"
              [cdkConnectedOverlayOrigin]="trigger"
              [cdkConnectedOverlayOpen]="isColorPickerOpen"
              [cdkConnectedOverlayHasBackdrop]="true"
              cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
              (backdropClick)="isColorPickerOpen = false">
              <div class="color-selector ee__standard-shadow">
                <div class="color-selector-label">Select a Color</div>
                <div class="flex flex-row">
                  <div class="color mr-1" *ngFor="let c of colors | keyvalue" [ngStyle]="{ backgroundColor: c.value }"
                       (click)="tagForm.get('color').setValue($any(c.key)); isColorPickerOpen = false">
                  </div>
                </div>
              </div>
            </ng-template>
            <div class="edit-buttons">
              <button mat-icon-button type="button" (click)="undoChange()"
                      class="ee__button ee__medium gray gray-bg mr-2" [disabled]="isColorPickerOpen">
                <mat-icon fontSet="fa" fontIcon="fa-undo"></mat-icon>
              </button>
              <button mat-icon-button type="submit" class="ee__button ee__medium green gray-bg"
                      [disabled]="tagForm.invalid || isColorPickerOpen">
                <mat-icon fontSet="fa" fontIcon="fa-floppy-disk"></mat-icon>
              </button>
            </div>
          </form>

          <ng-template #view>
            <div class="tag-editor-row">
              <div class="name mr-2">
                <ee-tag [tag]="l"></ee-tag>
              </div>
              <div class="edit-buttons">
                <button mat-icon-button type="button" class="ee__button ee__medium gray gray-bg mr-2"
                        [disabled]="editing > -1" [matMenuTriggerFor]="menu">
                  <mat-icon fontSet="fa" fontIcon="fa-trash"></mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <div class="confirm-delete">
                    Are you sure? This change will remove this label from all {{data.type.toLowerCase()}}s.
                  </div>
                  <button mat-menu-item (click)="deleteTag(i)">Yes</button>
                  <button mat-menu-item>No</button>
                </mat-menu>
                <button mat-icon-button type="button" class="ee__button ee__medium gray-bg"
                        [disabled]="editing > -1" (click)="editTag(i)">
                  <mat-icon fontSet="fa" fontIcon="fa-pen"></mat-icon>
                </button>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <ng-template #empty>
      <div mat-dialog-content class="tag-editor justify-center items-center labels empty">
        Add a tag to get started
      </div>
    </ng-template>

    <div mat-dialog-actions class="ee-modal-actions-split">
      <button class="ee-modal-actions-split-left" mat-button type="button" [disabled]="editing > -1"
              (click)="addNewLabel()">+ ADD NEW TAG
      </button>
      <div class="ee-modal-actions">
        <button mat-button type="button" (click)="close()" cdkFocusInitial color="primary"
                [disabled]="editing > -1">
          CLOSE
        </button>
      </div>
    </div>
  `,
  styles: [`
    .mat-mdc-dialog-content.mdc-dialog__content.tag-editor {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      height: 400px;
      padding-right: 16px;

      .tag-editor-table {
        overflow-y: auto;
        padding-right: 8px;

        .tag-editor-row-wrapper {
          display: flex;
          flex-direction: row;
          flex: 1;
          height: 46px;
          padding-bottom: 8px;
          margin-top: 8px;
          align-items: center;
          border-bottom: 1px solid #ccc;
          margin-bottom: 8px;

          .tag-editor-row {
            display: flex;
            flex-direction: row;
            flex: 1;
            align-items: center;

            &:first-of-type {
              margin-top: 0;
            }

            &:last-of-type {
              border-bottom: none;
            }

            .name {
              flex: 1;

              .mat-mdc-form-field {
                width: 100%;
              }
            }

            .color {
              width: 36px;
              height: 36px;
            }
          }
        }
      }
    }

    .color-selector {
      background-color: #fff;
      padding: 4px;
      border: 1px solid #ccc;

      .color-selector-label {
        text-align: center;
      }

      .color {
        cursor: pointer;
        width: 36px;
        height: 36px;
        margin: 0 1px;
      }
    }
  `]
})
export class TagManagerDialogComponent implements OnInit, OnDestroy {
  @ViewChild(TagManagerDialogComponent) billingFormComponent: TagManagerDialogComponent;

  tags: Tag[] = [];
  tagForm: TagFormType | undefined;
  title = '';
  editing = -1;
  isColorPickerOpen = false;
  colors = TAG_COLORS;
  public positions = [
    new ConnectionPositionPair({
      originX: 'center',
      originY: 'bottom'
    }, {
      overlayX: 'center',
      overlayY: 'top'
    }, 0, 2)];
  subs: Subscription[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private tagService: TagService,
    public dialogRef: MatDialogRef<TagManagerDialogComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { type: 'client' }
  ) {
    this.title = `Manage ${changeCase.capitalCase(data.type)} Tags`;
  }

  ngOnInit() {
    this.subs.push(this.tagService.getTags(this.data.type).subscribe((tags: Tag[]) => {
      this.tags = tags;
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  addNewLabel() {
    // find unused name
    let labelName = 'New Label 1';
    let i = 2;
    while (this.tags.find(l => l.name === labelName)) {
      labelName = `New Label ${i}`;
      i++;
    }
    const tag: Tag = {value: '', type: this.data.type, name: labelName, color: 'blue'};
    this.tagForm = GenerateTagForm(this.formBuilder, tag, [...this.tags]);

    this.tags.push(tag);
    this.editing = this.tags.length - 1;
  }

  editTag(i: number) {
    const tag = this.tags[i];
    const tagsMinusCurrent = [...this.tags];
    tagsMinusCurrent.splice(i, 1);
    this.tagForm = GenerateTagForm(this.formBuilder, tag, tagsMinusCurrent);
    this.editing = i;
  }

  deleteTag(i: number) {
    this.subs.push(this.tagService.deleteTag(this.data.type, this.tags[i]).subscribe({
      next: () => {
        this.tags.splice(i, 1);
      },
      error: () => {
        this.snackBar.open('Error deleting tag.', 'Dismiss',
          {duration: 3000});
      }
    }));
  }

  saveTag(i: number) {
    if (this.tagForm.invalid || this.isColorPickerOpen) {
      return;
    }

    const tag = this.tagForm.value as Tag;
    tag.name = tag.name.trim();

    this.subs.push(this.tagService.upsertTag(this.data.type, tag).subscribe({
      next: (t: Tag) => {
        const tags = [...this.tags];
        tags[i] = t;
        this.tags = tags;
        this.stopEditing();
      },
      error: () => {
        this.snackBar.open('Error saving the tag.', 'Dismiss', {duration: 3000});
      }
    }));
  }

  undoChange() {
    if (!this.tagForm.get('value').value) { // must be new
      this.tags.pop();
    }
    this.stopEditing();
  }

  close() {
    this.dialogRef.close(this.tags);
  }

  private stopEditing() {
    this.editing = -1;
    this.tagForm = undefined;
  }

  protected readonly IntakeEvictionType = IntakeEvictionType;
}

import { Action } from '@ngrx/store';
import { Attachment, KeyValue } from '@ee/common/models';

export const LOAD_EVICTION_ATTACHMENTS = '[Attachment] Load Eviction Attachments';
export const SET_EVICTION_ATTACHMENTS = '[Attachment] Set Eviction Attachments';
export const REFRESH_EVICTION_ATTACHMENTS = '[Attachment] Refresh Eviction Attachments';
export const ADD_ATTACHMENT = '[Attachment] Add Attachment';
export const ATTACHMENT_ADDED = '[Attachment] Attachment Added';
export const ATTACHMENT_READY = '[Attachment] Attachment Ready';
export const ATTACHMENT_GENERATE_ERROR = '[Attachment] Attachment Generate Error';
export const DELETE_ATTACHMENT = '[Attachment] Delete Attachment';
export const ATTACHMENT_DELETED = '[Attachment] Attachment Deleted';
export const UPDATE_ATTACHMENT_VISIBILITY = '[Attachment] Update Attachment Visibility';
export const ATTACHMENT_VISIBILITY_UPDATED = '[Attachment] Attachment Visibility Updated';
export const POLL_FOR_ATTACHMENT = '[Attachment] Poll For Attachment';

export class LoadEvictionAttachments implements Action {
  readonly type = LOAD_EVICTION_ATTACHMENTS;

  constructor(public payload: string) {
  } // eviction id
}

export class SetEvictionAttachments implements Action {
  readonly type = SET_EVICTION_ATTACHMENTS;

  constructor(public payload: Attachment[] | undefined) {
  }
}

export class RefreshEvictionAttachments implements Action {
  readonly type = REFRESH_EVICTION_ATTACHMENTS;

  constructor(public caseId: string, public attachments: Attachment[] | undefined) {
  }
}

export class AddAttachment implements Action {
  readonly type = ADD_ATTACHMENT;

  constructor(public payload: KeyValue<string, FormData>) {
  }
}

export class AttachmentAdded implements Action {
  readonly type = ATTACHMENT_ADDED;

  constructor(public payload: Attachment) {
  }
}

export class AttachmentReady implements Action {
  readonly type = ATTACHMENT_READY;

  constructor(public id: string | undefined) {
  }
}

export class AttachmentGenerateError implements Action {
  readonly type = ATTACHMENT_GENERATE_ERROR;

  constructor(public id: string) {
  }
}

export class PollForAttachment implements Action {
  readonly type = POLL_FOR_ATTACHMENT;

  constructor(public formId: string) {
  }
}

export class DeleteAttachment implements Action {
  readonly type = DELETE_ATTACHMENT;

  constructor(public payload: KeyValue<string, Attachment>) {
  }
}

export class AttachmentDeleted implements Action {
  readonly type = ATTACHMENT_DELETED;

  constructor(public payload: string | undefined) {
  }
}

export class UpdateAttachmentVisibility implements Action {
  readonly type = UPDATE_ATTACHMENT_VISIBILITY;

  constructor(public caseId: string, public attachmentId: string, public hideFromClient: boolean) {
  }
}

export class AttachmentVisibilityUpdated implements Action {
  readonly type = ATTACHMENT_VISIBILITY_UPDATED;

  constructor(public attachmentId: string, public hideFromClient: boolean) {
  }
}

export type AttachmentActions =
  LoadEvictionAttachments
  | SetEvictionAttachments
  | AddAttachment
  | AttachmentAdded
  | DeleteAttachment
  | AttachmentDeleted
  | PollForAttachment
  | AttachmentReady
  | AttachmentGenerateError
  | UpdateAttachmentVisibility
  | AttachmentVisibilityUpdated;

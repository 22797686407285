import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {
  ChangePassword,
  GuideViewed, ResetCaseGridSetting,
  ResetPassword,
  UpdateBarLicenses,
  UpdateCaseGridSetting,
  UpdateEmail,
  UpdateNotificationSettings,
  UpdateUserDashboardSettings,
  UpdateUserDetails,
  UpdateUserSetting
} from '../actions/user.actions';
import * as authActions from '../actions/auth.actions';
import {
  CaseGridSettingsUpdated,
  Logout,
  NotificationSettingsUpdated,
  UserBarLicensesUpdated,
  UserDashboardSettingsUpdated,
  UserEmailUpdated,
  UserSettingUpdated
} from '../actions/auth.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {NotificationSettings, User, UserDashboardSettings} from '@ee/common/models';
import {ShowAutoClosableAlert} from '../actions/alert.actions';

@Injectable()
export class UserEffects {
  updateUserNotifications$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateNotificationSettings),
      switchMap(({ settings, updateAllCases }) =>
        this.http
          .put(
            `${environment.api_prefix}api/users/notification-settings?update-existing-cases=${updateAllCases}`,
            settings
          )
          .pipe(
            switchMap((updatedNotification: NotificationSettings) => [
              new ShowAutoClosableAlert('Notification settings updated successfully.'),
              NotificationSettingsUpdated(updatedNotification)
            ]),
            catchError(() => [new ShowAutoClosableAlert('Error updating user notification settings.')])
          )
      )
    )
  );

  updateUserDashboardSettings$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateUserDashboardSettings),
      switchMap(({ dashboardSettings }) =>
        this.http.put(`${environment.api_prefix}api/dashboard/dashboard-settings`, dashboardSettings).pipe(
          switchMap((updatedSettings: UserDashboardSettings) => [UserDashboardSettingsUpdated(updatedSettings)]),
          catchError(() => [new ShowAutoClosableAlert('Error updating dashboard settings.')])
        )
      )
    )
  );

  updateUserSettings$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateUserSetting),
      // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
      switchMap((keyValue: { key: string; value: any | null }) =>
        this.http.put<boolean>(`${environment.api_prefix}api/users/update-setting`, keyValue).pipe(
          switchMap(success => {
            const update = [];
            if (success) {
              update.push(UserSettingUpdated(keyValue));
            }
            return update;
          }),
          catchError(() => [new ShowAutoClosableAlert('Error updating user settings.')])
        )
      )
    )
  );

  updateUserGridSettings$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateCaseGridSetting),
      switchMap(( {value} ) =>
        this.http.put<boolean>(`${environment.api_prefix}api/users/grid-settings`, value).pipe(
          switchMap(success => {
            if (success) {
              return [CaseGridSettingsUpdated(value)];
            }
          }),
          catchError(() => [new ShowAutoClosableAlert('Error updating user settings.')])
        )
      )
    )
  );

  resetUserGridSettings$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ResetCaseGridSetting),
      switchMap(() =>
        this.http.delete<boolean>(`${environment.api_prefix}api/users/grid-settings`).pipe(
          switchMap(success => {
            if (success) {
              return [CaseGridSettingsUpdated([])];
            }
          }),
          catchError(() => [new ShowAutoClosableAlert('Error resetting user settings.')])
        )
      )
    )
  );

  changeEmail$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateEmail),
      switchMap(({ email }) =>
        this.http.post(`${environment.api_prefix}api/users/change-email`, email).pipe(
          switchMap((result: User) => {
            return [new ShowAutoClosableAlert('Email address updated.'), UserEmailUpdated(result.email)];
          }),
          catchError(() => [new ShowAutoClosableAlert('Error updating email. Please try again.')])
        )
      )
    )
  );

  updateBarLicenses$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateBarLicenses),
      switchMap(({ licenses }) =>
        this.http.put(`${environment.api_prefix}api/users/bar-licenses`, licenses).pipe(
          switchMap((result: { string: string }) => [
            new ShowAutoClosableAlert('Licenses updated.'),
            UserBarLicensesUpdated(result)
          ]),
          catchError(() => [new ShowAutoClosableAlert('Error updating bar licenses.')])
        )
      )
    )
  );

  updateUserDetails$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateUserDetails),
      switchMap(({ user }) =>
        this.http.put<void>(`${environment.api_prefix}api/users/details`, user).pipe(
          switchMap(() => [
            new ShowAutoClosableAlert('User details updated.'),
            authActions.UserDetailsUpdated(user)
          ]),
          catchError(() => [new ShowAutoClosableAlert('Error updating user details. Please try again.')])
        )
      )
    )
  );

  changePassword$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangePassword),
      switchMap(({ changePasswordModel }) =>
        this.http.post(`${environment.api_prefix}api/account/change-password`, changePasswordModel).pipe(
          switchMap(() => [
            new ShowAutoClosableAlert('Password successfully updated. Please log in using your new email address.'),
            Logout()
          ]),
          catchError(error => {
            const message = error.error ? error.error.message : 'Error while updating existing password.';
            return [new ShowAutoClosableAlert(message)];
          })
        )
      )
    )
  );

  resetPassword$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ResetPassword),
      switchMap(({ email }) =>
        this.http.post(environment.api_prefix + 'api/account/reset-password/init', email).pipe(
          map(() => new ShowAutoClosableAlert('Password reset email sent.')),
          catchError(error => {
            const message = error.error ? error.error.message : 'Error encountered resetting password.';
            return [new ShowAutoClosableAlert(message)];
          })
        )
      )
    )
  );

  guideViewed$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(GuideViewed),
      switchMap(({ id }) =>
        this.http.post(`${environment.api_prefix}api/users/viewed-guide/${id}`, {}).pipe(
          switchMap(() => [authActions.GuidesViewedUpdated(id)]),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(private actions$: Actions, private http: HttpClient) {}
}

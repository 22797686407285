<div class="portal-notification-sidenav h-full w-full flex flex-col">
  <div class="notification-center-link">
    <a routerLink="/notifications" (click)="sidenav.close()">
      Click to Open Notification Center <i class="fa-solid fa-circle-arrow-right"></i>
    </a>
  </div>
  <div class="portal-notification-notifications" *ngIf="allNotificationIds.length > 0; else empty">
    <div *ngFor="let group of groupedNotification">
      <div class="flex flex-row justify-between items-center">
        <h2 class="portal-notifications-header" [textContent]="group.date"></h2>
        <h5 class="clear-all" (click)="clearAllNotifications()">Clear All</h5>
      </div>
      <div *ngFor="let notification of group.notifications">
        <mat-card appearance="outlined" class="portal-notifications-notification message mat-elevation-z">
          <mat-card-header>
            <mat-card-title class="notification-title" (click)="navigate(notification)">
              <!--                  <mat-icon fontSet="fa" fontIcon="fa-comment" matCardAvatar-->
              <!--                            class="portal-notifications-message"></mat-icon>-->
              <strong [ngClass]="{expired: notification.expired}">
                {{formatHeader(notification)}}
              </strong>
            </mat-card-title>
            <div class="mat-caption" (click)="removeNotification(notification)">
              <span class="mr-2">{{notification.time_since}}</span>
              <i class="fa fa-xmark"></i>
            </div>
          </mat-card-header>
          <mat-card-content [ngClass]="{expired: notification.expired}" (click)="navigate(notification)">
            <div *ngIf="notification.case_update_type">
              <b>{{notification.case_update_type}}:</b>
            </div>
            <p>
              <span [innerHtml]="notification.description"></span>
            </p>
            <div class="font-bold">{{ formatActionDisplay(notification) }}</div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>

  <ng-template #empty>
    <div class="no-notifications flex flex-1 flex-col justify-center items-center">
      <div>
        <mat-icon class="icon" fontSet="far" fontIcon="fa-bell-slash"></mat-icon>
      </div>
      <h2 class="align-center">No new notifications</h2>
    </div>
  </ng-template>
  <mat-toolbar>
    <a mat-icon-button (click)="sidenav.close()">
      <mat-icon>chevron_right</mat-icon>
    </a>
  </mat-toolbar>
</div>

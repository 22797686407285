import {Action, createAction} from '@ngrx/store';

export const EMPTY_ACTION = '[App] Empty';
export const CLOSE_DIALOG = '[App] Close Dialog';
export const LOG_ERROR = '[App] Log Error';

export class CloseDialog implements Action {
  readonly type = CLOSE_DIALOG;
}

export class EmptyAction implements Action {
  readonly type = EMPTY_ACTION;
}

export const LogError = createAction(
  LOG_ERROR,
  (payload: string) => ({payload})
);

export type AppActions = CloseDialog | EmptyAction;

import {UntypedFormArray, UntypedFormBuilder, Validators} from '@angular/forms';
import {MinSelectedCheckboxesValidator} from '@ee/common/validators';

export function GenerateEmailDetailsForm(fb: UntypedFormBuilder, defaultEmails: string[] = []) {
  const emails = defaultEmails?.length ? [defaultEmails] :
    new UntypedFormArray([], [MinSelectedCheckboxesValidator.createValidator(1)] as any[]);
  return fb.group({
    subject: [null, Validators.required],
    message: [null, Validators.required],
    emails
  });
}

export function GenerateSimpleEmailDetailsForm(fb: UntypedFormBuilder, defaultEmail: string) {
  return fb.group({
    subject: [null, Validators.required],
    message: [null, Validators.required],
    emails: [[defaultEmail], Validators.required]
  });
}

import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ToolsService {
  public resourceUrl = this.environment.api_prefix + 'api/tools';

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  parseSpreadsheet(formData: FormData): Observable<Array<string[]>> {
    return this.http.post<Array<string[]>>(`${this.resourceUrl}/parse-spreadsheet`, formData);
  }

  getRoadmapUrl(): Observable<any> {
    return this.http.get(`${this.resourceUrl}/roadmap-path`, {
      observe: 'response',
      responseType: 'text'
    });
  }
}

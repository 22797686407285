import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as noteActions from '../actions/note.actions';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Note } from '@ee/common/models';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class NoteEffects {
  saveNote$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(noteActions.SAVE_NOTE),
      map((action: noteActions.SaveNote) => action.payload),
      switchMap((note: Note) =>
        this.http.post<Note>(`${environment.api_prefix}api/evictions/${note.case_id}/notes`, note).pipe(
          switchMap((savedNote: Note) => {
            this.snackBar.open('Note saved', 'Dismiss', { duration: 3000 });
            return [new noteActions.NoteSaved(savedNote)];
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private snackBar: MatSnackBar, private http: HttpClient) {}
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as alertActions from '../actions/alert.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { OfflineSnackbarComponent } from '../../shared-components/offline-snackbar.component';
import { HotToastService } from '@ngneat/hot-toast';

@Injectable()
export class AlertEffects {

  constructor(private actions$: Actions, private toastService: HotToastService, private snackBar: MatSnackBar) {}

  showAutoClosableAlert$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(alertActions.SHOW_AUTO_CLOSABLE_ALERT),
        map((action: alertActions.ShowAutoClosableAlert) => action),
        map(action => {
          this.snackBar.open(action.payload, action.dismissLabel, {
            duration: action.duration
          });
        })
      ),
    { dispatch: false }
  );

  showManualClosableAlert$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(alertActions.SHOW_MANUAL_CLOSABLE_ALERT),
        map((action: alertActions.ShowManualClosableAlert) => action),
        map(action => {
          this.snackBar.open(action.payload, action.dismissLabel);
        })
      ),
    { dispatch: false }
  );

  showOfflineAlert$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(alertActions.SHOW_OFFLINE_ALERT),
        map((action: alertActions.ShowOfflineAlert) => action),
        map(action => {
          const offlineSnackbarRef = this.snackBar.openFromComponent(OfflineSnackbarComponent, {
            data: action,
            duration: action.autoClose ? 3000 : undefined
          });

          offlineSnackbarRef._open();
        })
      ),
    { dispatch: false }
  );

  showAlert$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(alertActions.SHOW_ALERT_WITH_LINK),
        map((action: alertActions.ShowAlertWithLink) => action),
        map(data => {
          this.toastService.show(`
            <div class="download-alert">
              <div class="flex flex-col text-center">
                ${data.alert}
              </div>
              <div class="ml-4 font-bold">
                <a class="btn btn-primary" href="${data.link}" target="_blank">${data.buttonText ?? 'Download'}</a>
              </div>
              </div>
            </div>
          `, { autoClose: false, dismissible: true, theme: 'snackbar', position: 'top-right' });
        })
      ),
    { dispatch: false }
  );

  showErrorAlert$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(alertActions.SHOW_ERROR_ALERT),
        map((action: alertActions.ShowErrorAlert) => action.payload),
        map((httpErrorResponse: HttpErrorResponse) => {
          switch (httpErrorResponse.status) {
          // connection refused, server not reachable
            case 0:
              this.showErrorAlert('Server not reachable');
              break;

            case 400:
              const arr = httpErrorResponse.headers.keys();
              let errorHeader = null;
              let entityKey = null;
              arr.forEach(entry => {
                if (entry.toLowerCase().endsWith('app-error')) {
                  errorHeader = httpErrorResponse.headers.get(entry);
                }
                if (entry.toLowerCase().endsWith('eeviction-error')) {
                  errorHeader = httpErrorResponse.headers.get(entry);
                } else if (entry.toLowerCase().endsWith('app-params')) {
                  entityKey = httpErrorResponse.headers.get(entry);
                } else if (entry.toLowerCase().endsWith('eeviction-params')) {
                  entityKey = httpErrorResponse.headers.get(entry);
                }
              });
              console.dir(entityKey);
              if (errorHeader) {
                this.showErrorAlert(errorHeader);
              } else if (httpErrorResponse.error !== '' && httpErrorResponse.error.fieldErrors) {
                const fieldErrors = httpErrorResponse.error.fieldErrors;

                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let i = 0; i < fieldErrors.length; i++) {
                  const fieldError = fieldErrors[i];
                  if (['Min', 'Max', 'DecimalMin', 'DecimalMax'].includes(fieldError.message)) {
                    fieldError.message = 'Size';
                  }
                  // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                  const convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
                  const fieldName = convertedField.charAt(0).toUpperCase() + convertedField.slice(1);
                  this.showErrorAlert('Error on field "' + fieldName + '"');
                }
              } else if (httpErrorResponse.error !== '' && httpErrorResponse.error.message) {
                this.showErrorAlert(httpErrorResponse.error.message);
              } else {
                this.showErrorAlert(httpErrorResponse.error);
              }
              break;

            case 404:
            // this.showErrorAlert('Not found');
            // DO nothing
              break;
            case 401:
            // this.router.navigate(['/authorize']);
            // DO nothing
              break;

            default:
              if (httpErrorResponse.error !== '' && httpErrorResponse.error.message) {
                this.showErrorAlert(httpErrorResponse.error.message);
              } else {
                this.showErrorAlert(httpErrorResponse.error);
              }
          }
        })
      ),
    { dispatch: false }
  );

  private showErrorAlert(message: string): void {
    this.snackBar.open(message, 'Dismiss', {
      duration: 5000
    });
  }
}

import { Action } from '@ngrx/store';
import { Note } from '@ee/common/models';

export const LOAD_EVICTION_NOTES = '[Note] Load Eviction Notes';
export const SET_EVICTION_NOTES = '[Note] Set Eviction Notes';
export const SAVE_NOTE = '[Note] Save Note';
export const NOTE_SAVED = '[Note] Note Saved';

export class LoadEvictionNotes implements Action {
  readonly type = LOAD_EVICTION_NOTES;

  constructor(public payload: string) {
  } // eviction id
}

export class SetEvictionNotes implements Action {
  readonly type = SET_EVICTION_NOTES;

  constructor(public payload: Note[] | undefined) {
  }
}

export class SaveNote implements Action {
  readonly type = SAVE_NOTE;

  constructor(public payload: Note) {
  }
}

export class NoteSaved implements Action {
  readonly type = NOTE_SAVED;

  constructor(public payload: Note) {
  }
}

export type NoteActions =
  LoadEvictionNotes
  | SetEvictionNotes
  | SaveNote
  | NoteSaved;

import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {WorkflowStep} from '@ee/common/models';
import {GenerateCustomFieldForm} from './custom-field.form';

export function GenerateClientAnswersForm(fb: UntypedFormBuilder, values: WorkflowStep): UntypedFormGroup {
  const form = fb.group({
    client_questions: fb.array([]),
    client_comment: null
  });

  values?.client_questions?.forEach(q => {
    const f = GenerateCustomFieldForm(fb, q);
    f.get('value').setValue(q.value);
    (form.get('client_questions') as UntypedFormArray).push(f);
  });

  return form;
}

import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  BillableItemsResult,
  BillableItemsSearchForm,
  EvictionSearch,
  InvoiceSearch,
  ReportField,
  ReportSettings,
  TaskSearch
} from '@ee/common/models';
import {ReportType} from '@ee/common/enums';

@Injectable({providedIn: 'root'})
export class ReportService {

  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  getEvictionFields(): Observable<ReportField[]> {
    return this.http.get<ReportField[]>(`${this.environment.api_prefix}api/report/eviction/available-fields`);
  }

  getTaskFields(): Observable<ReportField[]> {
    return this.http.get<ReportField[]>(`${this.environment.api_prefix}api/report/task/available-fields`);
  }

  getInvoiceFields(): Observable<ReportField[]> {
    return this.http.get<ReportField[]>(`${this.environment.api_prefix}api/report/invoice/available-fields`);
  }

  generateEvictionReport(searchRequest: EvictionSearch): Observable<any> {
    return this.http.post(`${this.environment.api_prefix}api/report/eviction`, searchRequest, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  generateTaskReport(searchRequest: TaskSearch): Observable<any> {
    return this.http.post(`${this.environment.api_prefix}api/report/task`, searchRequest, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  generateEvictionLabels(type: string, searchRequest: EvictionSearch): Observable<any> {
    return this.http.post(`${this.environment.api_prefix}api/report/labels/${type}`, searchRequest, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  generateBillableItemsReport(searchParams: BillableItemsSearchForm): Observable<BillableItemsResult[]> {
    return this.http.post<BillableItemsResult[]>(`${this.environment.api_prefix}api/report/billable-items`, searchParams);
  }

  generateInvoiceReport(searchRequest: InvoiceSearch): Observable<any> {
    return this.http.post(`${this.environment.api_prefix}api/report/invoice`, searchRequest, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  upsertReportSettings(reportSettings: ReportSettings, reportType: ReportType): Observable<any> {
    return this.http.post(`${this.environment.api_prefix}api/report/${reportType}`, reportSettings);
  }

  deleteReportSettings(reportName: string, reportType: ReportType): Observable<boolean> {
    return this.http.delete<boolean>(`${this.environment.api_prefix}api/report/${reportType}/delete`, {
      params: {
        'reportSettingName': reportName
      }
    });
  }

  getReportSettingsNames(reportType: ReportType): Observable<string[]> {
    return this.http.get<string[]>(`${this.environment.api_prefix}api/report/${reportType}/report-names`);
  }

  loadReportSettings(reportName: string, reportType: ReportType): Observable<ReportSettings> {
    return this.http.get<ReportSettings>(`${this.environment.api_prefix}api/report/${reportType}/load`, {
      params: {
        'reportSettingName': reportName
      }
    });
  }
}

import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Organization, User, ActivationDetails} from '@ee/common/models';

@Injectable({ providedIn: 'root' })
export class ActivationService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  getByUserActivationKey(activationKey: string): Observable<ActivationDetails> {
    return this.http.get<ActivationDetails>(this.environment.api_prefix + `api/activation-details/user/${activationKey}`);
  }

  completeRegistration(organization: Organization, activationKey: string): Observable<any> {
    return this.http.post(this.environment.api_prefix + `api/complete-registration/org/${activationKey}`, organization);
  }

  completeNewUserRegistration(user: User, activationKey: string): Observable<any> {
    return this.http.post(this.environment.api_prefix + `api/complete-registration/user/${activationKey}`, user);
  }

  validateAttorneyKey(attorneyKey: string): Observable<any> {
    return this.http.get(this.environment.api_prefix + `api/users/check-attorney-key/${attorneyKey}`);
  }

  resendActivation(activationEmailId: string): Observable<any> {
    return this.http.post(this.environment.api_prefix +  `api/resend-activation/${activationEmailId}`, {});
  }

  validateInvitationCode(invitationCode: string): Observable<boolean> {
    if (!invitationCode) {
      return of(false);
    }
    return this.http.get<boolean>(`${this.environment.api_prefix}api/register/validate-code/${invitationCode}`);
  }
}

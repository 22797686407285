import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {HttpClient} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import * as intakeEvictionActions from '../actions/intake-eviction.actions';
import {
  GenerateFillableFormDetails,
  IntakeEvictionScraCheck,
  LoadIntakeEvictions,
  ProcessIntakeEviction,
  RejectIntakeEviction,
  UpdateIntakeEviction,
  UpdateLandingPageVisit,
  UpdateScraCheckStatus
} from '../actions/intake-eviction.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {IntakeEviction, IntakeEvictionList, Tenant,} from '@ee/common/models';
import {RefreshMetrics} from '../actions/auth.actions';
import {ShowAutoClosableAlert, ShowManualClosableAlert} from '../actions/alert.actions';
import {FormService, IntakeEvictionService} from '@ee/common/services';
import {Router} from '@angular/router';
import {ScraScanStatus} from '@ee/common/enums';

@Injectable()
export class IntakeEvictionEffects {

  loadIntakeEvictions$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadIntakeEvictions),
      switchMap(() =>
        this.intakeEvictionService.loadIntakeEvictionList()
          .pipe(
            switchMap((intakeEvictions: IntakeEvictionList[]) => [
              intakeEvictionActions.SetIntakeEvictions(intakeEvictions)
            ]))
      )
    )
  );

  updatePageLoadVisit: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UpdateLandingPageVisit),
        switchMap(({landingPageVisit}) => {
          this.intakeEvictionService.updatePageLoadVisits(landingPageVisit);
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  processIntakeEviction: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessIntakeEviction),
      switchMap(({intakeEvictionId, intakeEviction}) =>
        this.intakeEvictionService.processIntakeEviction(intakeEvictionId, intakeEviction)
          .pipe(
            switchMap((caseId: string) => [
              new ShowAutoClosableAlert(`Eviction ${caseId} saved.`),
              intakeEvictionActions.IntakeEvictionProcessed(intakeEvictionId),
              RefreshMetrics()
            ])
          )
      )
    )
  );

  rejectIntakeEviction: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(RejectIntakeEviction),
      switchMap(({id, emailDetails}) =>
        this.intakeEvictionService.rejectIntakeEviction(id, emailDetails)
          .pipe(
            switchMap(() => [
              new ShowAutoClosableAlert('Eviction rejected.'),
              intakeEvictionActions.IntakeEvictionProcessed(id),
              RefreshMetrics()
            ])
          )
      )
    )
  );

  updateEvictionDetails: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateIntakeEviction),
      switchMap(({intakeEviction}) =>
        this.intakeEvictionService.updateIntakeEvictionDetails(intakeEviction)
          .pipe(
            switchMap((updatedIntakeEviction: IntakeEviction) => [
              new ShowAutoClosableAlert('Eviction details updated.'),
              intakeEvictionActions.IntakeEvictionUpdated(updatedIntakeEviction)
            ])
          )
      )
    )
  );

  generateFillableFormDetails: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(GenerateFillableFormDetails),
      switchMap(({intakeEviction, formPath}) =>
        this.intakeEvictionService.generateFillableFormDetails(intakeEviction)
          .pipe(
            map((details: Map<string, string>) => this.formService.generatePreview(formPath, details)),
            switchMap(() => [new ShowAutoClosableAlert('Preview generated.')])
          )
      ),
      catchError(() => [new ShowAutoClosableAlert('Error generating preview.')])
    )
  );

  intakeEvictionScraCheck: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntakeEvictionScraCheck),
      switchMap(({intakeEvictionId, tenantId}) =>
        this.intakeEvictionService.initScraCheck(intakeEvictionId, tenantId)
          .pipe(switchMap((result: { success: boolean, tenants: Tenant[] }) => {
            const refreshTenantEvents: any[] = [];
            if (result.success) {
              refreshTenantEvents.push(UpdateScraCheckStatus(intakeEvictionId, result.tenants, ScraScanStatus.PENDING));
            } else {
              refreshTenantEvents.push(UpdateScraCheckStatus(intakeEvictionId, result.tenants, ScraScanStatus.DATA_ERROR));
              refreshTenantEvents.push(new ShowAutoClosableAlert('The SCRA check failed.'));
            }
            return refreshTenantEvents;
          }
          ))),
      catchError(() => [new ShowManualClosableAlert('An error occurred setting up scra files for download. Please try again.')])
    )
  );

  constructor(
    private actions$: Actions,
    private appStore: Store,
    private http: HttpClient,
    private router: Router,
    private formService: FormService,
    private intakeEvictionService: IntakeEvictionService
  ) {}
}

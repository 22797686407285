import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as billingActions from '../actions/billing.actions';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {BillingDetails, PaymentMethod, SubscriptionInvoices, SubscriptionModel} from '@ee/common/models';
import {GetLoggedInUser} from '../actions/auth.actions';
import {ShowAutoClosableAlert, ShowManualClosableAlert} from '../actions/alert.actions';
import {StopSaving} from '../actions/ui.actions';
import {Router} from '@angular/router';

@Injectable()
export class BillingEffects {


  loadBillingDetails: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(billingActions.LOAD_BILLING_DETAILS),
    switchMap(() => this.http.get<BillingDetails>(`${environment.api_prefix}api/billing`)
      .pipe(
        switchMap((result: BillingDetails) => [
          new billingActions.BillingDetailsLoaded(result)
        ])
      ))
  ));


  updatePaymentMethod: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(billingActions.UPDATE_PAYMENT_METHOD),
    map((action: billingActions.UpdatePaymentMethod) => action.payload),
    switchMap((paymentMethod: PaymentMethod) =>
      this.http.put<BillingDetails>(`${environment.api_prefix}api/billing/payment-method`, paymentMethod)
        .pipe(
          switchMap((result: BillingDetails) => [
            new billingActions.BillingDetailsLoaded(result)
          ])
        ))
  ));


  changePlan: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(billingActions.CHANGE_PLAN),
    map((action: billingActions.ChangePlan) => action.payload),
    switchMap((planId: string) =>
      this.http.put<BillingDetails>(`${environment.api_prefix}api/billing/change-plan`, planId)
        .pipe(
          switchMap((result: BillingDetails) => [
            new billingActions.BillingDetailsLoaded(result)
          ])
        ))
  ));


  updateSubscription: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(billingActions.UPDATE_SUBSCRIPTION),
    map((action: billingActions.UpdateSubscription) => action.payload),
    switchMap((subscription: SubscriptionModel) =>
      this.http.put<BillingDetails>(`${environment.api_prefix}api/billing/subscription`, subscription)
        .pipe(
          switchMap((result: BillingDetails) => {
            this.router.navigate(['/']);
            return [
              new billingActions.BillingDetailsLoaded(result),
              GetLoggedInUser(true),
              new StopSaving(),
              new ShowAutoClosableAlert('Subscription updated.')
            ];
          }),
          catchError((err) => {
            let errorMessage = 'Error updating subscription.';
            if (err?.error?.message) {
              errorMessage = err?.error?.message;
            }
            console.error(errorMessage);
            return [
              new ShowManualClosableAlert(errorMessage),
              new StopSaving()
            ];
          })
        ))
  ));


  cancelSubscription: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(billingActions.CANCEL_SUBSCRIPTION),
    switchMap(() => this.http.put<boolean>(`${environment.api_prefix}api/billing/cancel`, null)
      .pipe(
        switchMap((result: boolean) => [new billingActions.SubscriptionCancelledReactivated(result)]),
        catchError(() => [
          new ShowAutoClosableAlert('Error cancelling subscription.')
        ])
      ))
  ));


  reactivateSubscription: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(billingActions.REACTIVATE_SUBSCRIPTION),
    switchMap(() => this.http.put<boolean>(`${environment.api_prefix}api/billing/reactivate`, null)
      .pipe(
        switchMap((result: boolean) => [new billingActions.SubscriptionCancelledReactivated(result)]),
        catchError(() => [
          new ShowAutoClosableAlert('Error reactivating subscription.')
        ])
      ))
  ));


  subscriptionInvoices: Observable<any> = createEffect(() => this.actions$.pipe(
    ofType(billingActions.LOAD_INVOICES),
    map((action: billingActions.LoadInvoices) => action.payload),
    switchMap((month: number) =>
      this.http.get<SubscriptionInvoices[]>(`${environment.api_prefix}api/billing/invoices?max=${month}`)
        .pipe(
          switchMap((result: SubscriptionInvoices[]) => [new billingActions.InvoicesLoaded(result)]),
          catchError(() => [new ShowAutoClosableAlert('Error on fetching subscription invoices.')])
        ))
  ));

  constructor(private actions$: Actions, private http: HttpClient, private router: Router) {
  }
}

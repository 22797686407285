import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as countyActions from '../actions/county.actions';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { County } from '@ee/common/models';

@Injectable()
export class CountyEffects {
  loadZipCodeToCountyByState: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(countyActions.LOAD_ALL_COUNTIES_BY_STATE),
      map((action: countyActions.LoadCountiesByState) => action.payload),
      switchMap((state: string) =>
        this.http
          .get<County[]>(`${environment.api_prefix}api/counties/state/${state}`)
          .pipe(switchMap((results: County[]) => [new countyActions.SetCountyResults(results)]))
      )
    )
  );

  updateCounty: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(countyActions.UPDATE_COUNTY),
      map((action: countyActions.UpdateCounty) => action.payload),
      switchMap((county: County) =>
        this.http
          .put<County>(`${environment.api_prefix}api/counties/${county.id}`, county)
          .pipe(switchMap((updatedCounty: County) => [new countyActions.CountyUpdated(updatedCounty)]))
      )
    )
  );

  constructor(private actions$: Actions, private http: HttpClient) {}
}

export enum EvictionDateField {
  // OPEN_DATE = 'openDate',
  // FILING_DATE = 'filingDate',
  // SERVE_DATE = 'serveDate',
  COURT_DATE = 'courtDate',
  // SET_OUT_DATE = 'setOutDate',
  // JUDGEMENT_DATE = 'judgementDate',
  // NOTICE_DATE = 'noticeDate',
  // INTEREST_START_DATE = 'interestStartDate'
}



export enum ReadableEvictionDateField {
  // openDate = 'Open Date',
  // serveDate = 'Serve Date',
  courtDate = 'Court Date',
  // evictedDate = 'Evicted Date',
  // judgementDate = 'Judgement Date',
  // noticeDate = 'Notice Date',
  // interestStartDate = 'Interest Start Date'
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as messageActions from '../actions/message.actions';
import { Message } from '@ee/common/models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageService } from '@ee/common/services';

@Injectable()
export class MessageEffects {
  sendMessage$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(messageActions.SEND_MESSAGE),
      map((action: messageActions.SendMessage) => action.payload),
      switchMap((message: Message) =>
        this.messageService.sendMessage(message.case_id, message.message).pipe(
          switchMap((sentMessage: Message) => {
            this.snackBar.open('Message sent', 'Dismiss', { duration: 3000 });
            return [new messageActions.MessageSent(sentMessage)];
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private snackBar: MatSnackBar, private messageService: MessageService) {}
}

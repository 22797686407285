import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Referral } from '@ee/common/models';

@Injectable({ providedIn: 'root' })
export class ReferralService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {}

  findAllReferrals(): Observable<Referral[]> {
    return this.http.get<Referral[]>(`${this.environment.api_prefix}api/referrals`);
  }

  findById(id: string): Observable<Referral> {
    return this.http.get<Referral>(`${this.environment.api_prefix}api/referrals/${id}`);
  }

  sendEmail(emails: string): Observable<Referral> {
    return this.http.post<Referral>(`${this.environment.api_prefix}api/referrals/emails`, { emails });
  }
}

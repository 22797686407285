<mat-toolbar class="portal-toolbar" [ngClass]="{ 'portal-transparent-toolbar': transparent }">
  <button *ngIf="this.leftSidenav && !this.leftSidenav.opened" mat-icon-button (click)="leftSidenav.open()">
    <mat-icon>menu</mat-icon>
  </button>
  <div *ngIf="!((smallBkPt$ | async)?.matches)">
    <div class="page-title">{{pageTitle}}</div>
    <div *ngIf="subTitle" class="sub-title">{{subTitle}}</div>
    <div *ngIf="pageTitleButton && (client$ | async)?.type !== accountTypes.PROPERTY_MANAGER"
         class="page-title-link" (click)="onPageTitleButtonClicked(pageTitleButton.action)">
      {{pageTitleButton.display}}
    </div>
  </div>

  <div class="self-start h-full flex flex-1 flex-row" *ngIf="(client$ | async) as client">

    <mat-form-field #searchFormField subscriptSizing="dynamic"
                    class="compact search-container tour-search lg:w-1/2" appearance="outline">
      <span matPrefix><mat-icon>search</mat-icon></span>
      <input #search matInput type="text" [formControl]="$any(searchControl)" placeholder="Search" [matAutocomplete]="auto">
      <mat-progress-bar *ngIf="isLoading" mode="query"></mat-progress-bar>
      <mat-autocomplete #auto="matAutocomplete">
        <div class="search-header">Search Results</div>
        <mat-option *ngFor="let suggestion of (searchSuggestions$ | async)" (click)="selectSuggestion(suggestion)"
                    class="search-result-row flex flex-row justify-start items-center">
          <div class="search-result-favicon justify-center items-center" [ngSwitch]="suggestion.type">
            <mat-icon *ngSwitchCase="searchResultType.EVICTION" class="search-result-favicon" fontSet="fa"
                      fontIcon="fa-briefcase" matListIcon></mat-icon>
            <mat-icon *ngSwitchCase="searchResultType.ORGANIZATION" class="search-result-favicon" fontSet="fa"
                      fontIcon="fa-address-book" matListIcon></mat-icon>
            <mat-icon *ngSwitchCase="searchResultType.INVOICE" class="search-result-favicon" fontSet="fa"
                      fontIcon="fa-credit-card" matListIcon></mat-icon>
            <mat-icon *ngSwitchCase="searchResultType.USER" class="search-result-favicon" fontSet="fa"
                      fontIcon="fa-user-circle" matListIcon></mat-icon>
          </div>
          <div class="search-result-text flex">
            <div class="line-1">
              <span [innerHTML]="suggestion.display"></span>
            </div>
            <div class="line-2" [innerHTML]="suggestion.description"></div>
            <div class="tag-wrapper flex flex-row">
              <ee-tag *ngFor="let tag of suggestion.tags" [tag]="tag" [noWrap]="true"></ee-tag>
            </div>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <!-- Trial Expiring Warning -->
    <div class="flex flex-row justify-center items-center">
      <div class="upgrade-plan mr-4 flex flex-row justify-center items-center"
           *ngIf="client.status !== subscriptionStatus.SUBSCRIPTION_ACTIVE && client.status !== subscriptionStatus.FREE_SUBSCRIPTION_ACTIVE">
        <span class="mr-2" *ngIf="(client.status === subscriptionStatus.SUBSCRIPTION_TRIAL_ACTIVE ||
             client.status === subscriptionStatus.SUBSCRIPTION_TRIAL_EXPIRED) && client.subscription_trial_end_date">
        <b>Your trial {{expired(client.subscription_trial_end_date)}} {{client.subscription_trial_end_date | date}}</b></span>
        <span *ngIf="client.status === subscriptionStatus.SUBSCRIPTION_CANCELLED || client.status === subscriptionStatus.SUBSCRIPTION_TRIAL_EXPIRED">
          <b>Your subscription has ended.</b>
        </span>
        <a *ngIf="(user$ | async)?.is_admin" class="update-subscription" [routerLink]="['/plan']">Click to upgrade plan.</a>
      </div>
    </div>
  </div>

  <button class="actions-button margin-right text-center" mat-stroked-button color="accent"
          [matMenuTriggerFor]="actionMenu" *ngIf="canCreateClient || canCreateCase || canCreateInvoice || canCreateTask">
    <span class="hidden max-sm:block text-center justify-center">
      <i class="fa fa-plus"></i>
    </span>
    <span class="hidden sm:max-lg:block text-center justify-center">Create...</span>
    <span class="lg:block hidden text-center justify-center">Create a New...</span>
  </button>
  <mat-menu #actionMenu="matMenu">
    <button mat-menu-item (click)="showAddClientDialog()" *ngIf="canCreateClient">
      Client
    </button>
    <button mat-menu-item routerLink="/evictions/create" *ngIf="canCreateCase">
      Eviction
    </button>
    <button mat-menu-item (click)="showNewInvoiceDialog()" *ngIf="canCreateInvoice">
      Invoice
    </button>
    <button mat-menu-item (click)="showAddTaskDialog()" *ngIf="canCreateTask">
      Task
    </button>
  </mat-menu>
  <button class="margin-right text-center" mat-stroked-button color="primary"
          [ngClass]="{'nav-switch-account': totalInvitedAccounts > 0}"
          [matBadge]="totalInvitedAccounts" matBadgeColor="accent" matBadgePosition="below after"
          [matBadgeHidden]="totalInvitedAccounts < 1" type="button" tooltip="Switch Accounts"
          [matMenuTriggerFor]="accounts" *ngIf="activeAccounts.length || totalInvitedAccounts > 0">
    <mat-icon fontSet="fa" fontIcon="fa-arrow-right-arrow-left" class="no-margin"></mat-icon>
  </button>
  <mat-menu #accounts="matMenu">
    <button mat-menu-item *ngIf="totalInvitedAccounts > 0" routerLink="/user-settings/accounts">
      View Invitations ({{totalInvitedAccounts}})
    </button>
    <button mat-menu-item *ngFor="let account of activeAccounts" (click)="switchAccount(account)">
      {{account.company_name}}
    </button>
  </mat-menu>
  <button class="portal-notifications bell-button margin-right" mat-icon-button (click)="openRightSidenav()"
          [ngClass]="{ 'portal-notification-active': !notificationsChecked && (notificationCount$ | async) > 0 }">
    <mat-icon>notifications</mat-icon>
    <span *ngIf="(notificationCount$ | async) > 0" class="portal-notification-badge"
          [textContent]="(notificationCount$ | async)"></span>
  </button>
  <ee-user-menu></ee-user-menu>
</mat-toolbar>
<mat-progress-bar *ngIf="saving$ | async" class="saving-progress" mode="indeterminate"
                  color="accent"></mat-progress-bar>

import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class LayoutService {
  private currentTheme$: BehaviorSubject<any> = new BehaviorSubject(null);
  private direction$: BehaviorSubject<string>;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, @Inject(DOCUMENT) private document: any) {
    // Set direction from session if set.
    const sessionDirection = sessionStorage.getItem('portal-direction');
    const direction = sessionDirection && sessionDirection === 'rtl' ? 'rtl' : 'ltr';
    this.direction$ = new BehaviorSubject(direction);
  }

  currentTheme(): Observable<any> {
    return this.currentTheme$.asObservable();
  }

  setCurrentTheme(theme: any): void {
    if (theme !== this.currentTheme$.getValue()) {
      this.currentTheme$.next(theme);
      sessionStorage.setItem('portal-theme', theme.id);
    }
  }
}

import {Duration, BeforeAfter, EvictionDateField, EmailSendStatus} from '@ee/common/enums';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Auditing} from './auditing.model';

export interface ScheduledEmailSettings extends Auditing {
  id?: string;
  name?: string;
  duration_amount?: number;
  duration?: Duration;
  before_after?: BeforeAfter;
  relative_to_field?: EvictionDateField;
  subject?: string;
  message?: string;
  scheduled_date?: string;
  cancel_date?: string;
  status?: EmailSendStatus;
  scheduled_time?: string;
}

export function GenerateScheduledEmailSettingsForm(fb: UntypedFormBuilder): UntypedFormGroup {
  return fb.group({
    id: null,
    name: ['', Validators.required],
    duration_amount: [0, [Validators.required, Validators.min(0)]],
    duration: [Duration.DAYS, Validators.required],
    before_after: [BeforeAfter.BEFORE, Validators.required],
    relative_to_field: [null, Validators.required],
    subject: ['', Validators.required],
    message: ['', Validators.required],
    scheduled_time: ['3:00 pm']
  });
}

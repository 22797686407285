import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {AccountType, SubscriptionPlan, SubscriptionStatus} from '@ee/common/enums';
import {NotEqualValidator, ValidateCouponCode} from '@ee/common/validators';
import {BillingService} from '@ee/common/services';
import {Organization} from '@ee/common/models';

export function GenerateSubscriptionForm(fb: UntypedFormBuilder, billingService: BillingService, org: Organization,
  subscription: SubscriptionPlan = null) {
  const userValidators =
    [Validators.required];
  if (org.selected_plan !== subscription && subscription !== SubscriptionPlan.FREE && org.status !== SubscriptionStatus.SUBSCRIPTION_TRIAL_ACTIVE) {
    userValidators.push(Validators.min(org.total_active_users), NotEqualValidator(org.subscription_user_count));
  }

  return fb.group({
    plan: new UntypedFormControl(subscription ?? (org.type === AccountType.ATTORNEY ? 'ENTERPRISE' : null), [Validators.required]),
    users: [org.subscription_user_count, userValidators],
    street_address_1: [org.street_address_1, Validators.required],
    street_address_2: [org.street_address_2],
    city: [org.city, Validators.required],
    state: [org.state, Validators.required],
    zip_code: [org.zip_code, Validators.required],
    payment_method: new UntypedFormControl(undefined, {
      validators: Validators.required
    }),
    coupon: new UntypedFormControl(undefined, {
      asyncValidators: [ValidateCouponCode.createValidator(billingService)],
      updateOn: 'blur'
    } as any)
  });
}

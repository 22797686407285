import { Action } from '@ngrx/store';
import { BillingDetails, PaymentMethod, SubscriptionInvoices, SubscriptionModel } from '@ee/common/models';

export const UPDATE_PAYMENT_METHOD = '[Billing] Update Payment Method';
export const LOAD_BILLING_DETAILS = '[Billing] Load Billing Details';
export const BILLING_DETAILS_LOADED = '[Billing] Billing Details Loaded';
export const ADD_SUBSCRIPTION = '[Billing] Add Subscription';
export const UPDATE_SUBSCRIPTION = '[Billing] Update Subscription';
export const CHANGE_PLAN = '[Billing] Change Plan';
export const PLAN_CHANGED = '[Billing] Plan Changed';
export const CANCEL_SUBSCRIPTION = '[Billing] Cancel Subscription';
export const REACTIVATE_SUBSCRIPTION = '[Billing] Re-activating Subscription';
export const LOAD_INVOICES = '[Billing] Load Invoices';
export const INVOICES_LOADED = '[Billing] Invoices Loaded';
export const SUBSCRIPTION_CANCELLED_REACTIVATED = '[Billing] Subscription Cancelled/Reactivated';

export class LoadBillingDetails implements Action {
  readonly type = LOAD_BILLING_DETAILS;
}

export class BillingDetailsLoaded implements Action {
  readonly type = BILLING_DETAILS_LOADED;

  constructor(public payload: BillingDetails) {
  }
}

export class UpdatePaymentMethod implements Action {
  readonly type = UPDATE_PAYMENT_METHOD;

  constructor(public payload: PaymentMethod) {
  }
}

export class AddSubscription implements Action {
  readonly type = ADD_SUBSCRIPTION;

  constructor(public payload: SubscriptionModel) { // subscription details
  }
}

export class UpdateSubscription implements Action {
  readonly type = UPDATE_SUBSCRIPTION;

  constructor(public payload: SubscriptionModel) { // subscription details
  }
}

export class ChangePlan implements Action {
  readonly type = CHANGE_PLAN;

  constructor(public payload: string) { // new plan id
  }
}

export class CancelSubscription implements Action {
  readonly type = CANCEL_SUBSCRIPTION;
}

export class ReactivateSubscription implements Action {
  readonly type = REACTIVATE_SUBSCRIPTION;
}

export class PlanChanged implements Action {
  readonly type = PLAN_CHANGED;

  constructor(public payload: string) { // new plan id
  }
}

export class LoadInvoices implements Action {
  readonly type = LOAD_INVOICES;

  constructor(public payload: number) { // Invoice number
  }
}

export class InvoicesLoaded implements Action {
  readonly type = INVOICES_LOADED;

  constructor(public payload: SubscriptionInvoices[]) { // Invoice number
  }
}

export class SubscriptionCancelledReactivated implements Action {
  readonly type = SUBSCRIPTION_CANCELLED_REACTIVATED;

  constructor(public payload: boolean) { // Is Subscription Cancelled/Reactivated
  }
}

export type BillingActions =
  LoadBillingDetails
  | BillingDetailsLoaded
  | UpdatePaymentMethod
  | AddSubscription
  | UpdateSubscription
  | ChangePlan
  | PlanChanged
  | CancelSubscription
  | ReactivateSubscription
  | LoadInvoices
  | InvoicesLoaded
  | SubscriptionCancelledReactivated;

import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {GettingStarted, Eviction, OrderedMap, MonthlyUsageStatisticsDTO, RecentlyViewedCase} from '@ee/common/models';
import {DateTime} from 'luxon';

@Injectable({providedIn: 'root'})
export class DashboardService {
  public resourceUrl: string;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
    this.resourceUrl = this.environment.api_prefix + 'api/dashboard';
  }

  getGettingStartedStatus(): Observable<GettingStarted> {
    return this.http.get<GettingStarted>(`${this.resourceUrl}/getting-started`);
  }

  getCourtCasesOn(date: DateTime): Observable<Eviction[]> {
    return this.http.get<Eviction[]>(`${this.resourceUrl}/court-cases/${date.toISODate()}`);
  }

  getCasesDueOn(date: DateTime): Observable<Eviction[]> {
    return this.http.get<Eviction[]>(`${this.resourceUrl}/overdue-cases/${date.toISODate()}`);
  }

  getUsageDetails(): Observable<MonthlyUsageStatisticsDTO[]> {
    return this.http.get<MonthlyUsageStatisticsDTO[]>(`${this.resourceUrl}/usage-details`);
  }

  getEvictionStepCounts(): Observable<OrderedMap> {
    return this.http.get<OrderedMap>(`${this.resourceUrl}/eviction-step-counts`);
  }

  getRecentlyViewedCases(): Observable<RecentlyViewedCase[]> {
    return this.http.get<RecentlyViewedCase[]>(`${this.resourceUrl}/recently-viewed-cases`);
  }
}

import { NgModule } from '@angular/core';
import { PlanComponent } from './components/plan/plan.component';
import { BillingRoutingModule } from './billing-routing.module';
import { CoreModule } from '../core/core.module';
import { MatRadioModule } from '@angular/material/radio';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { BillingComponent } from './billing.component';
import { AttorneySubscriptionComponent } from './components/attorney-subscription.component';
import { PropertyManagerSubscriptionComponent } from './components/property-manager-subscription.component';
import { OrderSummaryComponent } from './components/order-summary.component';
import { AddressAutocompleteModule } from '@ee/common/address-autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { PaymentMethodFormModule } from '@ee/common/payment-method-form';
import { ConfirmDialogModule } from '@ee/common/confirm-dialog';
import {AttorneyEnterpriseSubscribeComponent} from './components/attorney-enterprise-subscribe.component';

@NgModule({
  declarations: [
    PlanComponent,
    BillingComponent,
    AttorneySubscriptionComponent,
    PropertyManagerSubscriptionComponent,
    OrderSummaryComponent,
    AttorneyEnterpriseSubscribeComponent
  ],
  exports: [PlanComponent],
  imports: [
    CoreModule.forRoot(),
    BillingRoutingModule,
    MatRadioModule,
    SharedComponentsModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatCardModule,
    MatDividerModule,
    AddressAutocompleteModule,
    MatSelectModule,
    PaymentMethodFormModule,
    ConfirmDialogModule
  ]
})
export class BillingModule {}

import {UntypedFormBuilder, Validators} from '@angular/forms';
import {DocumentType} from '@ee/common/enums';
import {FileValidator} from '@ee/common/validators';
import {MAX_FILE_UPLOAD_SIZE} from '@ee/common/constants';

export function generateDocumentTemplate(fb: UntypedFormBuilder, type?: DocumentType, required = false) {
  const fileValidators: any[] = [FileValidator.maxContentSize(MAX_FILE_UPLOAD_SIZE)];
  if (required) {
    fileValidators.push(Validators.required);
  }

  return fb.group({
    id: null,
    name: ['', Validators.required],
    description: null,
    disabled: false,
    prevent_delete: false,
    detailed_claim: false,
    state: null,
    path: null,
    advanced_fill: false,
    clear_missing_fields: true,
    generate_multiple_per_tenant: false,
    tenant_count: 1,
    type: [null, Validators.required],
    zip_codes: [],
    original_form_id: null,
    counties: fb.array([]),
    pages: fb.array([]),
    file: [undefined, fileValidators],
    fields: fb.array([]),
    form_type: [type, Validators.required]
  });
}

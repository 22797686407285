import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from './app.reducer';
import { SearchResults } from '@ee/common/models';
import { AppSearchActions, SEARCH_SUGGESTION } from '../actions/search.actions';

export interface SearchState {
  searchSuggestion: SearchResults;
}

export interface State extends fromRoot.State {
  appSearch: SearchState;
}

const initialState: SearchState = {
  searchSuggestion: null
};

export function appSearchReducer(state = initialState, action: AppSearchActions) {
  if (action.type === SEARCH_SUGGESTION) {
    return {...state, searchSuggestion: action.payload};
  } else {
    return state;
  }
}

export const getAppSearchState = createFeatureSelector<SearchState>('appSearch');

export const getSearchSuggestion = createSelector(
  getAppSearchState, (state: SearchState) => state.searchSuggestion
);

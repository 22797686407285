import { Action } from '@ngrx/store';
import { Workflow } from '@ee/common/models';
import { ActivatedRoute } from '@angular/router';

export const LOAD_WORKFLOWS = '[Workflows] Load Workflows';
export const SET_WORKFLOWS = '[Workflows] Set Workflows';
export const LOAD_WORKFLOW = '[Workflows] Load Workflow';
export const SET_CURRENT_WORKFLOW = '[Workflows] Set Current Workflow';
export const ADD_WORKFLOW = '[Workflows] Add Workflow';
export const ADD_WORKFLOW_COMPLETE = '[Workflows] Add Workflow Complete';
export const UPDATE_WORKFLOW = '[Workflows] Update Workflow';
export const UPDATE_WORKFLOW_COMPLETE = '[Workflows] Update Workflow Complete';
export const CLONE_WORKFLOW = '[Workflows] Clone Workflow';
export const DELETE_WORKFLOW = '[Workflows] Delete Workflow';
export const WORKFLOW_DELETE = '[Workflows] Workflow Deleted';

export class LoadWorkflows implements Action {
  readonly type = LOAD_WORKFLOWS;
}

export class SetWorkflows implements Action {
  readonly type = SET_WORKFLOWS;

  constructor(public payload: Workflow[]) {
  }
}

export class LoadWorkflowById implements Action {
  readonly type = LOAD_WORKFLOW;

  constructor(public payload: string) {
  }
}

export class SetCurrentWorkflow implements Action {
  readonly type = SET_CURRENT_WORKFLOW;

  constructor(public payload: Workflow) {
  }
}

export class AddWorkflow implements Action {
  readonly type = ADD_WORKFLOW;

  constructor(public workflow: Workflow, public currentRoute: ActivatedRoute) {
  }
}

export class AddWorkflowComplete implements Action {
  readonly type = ADD_WORKFLOW_COMPLETE;

  constructor(public payload: Workflow) {
  }
}

export class UpdateWorkflow implements Action {
  readonly type = UPDATE_WORKFLOW;

  constructor(public payload: Workflow) {
  }
}

export class UpdateWorkflowComplete implements Action {
  readonly type = UPDATE_WORKFLOW_COMPLETE;

  constructor(public payload: Workflow) {
  }
}

export class CloneWorkflow implements Action {
  readonly type = CLONE_WORKFLOW;

  constructor(public workflowId: string, public currentRoute: ActivatedRoute) {
  }
}

export class DeleteWorkflow implements Action {
  readonly type = DELETE_WORKFLOW;

  constructor(public workflowId: string, public currentRoute: ActivatedRoute) {
  }
}

export class WorkflowDeleted implements Action {
  readonly type = WORKFLOW_DELETE;

  constructor(public payload: string) {
  }
}

export type WorkflowActions =
  LoadWorkflows
  | SetWorkflows
  | AddWorkflow
  | LoadWorkflowById
  | SetCurrentWorkflow
  | AddWorkflowComplete
  | UpdateWorkflow
  | UpdateWorkflowComplete
  | CloneWorkflow
  | DeleteWorkflow
  | WorkflowDeleted;

import { Component, OnDestroy, OnInit } from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ee-layout-loader',
  template: `
    <div *ngIf="loading" class="ee-layout-loader">
      <div class="ee-logo">
        <div class="lds-grid">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <span>
        Loading...
      </span>
    </div>

  `,
  styles: `
  .ee-layout-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
    text-transform: uppercase;
    text-align: center;

    > span {
      display: block;
      font-size: 0.875em;
      margin-top: 12px;
    }
  }
  `
})
export class LayoutLoaderComponent implements OnInit, OnDestroy {
  public loading = false;
  private subs: Subscription[] = [];

  constructor(private router: Router) {}

  ngOnInit(): void {
    // Watch for router events to turn on/off the loader.
    this.subs.push(
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationStart) {
          this.loading = true;
        } else if (event instanceof NavigationEnd || event instanceof NavigationError || event instanceof NavigationCancel) {
          this.loading = false;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }
}

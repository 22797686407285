import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSidenav } from '@angular/material/sidenav';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { GroupedNotification, Notification } from '@ee/common/models';
import groupBy from 'lodash-es/groupBy';
import map from 'lodash-es/map';
import { NotificationType } from '@ee/common/enums';
import { Router } from '@angular/router';
import { MarkNotificationRead } from '../../../core/actions/notification.actions';
import { DateTime } from 'luxon';
import { notificationTimeSince } from '../../../shared/util/timesince.util';
import * as fromNotification from '../../../core/reducers/notification.reducer';

@Component({
  selector: 'portal-notification-sidenav',
  templateUrl: './notification-sidenav.component.html',
  styleUrls: ['./notification-sidenav.component.scss']
})
export class NotificationSidenavComponent implements OnInit {
  public notificationTypes = NotificationType;

  constructor(private http: HttpClient, private store: Store, private router: Router) {}

  /**
   * Import material sidenav so we can access open close functions.
   */
  @Input() sidenav: MatSidenav;

  private subs: Subscription = new Subscription();

  groupedNotification: GroupedNotification[];
  allNotificationIds: string[] = [];

  public formatHeader(notification: Notification) {
    let header = notification.identifier;
    switch (notification.notification_type) {
      case NotificationType.INVOICE:
        header = `Invoice #${notification.identifier}`;
        break;
      case NotificationType.CASE:
        header = `Eviction ${notification.identifier}`;
        break;
      case NotificationType.CASE_CREATED:
        header = 'New Eviction Case Created';
        break;
      case NotificationType.INTAKE_CASE:
        header = 'New Eviction Received';
        break;
      case NotificationType.INTAKE_CASE_PROCESSED:
        header = 'Intake Eviction Processed';
        break;
      case NotificationType.BULK_CASE_FILES:
        header = 'Eviction Case Files';
        break;
      case NotificationType.BULK_INVOICES:
        header = 'Invoice Files';
        break;
    }
    return header;
  }

  public formatActionDisplay(notification: Notification) {
    let action = 'Click for more details.';
    if(notification.notification_type === NotificationType.BULK_CASE_FILES ||
      notification.notification_type === NotificationType.BULK_INVOICES) {
      action = 'Click to download.';
      if (notification.expired) {
        action = 'Download expired.';
      }
    }
    return action;
  }

  ngOnInit(): void {
    this.subs.add(
      this.store.select(fromNotification.getNotifications).subscribe((result: Notification[]) => {
        result.forEach(notification => {
          notification.time_since = notificationTimeSince(DateTime.fromISO(notification.created_date));
          notification.notification_url = notification.path;
          this.allNotificationIds.push(notification.id);
        });

        this.groupedNotification = map(
          groupBy(result, x => DateTime.fromISO(x.created_date).toFormat('DDD')),
          (notifications, date) => ({ date, notifications } as GroupedNotification)
        );
      })
    );
  }

  getNotificationName = (notification: string): string => NotificationType[notification];

  navigate(notification: Notification) {
    if (notification.notification_type === NotificationType.BULK_CASE_FILES ||
        notification.notification_type === NotificationType.BULK_INVOICES) {
      if (!notification.expired) {
        window.open(notification.path, '_blank');
      }
    } else {
      this.router.navigate([notification.notification_url]).then(() => {
        this.sidenav.close();
      });
    }
  }

  removeNotification(notification: Notification) {
    // remove from notification id list
    this.allNotificationIds.splice(this.allNotificationIds.indexOf(notification.id), 1);
    this.store.dispatch(new MarkNotificationRead([notification.id]));
  }

  clearAllNotifications() {
    this.store.dispatch(new MarkNotificationRead([...this.allNotificationIds]));
    this.allNotificationIds = []; // clear out id list
  }
}
